<template>
  <!-- <HeaderMain /> -->
  <div class="platform" v-if="USEFUL_PAGE">
    <div class="container">
      <div class="platform__main" v-if="USEFUL_PAGE.usefulPlatform">
        <h3 class="platform__main-title r16-600">{{ this.$t("plat") }}</h3>
        <h1 class="platform__main-title2 b36-700" v-html="USEFUL_PAGE.usefulPlatform.title"></h1>
        <div class="platform__main-info">
          <p class="platform__main-info-in g18-400" v-html="USEFUL_PAGE.usefulPlatform.description"></p>
        </div>

        <button
          class="platform__main-btn d-flex-center"
          @click="openVideoModal"
        >
          <img
            class="platform__main-btn-img"
            src="@/assets/images/platform/see-video.svg"
            alt=""
          />
          <p class="w24-700">{{ $t("watch") }}</p>
        </button>

        <!-- Модальное окно -->
        <div v-if="isVideoModalOpen" class="video-modal">
          <div class="video-modal__content">
            <div class="video-modal__video">
              <iframe
                width="100%"
                height="100%"
                :src="USEFUL_PAGE.usefulPlatform.video"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            </div>
            <button class="video-modal__close" @click="closeVideoModal">
              {{ $t("close") }}
            </button>
          </div>
        </div>
      </div>

      <div class="platform__content d-flex">
        <div class="platform__content__left">
          <div class="platform__content__left-wrapper">
            <h2 class="platform__content__left-title hu18-400" style="margin-bottom: 16px;">{{ $t("main") }}</h2>

            <router-link to="/useful">
              <button class="platform__content__left-item d-flex-center active">
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.5 11H11.71L11.43 10.73C12.4439 9.55402 13.0011 8.0527 13 6.5C13 5.21442 12.6188 3.95772 11.9046 2.8888C11.1903 1.81988 10.1752 0.986756 8.98744 0.494786C7.79973 0.00281635 6.49279 -0.125905 5.23192 0.124899C3.97104 0.375703 2.81285 0.994767 1.90381 1.90381C0.994767 2.81285 0.375703 3.97104 0.124899 5.23192C-0.125905 6.49279 0.00281635 7.79973 0.494786 8.98744C0.986756 10.1752 1.81988 11.1903 2.8888 11.9046C3.95772 12.6188 5.21442 13 6.5 13C8.11 13 9.59 12.41 10.73 11.43L11 11.71V12.5L16 17.49L17.49 16L12.5 11ZM6.5 11C4.01 11 2 8.99 2 6.5C2 4.01 4.01 2 6.5 2C8.99 2 11 4.01 11 6.5C11 8.99 8.99 11 6.5 11Z"
                    fill="red"
                  />
                </svg>

                <p class="hu18-400">{{ $t("searchProgram") }}</p>
              </button>
            </router-link>
            <button @click="toRegistration" class="platform__content__left-item d-flex-center">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6 22C5.45 22 4.979 21.804 4.587 21.412C4.195 21.02 3.99934 20.5493 4 20V10C4 9.45 4.196 8.979 4.588 8.587C4.98 8.195 5.45067 7.99933 6 8H7V6C7 4.61667 7.48767 3.43733 8.463 2.462C9.43834 1.48667 10.6173 0.999334 12 1C13.3833 1 14.5627 1.48767 15.538 2.463C16.5133 3.43833 17.0007 4.61733 17 6V8H18C18.55 8 19.021 8.196 19.413 8.588C19.805 8.98 20.0007 9.45067 20 10V20C20 20.55 19.804 21.021 19.412 21.413C19.02 21.805 18.5493 22.0007 18 22H6ZM12 17C12.55 17 13.021 16.804 13.413 16.412C13.805 16.02 14.0007 15.5493 14 15C14 14.45 13.804 13.979 13.412 13.587C13.02 13.195 12.5493 12.9993 12 13C11.45 13 10.979 13.196 10.587 13.588C10.195 13.98 9.99934 14.4507 10 15C10 15.55 10.196 16.021 10.588 16.413C10.98 16.805 11.4507 17.0007 12 17ZM9 8H15V6C15 5.16667 14.7083 4.45833 14.125 3.875C13.5417 3.29167 12.8333 3 12 3C11.1667 3 10.4583 3.29167 9.875 3.875C9.29167 4.45833 9 5.16667 9 6V8Z"
                  fill="black"
                  fill-opacity="0.54"
                />
              </svg>

              <p class="hu18-400">{{ $t("fav") }}</p>
            </button>

            <button @click="toRegistration" class="platform__content__left-item d-flex-center">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6 22C5.45 22 4.979 21.804 4.587 21.412C4.195 21.02 3.99934 20.5493 4 20V10C4 9.45 4.196 8.979 4.588 8.587C4.98 8.195 5.45067 7.99933 6 8H7V6C7 4.61667 7.48767 3.43733 8.463 2.462C9.43834 1.48667 10.6173 0.999334 12 1C13.3833 1 14.5627 1.48767 15.538 2.463C16.5133 3.43833 17.0007 4.61733 17 6V8H18C18.55 8 19.021 8.196 19.413 8.588C19.805 8.98 20.0007 9.45067 20 10V20C20 20.55 19.804 21.021 19.412 21.413C19.02 21.805 18.5493 22.0007 18 22H6ZM12 17C12.55 17 13.021 16.804 13.413 16.412C13.805 16.02 14.0007 15.5493 14 15C14 14.45 13.804 13.979 13.412 13.587C13.02 13.195 12.5493 12.9993 12 13C11.45 13 10.979 13.196 10.587 13.588C10.195 13.98 9.99934 14.4507 10 15C10 15.55 10.196 16.021 10.588 16.413C10.98 16.805 11.4507 17.0007 12 17ZM9 8H15V6C15 5.16667 14.7083 4.45833 14.125 3.875C13.5417 3.29167 12.8333 3 12 3C11.1667 3 10.4583 3.29167 9.875 3.875C9.29167 4.45833 9 5.16667 9 6V8Z"
                  fill="black"
                  fill-opacity="0.54"
                />
              </svg>

              <p class="hu18-400">{{ $t("acc") }}</p>
            </button>

            <button @click="toRegistration" class="platform__content__left-item d-flex-center">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6 22C5.45 22 4.979 21.804 4.587 21.412C4.195 21.02 3.99934 20.5493 4 20V10C4 9.45 4.196 8.979 4.588 8.587C4.98 8.195 5.45067 7.99933 6 8H7V6C7 4.61667 7.48767 3.43733 8.463 2.462C9.43834 1.48667 10.6173 0.999334 12 1C13.3833 1 14.5627 1.48767 15.538 2.463C16.5133 3.43833 17.0007 4.61733 17 6V8H18C18.55 8 19.021 8.196 19.413 8.588C19.805 8.98 20.0007 9.45067 20 10V20C20 20.55 19.804 21.021 19.412 21.413C19.02 21.805 18.5493 22.0007 18 22H6ZM12 17C12.55 17 13.021 16.804 13.413 16.412C13.805 16.02 14.0007 15.5493 14 15C14 14.45 13.804 13.979 13.412 13.587C13.02 13.195 12.5493 12.9993 12 13C11.45 13 10.979 13.196 10.587 13.588C10.195 13.98 9.99934 14.4507 10 15C10 15.55 10.196 16.021 10.588 16.413C10.98 16.805 11.4507 17.0007 12 17ZM9 8H15V6C15 5.16667 14.7083 4.45833 14.125 3.875C13.5417 3.29167 12.8333 3 12 3C11.1667 3 10.4583 3.29167 9.875 3.875C9.29167 4.45833 9 5.16667 9 6V8Z"
                  fill="black"
                  fill-opacity="0.54"
                />
              </svg>

              <p class="hu18-400">{{ $t("ourProd") }}</p>
            </button>

            <button @click="toRegistration" class="platform__content__left-item d-flex-center">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6 22C5.45 22 4.979 21.804 4.587 21.412C4.195 21.02 3.99934 20.5493 4 20V10C4 9.45 4.196 8.979 4.588 8.587C4.98 8.195 5.45067 7.99933 6 8H7V6C7 4.61667 7.48767 3.43733 8.463 2.462C9.43834 1.48667 10.6173 0.999334 12 1C13.3833 1 14.5627 1.48767 15.538 2.463C16.5133 3.43833 17.0007 4.61733 17 6V8H18C18.55 8 19.021 8.196 19.413 8.588C19.805 8.98 20.0007 9.45067 20 10V20C20 20.55 19.804 21.021 19.412 21.413C19.02 21.805 18.5493 22.0007 18 22H6ZM12 17C12.55 17 13.021 16.804 13.413 16.412C13.805 16.02 14.0007 15.5493 14 15C14 14.45 13.804 13.979 13.412 13.587C13.02 13.195 12.5493 12.9993 12 13C11.45 13 10.979 13.196 10.587 13.588C10.195 13.98 9.99934 14.4507 10 15C10 15.55 10.196 16.021 10.588 16.413C10.98 16.805 11.4507 17.0007 12 17ZM9 8H15V6C15 5.16667 14.7083 4.45833 14.125 3.875C13.5417 3.29167 12.8333 3 12 3C11.1667 3 10.4583 3.29167 9.875 3.875C9.29167 4.45833 9 5.16667 9 6V8Z"
                  fill="black"
                  fill-opacity="0.54"
                />
              </svg>

              <p class="hu18-400">{{ $t("ourServ") }}</p>
            </button>

            <button
              class="platform__content__left-item d-flex-center"
              @click="scrollTo('useful')"
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9 15H11V9H9V15ZM10 7C10.2833 7 10.521 6.904 10.713 6.712C10.905 6.52 11.0007 6.28267 11 6C11 5.71667 10.904 5.479 10.712 5.287C10.52 5.095 10.2827 4.99933 10 5C9.71667 5 9.479 5.096 9.287 5.288C9.095 5.48 8.99933 5.71733 9 6C9 6.28333 9.096 6.521 9.288 6.713C9.48 6.905 9.71733 7.00067 10 7ZM10 20C8.61667 20 7.31667 19.7373 6.1 19.212C4.88333 18.6867 3.825 17.9743 2.925 17.075C2.025 16.175 1.31267 15.1167 0.788 13.9C0.263333 12.6833 0.000666667 11.3833 0 10C0 8.61667 0.262667 7.31667 0.788 6.1C1.31333 4.88333 2.02567 3.825 2.925 2.925C3.825 2.025 4.88333 1.31267 6.1 0.788C7.31667 0.263333 8.61667 0.000666667 10 0C11.3833 0 12.6833 0.262667 13.9 0.788C15.1167 1.31333 16.175 2.02567 17.075 2.925C17.975 3.825 18.6877 4.88333 19.213 6.1C19.7383 7.31667 20.0007 8.61667 20 10C20 11.3833 19.7373 12.6833 19.212 13.9C18.6867 15.1167 17.9743 16.175 17.075 17.075C16.175 17.975 15.1167 18.6877 13.9 19.213C12.6833 19.7383 11.3833 20.0007 10 20ZM10 18C12.2333 18 14.125 17.225 15.675 15.675C17.225 14.125 18 12.2333 18 10C18 7.76667 17.225 5.875 15.675 4.325C14.125 2.775 12.2333 2 10 2C7.76667 2 5.875 2.775 4.325 4.325C2.775 5.875 2 7.76667 2 10C2 12.2333 2.775 14.125 4.325 15.675C5.875 17.225 7.76667 18 10 18Z"
                  fill="black"
                  fill-opacity="0.54"
                />
              </svg>

              <p class="hu18-400">{{ this.$t("use") }}</p>
            </button>
          </div>
          <div class="platform__content__left-line">
            <span></span>
          </div>
          <div class="platform__content__left__news">
            <h2 class="platform__content__left__news-title hu18-400">
              {{ $t("news") }}
            </h2>

            <div class="platform__content__left__news-item">
              <img
                class="platform__content__left__news-item-img"
                src="@/assets/images/platform/platform-news1.png"
                alt=""
              />
              <p class="hu18-400">{{ $t("add120") }}</p>
            </div>

            <div class="platform__content__left__news-item">
              <img
                class="platform__content__left__news-item-img"
                src="@/assets/images/platform/platform-news2.png"
                alt=""
              />
              <p class="hu18-400">{{ $t("add5") }}</p>
            </div>

            <div class="platform__content__left__news-item">
              <img
                class="platform__content__left__news-item-img"
                src="@/assets/images/platform/platform-news3.png"
                alt=""
              />
              <p class="hu18-400">{{ $t("addServ") }}</p>
            </div>
          </div>
        </div>

        <div class="platform__content__right">
          <h2 class="platform__content__right-title b20-600">{{ $t("searchProgram") }}</h2>

          <div class="platform__content__right-line">
            <span></span>
          </div>

          <div class="platform__content__right__select">
            <select class="platform__content__right__select-items g16-700">
              <!-- <p class="platform__content__right__select-items-title g16-700">{{ $t("searchProgram") }}</p> -->
              <option
                value=""
                selected
                class="platform__content__right__select-items-title g16-700"
              >
                {{ $t("searchProgram") }}
              </option>
              <!-- <option>МIT</option>
              <option>HARVARD</option> -->
            </select>
          </div>

          <div class="platform__content__right__select">
            <select class="platform__content__right__select-items g16-700">
              <option
                value=""
                selected
                class="platform__content__right__select-items-title g16-700"
              >
                {{ $t("filter") }}
              </option>
              <!-- <option>МIT</option>
              <option>HARVARD</option> -->
            </select>
          </div>

          <div class="platform__content__right-items">
            <router-link
              class="m_none"
              v-for="(item, index) in card"
              :to="{ name: item.link }"
              :key="index"
            >
              <div
                class="platform__content__right-item"
                :class="{ locked: item.lock }"
              >
                <img :src="item.img" alt="" />
                <h4
                  class="platform__content__right-item-address h16-400"
                  v-if="item.lock"
                >
                  {{ $t("accessClose") }}
                </h4>
                <h4
                  class="platform__content__right-item-address h16-400"
                  v-else
                >
                  Massachusetts, USA
                </h4>
                <div class="platform__content__right-item-info">
                  <p
                    class="platform__content__right-item-info-category b14-600"
                  >
                    Technology and Policy
                  </p>
                  <p
                    class="platform__content__right-item-info-university h14-400"
                    v-if="item.sign"
                  >
                    {{ $t("signReq") }}
                  </p>
                  <p
                    class="platform__content__right-item-info-university h14-400"
                    v-else
                  >
                    MIT
                  </p>
                </div>
              </div>
            </router-link>
            <router-link
              class="d_none"
              v-for="(item, index) in card.slice(0, this.step)"
              :key="index"
              :to="{ name: item.link }"
            >
              <div
                class="platform__content__right-item"
                :class="{ locked: item.lock }"
              >
                <img :src="item.img" alt="" />
                <h4
                  class="platform__content__right-item-address h16-400"
                  v-if="item.lock"
                >
                  {{ $t("accessClose") }}
                </h4>
                <h4
                  class="platform__content__right-item-address h16-400"
                  v-else
                >
                  Massachusetts, USA
                </h4>
                <div class="platform__content__right-item-info">
                  <p
                    class="platform__content__right-item-info-category b14-600"
                  >
                    Technology and Policy
                  </p>
                  <p
                    class="platform__content__right-item-info-university h14-400"
                    v-if="item.sign"
                  >
                    {{ $t("signReq") }}
                  </p>
                  <p
                    class="platform__content__right-item-info-university h14-400"
                    v-else
                  >
                    MIT
                  </p>
                </div>
              </div>
            </router-link>
          </div>
          <div class="more-btn d_none">
            <button
              class="more"
              v-if="this.step != this.card.length"
              @click="showMore"
            >
              {{ $t("more2") }}
            </button>
          </div>
        </div>
      </div>

      <button class="platform__bottom-btn">
        <router-link to="/price">
          <p class="w24-700">{{ $t("buy") }}</p>
        </router-link>
      </button>
    </div>
    <grantsWays/>
  </div>
</template>

<script>
import grantsWays from "@/components/grantsWays.vue";
import { mapActions, mapGetters } from "vuex";
import {useHead} from "@vueuse/head"
import { computed, reactive } from "vue"

export default {
  components: { grantsWays },
  data() {
    return {
      isVideoModalOpen: false,
      videoUrl: "",
      step: 6,
      card: [
        {
          img: require("../assets/images/platform/MIT.png"),
          lock: false,
          desc: "MIT",
          sign: false,
          link: "platformInfo",
        },
        {
          img: require("../assets/images/platform/MIT.png"),
          lock: true,
          desc: "MIT",
          sign: true,
        },
        {
          img: require("../assets/images/platform/MIT.png"),
          lock: true,
          desc: "MIT",
          sign: true,
        },
        {
          img: require("../assets/images/platform/MIT.png"),
          lock: true,
          desc: "MIT",
          sign: true,
        },
        {
          img: require("../assets/images/platform/MIT.png"),
          lock: true,
          desc: "MIT",
          sign: true,
        },
        {
          img: require("../assets/images/platform/MIT.png"),
          lock: true,
          desc: "MIT",
          sign: true,
        },
        {
          img: require("../assets/images/platform/MIT.png"),
          lock: true,
          desc: "MIT",
          sign: true,
        },
        {
          img: require("../assets/images/platform/MIT.png"),
          lock: true,
          desc: "MIT",
          sign: true,
        },
        {
          img: require("../assets/images/platform/MIT.png"),
          lock: true,
          desc: "MIT",
          sign: true,
        },
        {
          img: require("../assets/images/platform/MIT.png"),
          lock: true,
          desc: "MIT",
          sign: true,
        },
        {
          img: require("../assets/images/platform/MIT.png"),
          lock: true,
          desc: "MIT",
          sign: true,
        },
        {
          img: require("../assets/images/platform/MIT.png"),
          lock: true,
          desc: "MIT",
          sign: true,
        },
      ],
    };
  },
  setup() {
    const siteData = reactive({
        title: localStorage.getItem('meta_title') || 'Платформа',
        description: localStorage.getItem('meta_description') || 'Платформа',
        keywords: localStorage.getItem('meta_key') || 'Платформа'
    })
    useHead({
        meta: [
            {
                name:  computed(() => siteData.title),
                content: computed(() => siteData.description && siteData.keywords),
            },
        ],
    })
  },
  async created() {
    await this.GET_USEFUL_PAGE();
    localStorage.setItem('meta_title',this.USEFUL_PAGE?.seoPage?.meta_title)
    localStorage.setItem('meta_description',this.USEFUL_PAGE?.seoPage?.meta_description)
    localStorage.setItem('meta_key', this.USEFUL_PAGE?.seoPage?.meta_keyword)
  },
  methods: {
    ...mapActions(["GET_USEFUL_PAGE"]),
    toRegistration() {
      this.$router.push('/registration');
    },
    showMore() {
      this.step = this.card.length;
    },
    scrollTo(refName) {
      var element = this.$refs[refName];
      window.scrollTo({
        top: 1800,
        behavior: "smooth",
      });
    },
    openVideoModal() {
      this.isVideoModalOpen = true;
      this.videoUrl = this.USEFUL_PAGE?.usefulPlatform?.video;
    },
    closeVideoModal() {
      this.isVideoModalOpen = false;
    },
  },
  computed: {
    ...mapGetters(["USEFUL_PAGE"]),
  },
};
</script>

<style lang="scss" scoped>
.more {
  font-size: 16px;
  font-weight: 700;
  color: #11415d;
  padding: 9px 0;
  width: 224px;
  background-color: #fff;
  border: 1px solid #0000001a;
  border-radius: 4px;
  margin-top: 18px;
  &-btn {
    display: flex;
    justify-content: center;
  }
}
.platform {
  padding-top: 190px;
  background: #f5f7fb;
  &__main {
    text-align: center;
    &-title2 {
      width: 60%;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 18px;
    }
    &-info {
      margin-left: auto;
      margin-right: auto;
      width: 100%; //75
    }
    &-btn {
      // width: 360px;
      display: flex;
      justify-content: center;
      text-align: center;
      margin-left: auto;
      margin-right: auto;
      margin: 48px auto 80px;
      padding: 16px;
      background: #fd2d2a;
      border-radius: 8px;
      border: none;
      &-img {
        width: 29px;
        height: 29px;
        margin-right: 10px;
      }
    }
  }

  &__content {
    &__left {
      flex-basis: 352px;

      &-line {
        border-top: 1px solid rgba(0, 0, 0, 0.1);
        width: 100%;
        margin-top: 16px;
      }
      &-item {
        // box-sizing: border-box;
        background: #ffffff;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        padding: 8px 12px;
        width: 100%;
        margin-bottom: 8px;
        & p {
          margin-left: 15px;
        }
        &.active,
        &:hover {
          background: #fd2d2a;
          & p {
            color: white;
          }
          & path {
            fill: white;
            fill-opacity: 1;
          }
        }
      }
      &__news {
        margin-top: 16px;

        &-item {
          background: #ffffff;
          border: 1px solid rgba(0, 0, 0, 0.1);
          border-radius: 8px;
          width: 100%;
          margin-top: 8px;
          padding: 8px;
          &-img {
            width: 100%;
            height: 137px;
            border: 1px solid rgba(0, 0, 0, 0.1);
            border-radius: 8px;
          }
          & p {
            text-align: center;
            width: 75%;
            margin-left: auto;
            margin-right: auto;
            margin-top: 8px;
          }
        }
      }
    }

    &__right {
      flex: 1 0 auto;
      margin-left: 36px;
      background: #ffffff;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 24px;
      padding: 24px 24px 28px 32px;
      &-line {
        border: 1px solid rgba(0, 0, 0, 0.1);
        margin: 24px 0px 28px;
      }
      &-items {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        row-gap: 24px;
        column-gap: 18px;
        width: 100%;
      }

      &-item {
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        height: 280px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;
        padding: 10px;
        box-sizing: border-box;
        overflow: hidden;
        &.locked {
          & img {
            filter: blur(6px);
          }

          &::after {
            content: "";
            width: 27%;
            height: 79px;
            position: absolute;
            top: 40%;
            left: 50%;
            transform: translate(-50%, -50%);
            background-image: url("@/assets/images/platform/locked.png");
            background-size: cover;
            background-repeat: no-repeat;
          }
        }
        & img {
          position: absolute;
          top: 0;
          left: 0;
          z-index: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        &-address {
          background: #ffffff;
          border-radius: 4px;
          padding: 4px;
          text-align: center;
          position: relative;
          z-index: 1;
        }
        &-info {
          background: #ffffff;
          border-radius: 8px;
          padding: 6px 12px;
          z-index: 1;
          position: relative;

          &-category {
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            padding-bottom: 6px;
            margin-bottom: 13px;
            text-align: center;
          }
          &-university {
            text-align: center;
          }
        }
      }

      &__select {
        display: none;
      }
    }
  }

  .video-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;

    &__content {
      width: 100%;
      height: 100%;
      max-width: 100%;
      max-height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
    }

    &__video {
      width: 100%;
      height: 100%;
      max-width: 100%;
      max-height: 100%;
    }

    &__close {
      position: absolute;
      top: 10px;
      right: 10px;
      background-color: #fd2d2a;
      color: #fff;
      padding: 8px 16px;
      border-radius: 4px;
      border: none;
      cursor: pointer;
    }
  }

  .video-modal__video video {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &__bottom {
    &-btn {
      // width: 360px;
      display: flex;
      justify-content: center;
      text-align: center;
      margin-left: auto;
      margin-right: auto;
      margin: 80px auto 0px;
      padding: 16px;
      background: #fd2d2a;
      border-radius: 8px;
      border: none;
      &-img {
        width: 29px;
        height: 29px;
        margin-right: 10px;
      }
    }
  }
}

@media (min-width: 1200px) and (max-width: 1400px) {
  .platform {
    &__content {
      &__left {
        flex-basis: 352px;
      }
      &__right {
        flex: 0 1 auto;
        &-items {
          grid-template-columns: repeat(3, 1fr);
        }
      }
    }
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .platform {
    &__content {
      &__left {
        flex-basis: 352px;
      }
      &__right {
        &-items {
          grid-template-columns: repeat(2, 1fr);
        }
      }
    }
  }
}

@media (max-width: 991px) {
  .platform {
    background-color: #fff !important;
    padding-top: 100px;
    &__main {
      text-align: left;
      margin-bottom: 18px;
      &-title2 {
        width: 100%;
        font-size: 22px;
        font-weight: 700;
        margin-bottom: 18px;
      }
      &-btn {
        display: none;
        & p {
          font-weight: 700;
          font-size: 16px;
        }
      }
      &-info {
        &-in {
          font-size: 14px;
          line-height: 17px;
        }
      }
    }
    &__content {
      // display: inline-block !important;
      flex-direction: column-reverse;
      &__left {
        &-wrapper {
          display: none;
        }
        &-line {
          display: none;
        }
        &__news {
          &-title {
            font-size: 22px;
            font-weight: 700;
            color: black;
            margin-top: 30px;
          }
          &-item {
            img {
              object-fit: cover;
              height: 31vw;
            }
          }
        }
      }
      &__right {
        margin-left: none;
        background: none;
        border-radius: 0px !important;
        border: none !important;
        padding: 0px;
        margin: 0px;
        &-items {
          grid-template-columns: repeat(2, 1fr);
        }
        &-item {
          height: 248px;
          &-address {
            display: none;
          }
          &-info {
            margin-top: 100%;
            padding: 0px 12px;

            background: none;
            &-university {
              font-size: 11px;
            }
          }
          & img {
            width: 90%;
            height: 60%;
            border-radius: 8px;
            border: 1px solid rgba(0, 0, 0, 0.1);
            margin: 8px;
          }
          &.locked {
            &::after {
              top: 28%;
              height: 60px;
              background-size: contain;
            }
          }
        }
        &-title {
          display: none;
        }
        &-line {
          display: none;
        }
        &__select {
          display: block;
          width: 100% !important;
          margin-left: 0;
          margin-right: 0;
          padding-bottom: 18px;

          &-items {
            width: 100% !important;
            font-style: normal;
            padding: 9px 14px;
            font-size: 16px;
            font-weight: 700;
            color: #7c7c7c;
            border-radius: 4px;
            border: 1px solid rgba(0, 0, 0, 0.1);
            background: #fff;
          }
        }
      }
    }
    &__bottom {
      &-btn {
        display: none;
        & p {
          font-size: 16px;
        }
      }
    }
  }
}
</style>
