import { createStore } from "vuex";
import axios from "axios";
import router from "@/router";
import { createToaster } from "@meforma/vue-toaster";
import i18n from "@/i18n.js";

const toaster = createToaster({ position: "top-right", duration: 2000 });
export default createStore({
  state: {
    lang: localStorage.getItem("lang") || "ru",
    // openSignModal: false,
    // openRegistrModal: false,
    openMentorModal: false,
    openSendModal: false,
    openVideoModal: false,
    platformModal: false,
    cdn: "https://admin.mentorme.kz",
    about: [],
    grant: [],
    grantId: [],
    blog: [],
    blogId: [],
    question: [],
    price: [],
    contact: [],
    main: [],
    career: [],
    useful: [],
    dev: [],
    searchData: null,
    filter: [],
    service_mentors: [],
    service_add: [],
    video_podcast: [],
    audio_podcast: [],
    guides: [],
    favs: [],
    favourites: JSON.parse(localStorage.getItem('favourites')) || [],
    basket: JSON.parse(localStorage.getItem('basket')) || [],
    auth: localStorage.getItem("access_token") ? true : false,
    user: {},
    product_guide: [],
    product_course: [],
    uni_programs: [],
    programId: [],
    docs: [],
    news: [],
    loading: false,
  },
  getters: {
    getLoading: (state) => state.loading,
    getLang: (state) => state.lang,
    getOpenMentorModal(state) {
      return state.openMentorModal;
    },
    getOpenSendModal(state) {
      return state.openSendModal;
    },
    getOpenVideoModal(state) {
      return state.openVideoModal;
    },
    getPlatformModal(state) {
      return state.platformModal;
    },
    ABOUT_PAGE: (state) => state.about,
    GRANT_PAGE: (state) => state.grant,
    GRANT_PAGE_ID: (state) => state.grantId,
    BLOG_PAGE: (state) => state.blog,
    BLOG_PAGE_ID: (state) => state.blogId,
    QUESTION_PAGE: (state) => state.question,
    PRICE_PAGE: (state) => state.price,
    CONTACT_PAGE: (state) => state.contact,
    MAIN_PAGE: (state) => state.main,
    CAREER_PAGE: (state) => state.career,
    USEFUL_PAGE: (state) => state.useful,
    DEV_PAGE: (state) => state.dev,
    SEARCH_RESULT: (state) => state.searchData,
    FILTER: (state) => state.filter,
    SERVICE_MENTORS: (state) => state.service_mentors,
    SERVICE_ADD: (state) => state.service_add,
    VIDEO_PODCAST: (state) => state.video_podcast,
    AUDIO_PODCAST: (state) => state.audio_podcast,
    GUIDES: (state) => state.guides,
    getAuth(state) {
      return state.auth;
    },
    getUser: (state) => state.user,
    getFavs: (state) => state.favs,
    getFavour: (state) => state.favourites,
    getBasket: (state) => state.basket,
    PRODUCT_GUIDE: (state) => state.product_guide,
    PRODUCT_COURSE: (state) => state.product_course,
    UNI_PROGRAMS: (state) => state.uni_programs,
    PROGRAM_ID: (state) => state.programId,
    DOCS: (state) => state.docs,
    NEWS: (state) => state.news,
  },
  mutations: {
    SET_LANGUAGE(state, lang) {
      localStorage.setItem("lang", lang);
      state.lang = lang;
    },
    SET_LOADING(state, value) {
      state.loading = value;
    },
    UPDATE_LANG(state, lang) {
      state.lang = lang;
      localStorage.setItem("lang", lang);
    },

    SET_ABOUT_PAGE(state, data) {
      state.about = data;
    },
    SET_GRANT_PAGE(state, data) {
      state.grant = data;
    },
    SET_GRANT_PAGE_ID(state, data) {
      state.grantId = data;
    },
    SET_BLOG_PAGE(state, data) {
      state.blog = data;
    },
    SET_BLOG_PAGE_ID(state, data) {
      state.blogId = data;
    },
    SET_QUESTION_PAGE(state, data) {
      state.question = data;
    },
    SET_PRICE_PAGE(state, data) {
      state.price = data;
    },
    SET_CONTACT_PAGE(state, data) {
      state.contact = data;
    },
    SET_MAIN_PAGE(state, data) {
      state.main = data;
    },
    SET_CAREER_PAGE(state, data) {
      state.career = data;
    },
    SET_USEFUL_PAGE(state, data) {
      state.useful = data;
    },
    SET_DEV_PAGE(state, data) {
      state.dev = data;
    },
    SET_SEARCH(state, result) {
      state.searchData = result;
    },
    SET_FILTER(state, data) {
      state.filter = data
    },
    SET_SERVICE_MENTORS(state, data) {
      state.service_mentors = data;
    },
    SET_SERVICE_ADD(state, data) {
      state.service_add = data;
    },
    SET_VIDEO_PODCAST(state, data) {
      state.video_podcast = data;
    },
    SET_AUDIO_PODCAST(state, data) {
      state.audio_podcast = data;
    },
    SET_GUIDES(state, data) {
      state.guides = data;
    },
    SET_PRODUCT_GUIDE(state, data) {
      state.product_guide = data;
    },
    SET_PRODUCT_COURSE(state, data) {
      state.product_course = data;
    },
    SET_UNI_PROGRAMS(state, data) {
      state.uni_programs = data;
    },
    SET_PROGRAM_ID(state, data) {
      state.programId = data;
    },
    SET_AUTH(state, auth) {
      state.auth = auth;
    },
    SET_USER(state, user) {
      state.user = user;
    },
    SET_FAVS(state, data) {
      state.favs = data;
    },
    SET_DOCS(state, data) {
      state.docs = data;
    },
    SET_NEWS(state, data) {
      state.news = data;
    },
    DELETE_All_BASKET(state) {
      state.basket = []
      localStorage.removeItem('basket')
    },
    ADD_FAVOUR(state, product) {
      const result = state.favourites.findIndex(obj => obj.id === product.id);
      if (result < 0) {
        state.favourites.push(product);
        toaster.success(i18n.global.t(`notAddFav`));
      } else {
        state.favourites.splice(result, 1);
        toaster.error(i18n.global.t(`notRemFav`));
      }
      localStorage.setItem('favourites', JSON.stringify(state.favourites));
    },

    ADD_BASKET(state, product) {
      const result = state.basket.findIndex(obj => obj.uuid === product.uuid);
      if (result < 0) {
        state.basket.push(product);
        toaster.success(i18n.global.t(`notAddBs`));
      } else {
        state.basket.splice(result, 1);
        toaster.error(i18n.global.t(`notRemBs`));
      }
      localStorage.setItem('basket', JSON.stringify(state.basket));
    },

    //добавление подписки в корзину
    ADD_SUBSCRIPTION(state, product) {
      const result = state.basket.findIndex(obj => obj.title === product.title);
      if(result < 0) {
        state.basket.push(product);
        toaster.success(i18n.global.t(`addSub`));
      } else {
        toaster.success(i18n.global.t(`addYet`));
      }
      localStorage.setItem('basket', JSON.stringify(state.basket));
    }
  },
  actions: {
    async GET_ABOUT_PAGE({ commit }) {
      // commit("SET_LOADING", true)
      await axios
        .get("/page/about")
        .then((res) => {
          commit("SET_ABOUT_PAGE", res.data)
          // commit("SET_LOADING", false)
        })
        .catch((error) => {
          console.log(error);
          // commit("SET_LOADING", false)
        });
    },
    async GET_GRANT_PAGE({ commit }) {
      commit("SET_LOADING", true)
      await axios
        .get("/page/grant")
        .then((res) => {
          commit("SET_GRANT_PAGE", res.data)
          commit("SET_LOADING", false)
        })
        .catch((error) => {
          console.log(error);
          commit("SET_LOADING", false)
        });
    },
    async GET_GRANT_PAGE_ID({ commit }, id) {
      commit("SET_LOADING", true)
      await axios
        .get(`/page/grant/${id}`)
        .then((res) => {
          commit("SET_GRANT_PAGE_ID", res.data)
          commit("SET_LOADING", false)
        })
        .catch((error) => {
          console.log(error);
          commit("SET_LOADING", false)
        });
    },
    async GET_BLOG_PAGE({ commit }) {
      commit("SET_LOADING", true)
      await axios
        .get("/page/blog")
        .then((res) => {
          commit("SET_BLOG_PAGE", res.data)
          commit("SET_LOADING", false)
        })
        .catch((error) => {
          console.log(error);
          commit("SET_LOADING", false)
        });
    },
    async GET_BLOG_PAGE_ID({ commit }, slug) {
      commit("SET_LOADING", true)
      await axios
        .get(`/page/blog/${slug}`)
        .then((res) => {
          commit("SET_BLOG_PAGE_ID", res.data)
          commit("SET_LOADING", false)
        })
        .catch((error) => {
          console.log(error);
          commit("SET_LOADING", false)
        });
    },
    async GET_QUESTION_PAGE({ commit }) {
      commit("SET_LOADING", true)
      await axios
        .get("page/question")
        .then((res) => {
          commit("SET_QUESTION_PAGE", res.data)
          commit("SET_LOADING", false)
        })
        .catch((error) => {
          console.log(error);
          commit("SET_LOADING", false)
        });
    },
    async GET_PRICE_PAGE({ commit }) {
      commit("SET_LOADING", true)
      await axios
        .get("/page/price")
        .then((res) => {
          commit("SET_PRICE_PAGE", res.data)
          commit("SET_LOADING", false)
        })
        .catch((error) => {
          console.log(error);
          commit("SET_LOADING", false)
        });
    },
    async GET_CONTACT_PAGE({ commit }) {
      // commit("SET_LOADING", true)
      await axios
        .get("/contacts")
        .then((res) =>  {
          commit("SET_CONTACT_PAGE", res.data)
          // commit("SET_LOADING", false)
        })
        .catch((error) => {
          console.log(error);
          // commit("SET_LOADING", false)
        });
    },
    async GET_MAIN_PAGE({ commit }) {
      commit("SET_LOADING", true)
      await axios
        .get("/page/home")
        .then((res) => {
          commit("SET_MAIN_PAGE", res.data);
          setTimeout(() => {
            commit("SET_LOADING", false);
          }, 1500);
        })
        .catch((error) => {
          console.log(error);
          commit("SET_LOADING", false);
        });
    },
    async GET_CAREER_PAGE({ commit }) {
      commit("SET_LOADING", true)
      await axios
        .get("/page/carer")
        .then((res) =>  {
          commit("SET_CAREER_PAGE", res.data)
          commit("SET_LOADING", false)
        })
        .catch((error) => {
          console.log(error);
          commit("SET_LOADING", false)
        });
    },
    async GET_USEFUL_PAGE({ commit }) {
      commit("SET_LOADING", true)
      await axios
        .get("/page/useful")
        .then((res) => {
          commit("SET_USEFUL_PAGE", res.data)
          commit("SET_LOADING", false)
        })
        .catch((error) => {
          console.log(error);
          commit("SET_LOADING", false)
        });
    },
    async GET_DEV_PAGE({ commit }) {
      commit("SET_LOADING", true)
      await axios
        .get("/page/development")
        .then((res) => {
          commit("SET_DEV_PAGE", res.data)
          commit("SET_LOADING", false)
        })
        .catch((error) => {
          console.log(error);
          commit("SET_LOADING", false)
        });
    },
    async GET_SEARCH_RESULT({ commit }, { search, specification_item_ids }) {
      let params = {
        search: search,
      };
      specification_item_ids.length
        ? (params.specification_item_ids = specification_item_ids)
        : "";
      let result = await axios.get(`/search-mentor`, {
        params: params,
      });
      console.log("searchData", result);
      commit("SET_SEARCH", result.data);
    },
    async GET_SEARCH_ANSWER({ commit }, { search }) {
      let result = await axios.get(`/search-question`, {
        params: {
          search: search,
        },
      });
      console.log("searchData", result);
      commit("SET_SEARCH", result.data);
    },
    async GET_FILTER({commit}) {
      await axios
      .get('/universityProgramFilters', {
        headers: {
          Authorization: localStorage.getItem("access_token")
            ? `Bearer ${localStorage.getItem("access_token")}`
            : null,
        },
      })
      .then((res) => commit("SET_FILTER", res.data))
      .catch((error) => {
        console.log(error);
      });
    },
    async GET_SERVICE_MENTORS({ commit }, payload) {
      commit("SET_LOADING", true)
      try {
        const response = await axios.get("/platform/services/mentors", {
          params: payload
        });
        commit("SET_LOADING", false)
        commit("SET_SERVICE_MENTORS", response.data);
      } catch (error) {
        commit("SET_LOADING", false)
        console.log(error);
      }
    },
    async GET_SERVICE_ADD({commit}) {
      await axios
      .get("/platform/services/additional", {
        headers: {
          Authorization: localStorage.getItem("access_token")
            ? `Bearer ${localStorage.getItem("access_token")}`
            : null,
        },
      })
      .then((res) => commit("SET_SERVICE_ADD", res.data.additionals))
      .catch((error) => {
        console.log(error);
      });
    },
    async GET_VIDEO_PODCAST({commit}) {
      await axios
      .get("platform/useful/videos")
      .then((res) => commit("SET_VIDEO_PODCAST", res.data.data))
      .catch((error) => {
        console.log(error);
      });
    },
    async GET_AUDIO_PODCAST({commit}) {
      await axios
      .get("/platform/useful/audios")
      .then((res) => commit("SET_AUDIO_PODCAST", res.data.data))
      .catch((error) => {
        console.log(error);
      });
    },
    async GET_GUIDES({commit}) {
      commit("SET_LOADING", true)
      await axios
      .get("/platform/useful/guides", {
        headers: {
          Authorization: localStorage.getItem("access_token")
            ? `Bearer ${localStorage.getItem("access_token")}`
            : null,
        },
      })
      .then((res) => {
        commit("SET_LOADING", false)
        commit("SET_GUIDES", res.data.data)
      })
      .catch((error) => {
        commit("SET_LOADING", false)
        console.log(error);
      })
    },
    async GET_PRODUCT_GUIDE({commit}) {
      commit("SET_LOADING", true)
      await axios
      .get("/platform/products/guides", {
        headers: {
          Authorization: localStorage.getItem("access_token")
            ? `Bearer ${localStorage.getItem("access_token")}`
            : null,
        },
      })
      .then((res) => { 
        commit("SET_LOADING", false)
        commit("SET_PRODUCT_GUIDE", res.data.guides)
      })
      .catch((error) => {
        commit("SET_LOADING", false)
        console.log(error);
      })
    },
    async GET_PRODUCT_COURSE({commit}) {
      await axios
      .get("/platform/products/courses", {
        headers: {
          Authorization: localStorage.getItem("access_token")
            ? `Bearer ${localStorage.getItem("access_token")}`
            : null,
        },
      })
      .then((res) => commit("SET_PRODUCT_COURSE", res.data.courses))
      .catch((error) => {
        console.log(error);
      })
    },
    async GET_UNI_PROGRAMS({commit}, payload) {
      // commit("SET_LOADING", true)
      await axios
      .get("/universityPrograms", {
        params: payload,
        headers: {
          Authorization: localStorage.getItem("access_token")
              ? `Bearer ${localStorage.getItem("access_token")}`
              : null,
        },
      },)
      .then((res) => {
        // commit("SET_LOADING", false)
        commit("SET_UNI_PROGRAMS", res.data.universityPrograms)
      })
      .catch((error) => {
        console.log(error);
        // if( error.response.status === 401) {
        //   localStorage.removeItem("access_token");
        //   commit("SET_USER", {});
        //   commit("SET_AUTH", false);
        //   this.$router.push('/')
        //   console.log("err 401");
        // }
      })
    },
    async GET_PROGRAM_ID({ commit }, id) {
      // commit("SET_LOADING", true)
      await axios
        .get(`/universityPrograms/${id}`, {
          headers: {
            Authorization: localStorage.getItem("access_token")
                ? `Bearer ${localStorage.getItem("access_token")}`
                : null,
          },
        })
        .then((res) => {
          // commit("SET_LOADING", false)
          commit("SET_PROGRAM_ID", res.data)
        })
        .catch((error) => {
          // commit("SET_LOADING", false)
          console.log(error);
        });
    },
    async GET_FAVS({commit}) {
      commit("SET_LOADING", true)
      await axios
        .get('/universityPrograms/favourites', {
          headers: {
            Authorization: localStorage.getItem("access_token")
              ? `Bearer ${localStorage.getItem("access_token")}`
              : null,
          },
        })
        .then((res) => {
          commit("SET_LOADING", false)
          commit("SET_FAVS", res.data.universityPrograms)
        })
    },
    async GET_DOCS({commit}) {
      await axios
        .get('/documents')
        .then((res) => commit("SET_DOCS", res.data))
    },
    async GET_NEWS({commit}) {
      await axios
        .get('/articles')
        .then((res) => commit("SET_NEWS", res.data.data))
    },
    checkAuth({ commit }) {
      if (localStorage.getItem("access_token")) commit("SET_AUTH", true);
      else commit("SET_AUTH", false);
    }, 
    logoutUser({ commit }) {
      localStorage.removeItem("access_token");
      commit("SET_USER", {});
      commit("SET_AUTH", false);
    },
    addFavour({ commit, state }, product) {
      const getFavour = JSON.parse(localStorage.getItem('favourites'));
      if (getFavour) {
        commit('ADD_FAVOUR', product);
      } else {
        localStorage.setItem('favourites', JSON.stringify([product]));
        commit('ADD_FAVOUR', product);
      }
    },

    addBasket({ commit, state }, product) {
      const updatedProduct = {
        ...product,
        counter: 1
      };
      
      const getBasket = JSON.parse(localStorage.getItem('basket'));
      if (getBasket) {
        commit('ADD_BASKET', updatedProduct);
      } else {
        localStorage.setItem('basket', JSON.stringify([product]));
        commit('ADD_BASKET', updatedProduct);
      }
    },
    addSub({ commit, state }, product) {
      const updatedProduct = {
        ...product,
      };
      const getBasket = JSON.parse(localStorage.getItem("basket"));
      if(getBasket) {
        commit('ADD_SUBSCRIPTION', updatedProduct);
      } else {
        localStorage.setItem("basket", JSON.stringify([product]));
        commit('ADD_SUBSCRIPTION', updatedProduct)
      }
    }
  },
  modules: {},
});
