<template>
  <div class="scholarship" v-if="MAIN_PAGE">
    <div class="container">
      <div class="scholarship__main">
        <div class="scholarship__content" v-if="MAIN_PAGE?.homeProgram">
          <h3 class="scholarship__content-title">{{ this.$t("ourSr") }}</h3>
          <h1
            class="scholarship__content-title2 w36-700"
            v-if="MAIN_PAGE.homeProgram.title"
            v-html="MAIN_PAGE.homeProgram.title"
          ></h1>
          <p
            class="scholarship__content-info w18-600"
            v-if="MAIN_PAGE.homeProgram.description"
            v-html="MAIN_PAGE.homeProgram.description"
          ></p>
        </div>
        <div
          class="scholarship__cards d-flex"
          v-if="MAIN_PAGE.homeProgramItems"
        >
          <swiper
            :navigation="navigation26"
            :modules="modules"
            :slides-per-view="5"
            :breakpoints="{
              1800: {
                slidesPerView: 5,
              },

              1400: {
                slidesPerView: 5,
              },
              1200: {
                slidesPerView: 5,
              },
              1000: {
                slidesPerView: 3,
              },
              768: {
                slidesPerView: 2,
              },
              350: {
                slidesPerView: 1.5,
                spaceBetween: 20,
              },
            }"
            class="mySwiper"
          >
            <swiper-slide
              v-for="(item, index) in MAIN_PAGE.homeProgramItems"
              :key="index"
            >
                <CardComponent
                  :imgURL="item.image"
                  :title="item.title"
                  :link="item.link"
                  :desc="item.mini_description"
                  width="280px"
                  height="440px"
                  class="scholarship__cards-card"
                  is-transparent="true"
                />
            </swiper-slide>
          </swiper>
        </div>

        <div class="nav">
          <div class="swiper-button-prev swiperprev26">
            <img src="@/assets/images/mainPage/linia-left.png" alt="" />
          </div>
          <div class="swiper-button-next swipernext26">
            <img src="@/assets/images/mainPage/linia-right.png" alt="" />
          </div>
        </div>
        <div class="scholarship__pageBreak">
          <img src="@/assets/images/mainPage/pageBreak.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CardComponent from "./CardComponent.vue";
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper styles
import "swiper/css";

import "swiper/css/navigation";

// import required modules
import { Navigation } from "swiper";

export default {
  props: ["MAIN_PAGE"],
  components: {
    Swiper,
    SwiperSlide,
    CardComponent,
  },
  setup() {
    return {
      modules: [Navigation],
    };
  },
  data() {
    return {
      breakpoints: {
        1300: {
          slidesPerView: 5,
        },
        990: {
          slidesPerView: 3,
        },
        760: {
          slidesPerView: 2,
        },
        460: {
          slidesPerView: 1.5,
          sliderPerGroup: 1.5,
        },
        0: {
          slidesPerView: 1,
          sliderPerGroup: 1,
        },
      },
      navigation26: {
        nextEl: ".swipernext26",
        prevEl: ".swiperprev26",
      },
    };
  },
};
</script>
<style lang="scss" scoped>
.mySwiper {
  width: 100%;
}
.container {
  position: relative;
}
.nav {
  display: none;
}
.swiper-button-next {
  right: -16px;
  &::after {
    opacity: 0;
  }
  img {
    width: 13px;
    height: 22px;
  }
}
.swiper-button-prev {
  left: -30px;
  &::after {
    opacity: 0;
  }
  img {
    width: 13px;
    height: 22px;
  }
}
.scholarship {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.54) 0%,
    rgba(0, 0, 0, 0) 0%
  );
  &__content {
    &-title {
      font-weight: 600;
      font-size: 16px;
      text-align: center;
      letter-spacing: 0.02em;
      color: #fd2d2a;
      padding-top: 100px;
    }
    &-title2 {
      text-align: center;
      padding: 4px 0px 18px;
    }
    &-info {
      line-height: 28px;
      text-align: center;
      width: 55%;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &__cards {
    width: 100%;
    margin-top: 80px;
    margin-bottom: 80px;
    @media (max-width: $mobile) {
      margin-top: 0px;
      margin-bottom: 0px;
    }
  }
  &__card {
    width: 280px;
    height: 480px;
    border-radius: 12px;
    margin-right: 60px;
    padding: 12px 9px;
    position: relative;
    @media (max-width: $mobile) {
      padding: 0px 0px;
      margin-right: 0px;
      height: 400px;
    }
    &:last-child {
      margin-right: 0px;
    }
    &-image {
      // height: 457px; //457px
      display: block;
      position: relative;
      object-fit: cover;
      background-color: rgba(255, 255, 255, 0.1);
      backdrop-filter: blur(60px);
      border-radius: 12px;
      padding: 12px 9px;
      transition: 0.3s;
      @media screen and (max-width: $mobile) {
        width: 140%;
        padding: 0px 0px;
      }
      &:hover + .scholarship__card-overlay {
        visibility: visible;
        // background: rgba(255, 255, 255, 0.1);
        background: #5e809b;
        backdrop-filter: blur(60px);
        transform: translateY(-331px);
        transition: 0.3s;
      }
      &-img {
        width: 244px !important;
        height: 373px !important;
        border-radius: 12px;
        object-fit: cover;
        @media screen and (max-width: $mobile) {
          width: 100% !important;
        }
      }
      &-text {
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        text-align: center;
        color: #ffffff;
        padding: 24px 0px;
      }
    }
    &-overlay {
      position: absolute;
      visibility: hidden;
      width: 260px;
      // transition: all linear 0.7s;
      border-radius: 12px;
      padding: 12px;
      display: flex;
      flex-direction: column;
      backdrop-filter: blur(60px);
      transform: translateY(-300px);
      transition: 0.3s;
      &:hover {
        visibility: visible;
        // background: rgba(255, 255, 255, 0.1);
        background: #5e809b;
        backdrop-filter: blur(60px);
        transform: translateY(-331px);
        transition: 0.3s;
        height: 331px;
      }
      &-title {
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        text-align: center;
        color: #ffffff;
        padding: 12px 0px;
        &-info {
          font-weight: 500;
          font-size: 16px;
          text-align: center;
          line-height: 28px;
          letter-spacing: 0.02em;
          color: rgba(255, 255, 255, 0.54);
          margin-bottom: 29px;
        }
      }
    }
  }

  &__pageBreak {
    width: 100%;
    // padding: 0px 253px;
  }
}
@media (min-width: 1024px) and (max-width: 1620px) {
  .scholarship {
    &__cards {
      &-card {
        width: 250px !important;
        height: 440px !important;
      }
    }
  }
}
@media (min-width: 1024px) and (max-width: 1440px) {
  .scholarship {
    &__content {
      &-info {
        width: 75%;
        margin-left: auto;
        margin-right: auto;
      }
    }
    &__cards {
      &-card {
        width: 215px !important;
        height: 350px !important;
      }
    }
    &__card {
      margin-right: 0px;
      &-image {
        &:hover + .scholarship__card-overlay {
          transform: translateY(-300px);
        }
      }
      &-overlay {
        &:hover {
          transform: translateY(-300px);
        }
        &-title {
          &-info {
            font-size: 13px;
            line-height: 1.25em;
          }
        }
      }
    }
  }
}

@media (max-width: 990px) {
  .nav {
    width: 94%;
    top: 65%;
  }
  .swiper-button-prev {
    left: 0px;
  }
  .scholarship {
    &__content {
      margin-bottom: 18px;
      &-title {
        text-align: left;
        font-size: 14px;
        padding-top: 18px;
      }
      &-title2 {
        text-align: left;
        font-size: 22px;
        font-weight: 700;
      }
      &-info {
        width: 100%;
        line-height: 130%;
        text-align: left;
        font-size: 14px;
      }
    }
    &-cards {
      margin-bottom: 18px;
    }
    &__card {
      width: 218px;
      height: 283px;
      &-image {
        &-img {
          max-width: 202px !important;
          max-height: 280px !important;
          border-radius: 12px;
        }
      }
    }
  }
}

@media (max-width: 576px) {
  .swiper-slide {
    // filter: blur(2px);
    &-active {
      filter: unset !important;
    }
  }
}
</style>
