<template>
    <div
      class="backgroundSign block animated fadeIn"
      v-if="$store.state.platformModal"
      @click="$store.state.platformModal = false"
    >
        <div class="modals" v-if="sub">
            <p class="modals-title white px-36 fw700" v-html="sub.title"></p>
            <!-- <p class="modals-title red px-36 fw700">1 платформа</p> -->
            <p class="modals-desc white px-24 fw500" v-html="sub.description"></p>
            <div class="modals-btm">
              <button @click="isBasket(sub)" class="px-24 fw700 white pulse-red">{{ this.$t("buySub") }}</button>
              <p @click="$store.state.platformModal = false" class="px-24 fw500 white">{{ this.$t("later") }}</p>
            </div>
        </div>
    </div>
  </template>

  <script>
  import axios from "axios";
  import { mapActions } from 'vuex';
  export default {
    data() {
      return {
        sub: [],
      }
    },
    created() {
      this.getSubscription();
    },
    methods: {
      ...mapActions(["addSub"]),
      isBasket(sub) {
        this.addSub(sub);
        this.$router.push('/platform/basket');
      },
      getSubscription() {
        axios.get('/platform/subscription', {
          headers: {
            Authorization: localStorage.getItem("access_token")
              ? `Bearer ${localStorage.getItem("access_token")}`
              : null,
          },
        })
        .then(res => {
          this.sub = res.data.subscription;
        })
      }
    }
  };
  </script>
  <style lang="scss" scoped>
  @import '@/assets/styles/platform/main.scss';
    .pulse-red {
        animation: pulse-red-animation 2s infinite;
    }
    @keyframes pulse-red-animation {
        0% {
            box-shadow: 0 0 0 0px rgb(245, 2, 2);
        }
        100% {
            box-shadow: 0 0 0 20px rgba(245, 2, 2, 0);
        }
    }
  .valid {
    color: red;
    text-align: start;
  }
  .block {
    display: none; /* блок по умолчанию скрыт */
  }
  .animated {
    animation-duration: 1s; /* длительность анимации */
    animation-fill-mode: both; /* сохраняем состояние после анимации */
    transition: all ease 0.4s;
  }
  .fadeIn {
    animation-name: fadeIn; /* имя анимации */
  }
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  .backgroundSign {
    color: black;
    position: fixed;
    z-index: 999;
    transition: all ease 0.4s;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .modals {
    background: url('@/assets/images/platform/modal.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 70%;
    border-radius: 24px;
    padding: 48px;
    &-title {
        margin-bottom: 42px;
    }
    &-desc {
        margin-bottom: 40px;
        width: 80%;
    }
    button {
        border-radius: 8px;
        background: #FD2D2A;
        padding: 15px 0;
        width: 386px;
        border: none;
        transition: all 0.4s ease;
        margin-right: 48px;
        cursor: pointer;
        &:hover {
            transform: scale(1.1);
        }
    }
    &-btm {
      display: flex;
      align-items: center;
      margin-top: 80px;
      p {
        cursor: pointer;
      }
    }
  }
  @media (max-width: 960px) {
    .modals {
      width: 96%;
      padding: 18px 16px;
      &-title {
        font-size: 16px;
        margin-bottom: 18px;
      }
      &-desc {
        width: 100%;
        margin-bottom: 32px;
        font-size: 14px;
      }
      &-btm {
        margin-top: 0;
        flex-direction: column;
        button {
          margin-right: 0;
          margin-bottom: 18px;
          width: 100%;
          font-size: 16px;
          padding: 9px 0;
        }
        p {
          font-size: 16px;
        }
      }
    }
  }
  </style>
  