<template>
  <div class="product-btm__item m_none">
    <p class="px-16 gray title">{{ card.title }}</p>
    <img :src="card.image" alt="" @click="emitSelectGuide(card)" />
    <button v-if="card.is_buy" class="button" @click="addBasket(card)">
      <p v-if="!isBasket">{{ this.$t("addBasket") }}</p>
      <p v-else>{{ this.$t("remBasket") }}</p>
    </button>
    <p v-else class="buy-yet px-14 fw600 blue">{{ this.$t('buyYet') }}</p>
  </div>
  <div class="product-btm__item d_none">
    <p class="px-16 gray title">{{ card.title }}</p>
    <img :src="card.image" alt="" @click="emitSelectGuide(card)" />
    <button v-if="card.is_buy" class="button" @click="addBasket(card)">
      <p v-if="!isBasket">{{ this.$t("addBasket") }}</p>
      <p v-else>{{ this.$t("remBasket") }}</p>
    </button>
    <p v-else class="buy-yet px-14 fw600 blue">{{ this.$t('buyYet') }}</p>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  props: ["card"],
  methods: {
    ...mapActions(["addBasket"]),
    emitSelectGuide(card) {
      this.$emit("select-guide", card);
    },
  },
  computed: {
    ...mapGetters(["getBasket"]),
    isBasket() {
      return this.getBasket.some((item) => item.uuid === this.card.uuid);
    },
  },
};
</script>

<style scoped lang="scss">
@import url("@/assets/styles/platform/main.scss");
@media (max-width: 960px) {
  .m_none {
    display: none !important;
  }
  .container-wrap {
    width: 94%;
  }
}
@media (min-width: 960px) {
  .d_none {
    display: none !important;
  }
}
.buy {
  &-yet {
    text-align: center;
    padding-top: 12px;
  }
}
.product {
  &-btm {
    margin-top: 28px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 10px;
    &__item {
      border-radius: 12px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      background: #fff;
      max-width: 97%;
      width: 100%;
      padding: 7px;
      margin-bottom: 24px;
      transition: 0.4s;

      &:hover {
        transition: 0.4s;
        transform: scale(1.1);
      }
      img {
        width: 100%;
        height: 208px;
        margin: 8px 0;
        object-fit: cover;
        border-radius: 8px;
      }
      .title {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        -webkit-line-clamp: 1;
      }
    }
  }
}
.option {
  img {
    width: 22px;
    height: 22px;
  }
}
@media (min-width: 960px) and (max-width: 1560px) {
  .product {
    &-btm {
      &__item {
        img {
          height: 120px !important;
        }
        .button {
          font-size: 12px;
          padding: 6px 0;
        }
      }
    }
  }
}
@media (max-width: 960px) {
  .product {
    &-btm {
      margin-top: 18px;
      width: 100%;
      grid-template-columns: repeat(2, 1fr);
      &__item {
        width: 100%;
        padding: 8px;
        border-radius: 8px;
        img {
          width: 100%;
          height: 150px;
        }
        .button {
          font-size: 12px;
          font-weight: 700;
          border-radius: 8px;
          p {
            color: #11415d !important;
          }
          &:hover {
            p {
              color: #fff !important;
            }
          }
        }
        p {
          font-size: 16px;
          font-weight: 700;
          text-align: center;
          color: #000;
        }
        .title {
          -webkit-line-clamp: 2;
          height: 40px;
          display: flex;
          flex-direction: column;
        }
      }
    }
  }
}
</style>
