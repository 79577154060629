<template>
    <div class="service-dop__item">
        <p class="px-16 gray fw400">{{ card.title }}</p>
        <img :src="card.image" alt="" />
        <p style="display: none;">{{ card.price = this.optionSelected }}</p>
        <p style="display: none;">{{ card.type_value = this.option_title.type_value }}</p>
        <p class="price">
            {{ this.$t('pricePr') }}: <span class="">{{ Number(this.optionSelected) ? Number(this.optionSelected) : card.price_2 }}</span>
        </p>
        <hr />
        <div class="service-btm">
          <p class="service-dop__item-desc gray" v-html="card.description"></p>
          <div class="btm">
              <select name="" id="" v-model="option_title" @change="selectOption()">
                  <option value="" selected disabled>{{ $t("filter") }}</option>
                  <option v-for="(it, index) in card.prices" :key="index" :value="it">{{ it.title }}</option>
              </select>
              <button v-if="!isBasket" class="button" @click="selectFilter" :disabled="disa">
                  <p>{{ this.$t("addBasket") }}</p>
              </button>
              <button v-else class="button" @click="addBasket(card)">
                  <p>{{ this.$t("remBasket") }}</p>
              </button>
          </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { createToaster } from "@meforma/vue-toaster";
const toaster = createToaster({ position: "top-right", duration: 2000});

export default {
  data() {
    return {
      optionSelected: null,
      type_value: 0,
      option_title: "",
      disabled: true,
    }
  },
  props: ["card"],
  methods: {
      ...mapActions(["addBasket"]),
      selectOption() {
        this.optionSelected = this.option_title.price;
        console.log(this.option_title.type_value);
      },
      selectFilter() {
        if(this.option_title == "") {
          this.disabled = true;
          toaster.error("Выберите услугу в фильтрах!")
        } else {
          this.disabled = false;
          this.addBasket(this.card);
        }
        console.log("filter clicked!!");
      }
  },
  computed: {
      ...mapGetters(["getBasket"]),
      isBasket() {
          return this.getBasket.some(item => item.uuid === this.card.uuid)
      }
  }
}
</script>

<style lang="scss" scoped>
.service {
  &-dop {
    &__item {
      border-radius: 12px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      padding: 8px;
      width: 98%;
      margin-bottom: 28px;
      img {
        // width: 462px;
        height: 140px;
        padding: 8px 0;
        border-radius: 12px;
        object-fit: cover;
      }
      .price {
        text-align: center;
        color: rgba(0, 0, 0, 0.54);
        font-size: 16px;
        span {
          color: #fd2d2a;
          font-weight: 700;
        }
      }
      hr {
        color: rgba(0, 0, 0, 0.54);
        margin: 8px 0;
      }
      .btm {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-top: 24px;
        select,
        button {
          width: 49%;
          border-radius: 8px;
          border: 1px solid rgba(0, 0, 0, 0.1);
          background: #fff;
          padding: 8px;
          color: rgba(0, 0, 0, 0.54);
          font-size: 18px;
          transition: 0.4s;
        }
        select {
          appearance: none;
          background: url("@/assets/images/platform/arr-gray.png") 96% / 20px
            no-repeat;
        }
        button {
          &:hover {
            background: #fd2d2a;
            color: #fff;
            transition: 0.4s;
          }
        }
      }
      &-desc {
        text-align: center;
        font-size: 16px;
        line-height: 20px;
      }
    }
  }
  &-btm {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
@media (min-width: 960px) {
  .service {
    &-btm {
      height: 130px !important;
    }
    &-dop {
      &__item {
        &-desc {
          height: 60px;
          display: -webkit-box;
          -webkit-box-orient: vertical;  
          overflow: hidden;
          -webkit-line-clamp: 3;
        }
      }
    }
  }
}
@media (max-width: 960px) {
  .service {
    &-dop {
      &__item {
        .btm {
          margin-top: 18px;
          flex-direction: column;
          select, button {
            width: 100%;
            font-size: 16px;
          }
          select {
            color: #000;
            margin-bottom: 8px;
          }
          button {
            height: 42px;
          }
        }
      }
    }
  }
}
</style>