<template>
    <div class="fav">
        <p class="fav-title px-20 fw600">{{ $t("fav") }}</p>
        <p class="fav-none px-24 fw600" v-if="!getFavs">{{ this.$t("addPr") }}</p>
        <div v-else class="fav-cards">
            <fav-card v-for="(item, index) in getFavs" :item="item" :key="index"/>
        </div>
    </div>
</template>

<script>
import favCard from '@/components/platform/favCard.vue';
import { mapGetters, mapActions } from 'vuex'
export default {
    components: {favCard},
    data() {
        return {
            program: [
                {
                    geo: "Massachusetts, USA",
                    subject: "Technology and Policy",
                    uni: "MIT",
                    image: require('@/assets/images/platform/MIT.png')
                },
                {
                    geo: "Berlin, Germany",
                    subject: "Technology and Policy",
                    uni: "Berlin FA",
                    image: require('@/assets/images/platform/berlin.png')
                },
                {
                    geo: "Paris, France",
                    subject: "Technology and Policy",
                    uni: "Sorbonne Université",
                    image: require('@/assets/images/platform/uni2.png')
                },
                {
                    geo: "Washington, USA",
                    subject: "Technology and Policy",
                    uni: "Seattle TU",
                    image: require('@/assets/images/platform/uni3.png')
                },
            ]
        }
    },
    created() {
        this.GET_FAVS();
    },
    methods: {
        ...mapActions(["GET_FAVS"])
    },
    computed: {
        ...mapGetters(["getFavs"])
    }
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/platform/main.scss';
@import '@/assets/styles/platform/platformPage.scss';

</style>