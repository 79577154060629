<template>
  <div :class="[isBottomfooter ? 'bottomfooter' : '', 'footer']">
    <div class="container" v-if="CONTACT_PAGE.contact">
      <div class="footer__main flex-row-between-center">
        <div class="footer__content">
          <img style="width: 177px; height: 35px;" src="@/assets/logo-footer.png" alt="" />
        </div>
        <div class="footer__content d-flex">
          <img
            class="footer__content-img"
            src="@/assets/images/mainPage/footer1.svg"
            alt=""
          />
          <p class="footer__content-title">
            <a
              style="color: #11415d"
              :href="CONTACT_PAGE.contact.address_link"
              >{{ CONTACT_PAGE.contact.address }}</a
            >
          </p>
        </div>
        <div class="footer__content d-flex">
          <img
            class="footer__content-img"
            src="@/assets/images/mainPage/footerMail.svg"
            alt=""
          />
          <a
            :href="CONTACT_PAGE.contact.email_link"
            class="footer__content-title"
            >{{ CONTACT_PAGE.contact.email }}</a
          >
        </div>
        <div class="footer__content d-flex">
          <img
            class="footer__content-img"
            src="@/assets/images/mainPage/footer3.svg"
            alt=""
          />
          <a
            :href="CONTACT_PAGE.contact.phone_link"
            class="footer__content-title"
            >{{ CONTACT_PAGE.contact.phone }}</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  props: ["isBottomfooter"],
  created() {
    this.GET_CONTACT_PAGE();
  },
  methods: {
    ...mapActions(["GET_CONTACT_PAGE"]),
  },
  computed: {
    ...mapGetters(["CONTACT_PAGE"]),
  },
};
</script>
<style lang="scss">
.bottomfooter.footer {
  background-image: url(@/assets/images/mainPage/footer.png);
  background-repeat: no-repeat;
  background-size: cover;
  position: fixed;
  bottom: 0;
  width: 100%;
}

.footer {
  background-image: url(@/assets/images/mainPage/footer.png);
  background-repeat: no-repeat;
  background-size: cover;
  // position: fixed;
  // bottom: 0;
  // width: 100%;
  // &-other {
  //   &:deep(.footer) {
  //     position: absolute;
  //     z-index: 99;
  //     bottom: 0px;
  //     width: 100%;
  //   }
  // }
  &__main {
    padding: 37px 0px 51px;
  }
  &__content {
    &-img {
      width: 21px;
      height: 21px;
    }
    &-title {
      padding-left: 21px;
      font-weight: 400;
      font-size: 16px;
      line-height: 130%;
      color: #11415d;
    }
  }
}

@media (max-width: 990px) {
  .footer {
    &__main {
      display: block !important;
      padding: 38px 0 10px 0;
    }
    &__content {
      width: 100%;
      margin-bottom: 18px;
      margin-left: 6px;
      &:first-child {
        margin-left: 0px;
      }
      & img {
        width: 177px;
        height: 35px;
      }
      &-img {
        width: 21px !important;
        height: 21px !important;
      }
    }
  }
}
</style>
