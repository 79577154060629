<template>
  <videoModal />
  <div class="usefulPodcast" v-if="USEFUL_PAGE">
    <div class="container">
      <div class="usefulPodcasts">
        <div class="usefulPodcast__main" v-if="USEFUL_PAGE.usefulVideo">
          <h3 class="usefulPodcast__main-title r16-600">{{ this.$t("use") }}</h3>
          <h1
            class="usefulPodcast__main-title2 b36-700"
            v-html="USEFUL_PAGE.usefulVideo.title"
          ></h1>
          <div class="usefulPodcast__main-info">
            <p
              class="usefulPodcast__main-info-in g18-400"
              v-html="USEFUL_PAGE.usefulVideo.description"
            ></p>
          </div>
        </div>
        <div
          class="usefulPodcast__content-out"
          v-if="USEFUL_PAGE.usefulVideoItems"
        >
          <div class="usefulPodcast__content">
            <div class="slider m_none">
              <swiper
                class="mySwiper2"
                :navigation="navigation1"
                :modules="modules"
                :grid="{ rows: 1 }"
                :slidesPerView="6"
                :spaceBetween="20"
                :breakpoints="breakpoints"
              >
                <swiper-slide
                  v-for="(item, index) in USEFUL_PAGE.usefulVideoItems"
                  :key="index"
                >
                  <div>
                    <div class="slider__item filter">
                      <div class="slider__item-image">
                        <v-lazy-image :src="item.image" alt="" />
                        <div class="slider__item-image__overlay">
                          <a
                            v-if="item.type == '1'"
                            class="slider__item-image__overlay-text"
                            @click="
                              (selectedVideo = item),
                                (activeVideo = true)
                            "
                            style="cursor: pointer;"
                            >{{ $t("play") }}</a
                          >
                          <a
                            v-else
                            @click="
                              (selectedVideo = item),
                                (activeVideo = true)
                            "
                            style="cursor: pointer;"
                            class="slider__item-image__overlay-text"
                            >{{ $t("play") }}</a
                          >
                        </div>
                      </div>
                      <div class="slider__item-text">
                        <p
                          class="slider__item-text-name"
                          style="margin-bottom: 10px"
                          v-html="item.title"
                        ></p>
                      </div>
                    </div>
                  </div>
                </swiper-slide>
              </swiper>
              <div class="nav">
                <div class="swiper-button-prev swiperButtonPrev1">
                  <img src="@/assets/images/mainPage/linia-left.png" alt="" />
                </div>
                <div class="swiper-button-next swiperButtonNext1">
                  <img src="@/assets/images/mainPage/linia-right.png" alt="" />
                </div>
              </div>
            </div>
            <div class="mobile-wrap d_none">
              <div class="video-mobile d_none">
                <div v-for="(item, index) in USEFUL_PAGE.usefulVideoItems.slice(0, this.step1)"
                  :key="index" class="slider__item filter">
                    <div class="slider__item-image">
                      <v-lazy-image :src="item.image" alt="" />
                      <div class="slider__item-image__overlay">
                        <a
                          v-if="item.type == '1'"
                          class="slider__item-image__overlay-text"
                          @click="
                            (selectedVideo = item),
                              (activeVideo = true)
                          "
                          style="cursor: pointer;"
                          >{{ $t("play") }}</a
                        >
                        <a
                          v-else
                          @click="
                            (selectedVideo = item),
                              (activeVideo = true)
                          "
                          style="cursor: pointer;"
                          class="slider__item-image__overlay-text"
                          >{{ $t("play") }}</a
                        >
                      </div>
                    </div>
                    <div class="slider__item-text">
                      <p
                        class="slider__item-text-name"
                        style="margin-bottom: 10px"
                        v-html="item.title"
                      ></p>
                    </div>
                </div>
              </div>
              <div class="video-mobile__btn" v-if=" USEFUL_PAGE?.usefulVideoItems?.length > 4 && this.step1 != USEFUL_PAGE?.usefulVideoItems?.length">
                <button @click="showAll2">{{ $t("more2") }}</button>
              </div>
            </div>
            <div class="slider-video" v-if="activeVideo">
              <iframe
                :src="selectedVideo.video"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
              <img @click="activeVideo = false" src="@/assets/images/close.png" alt="">
            </div>
          </div>
        </div>

        <!-- <div class="usefulPodcast__pageBreak">
          <img src="@/assets/images/aboutPage/pageBreak.png" alt="" />
        </div> -->
      </div>

      <div class="usefulPodcasts audio-wrap">
        <div class="usefulPodcast__main" v-if="USEFUL_PAGE.usefulAudio">
          <h1
            class="usefulPodcast__main-title2 b36-700"
            v-html="USEFUL_PAGE.usefulAudio.title"
          ></h1>
          <div class="usefulPodcast__main-info">
            <p
              class="usefulPodcast__main-info-in g18-400"
              v-html="USEFUL_PAGE.usefulAudio.description"
            ></p>
          </div>
        </div>

        <div class="usefulPodcast__content-out">
          <div class="usefulPodcast__content">
            <div class="slider" v-if="USEFUL_PAGE.usefulAudioItems">
              <swiper
                class="mySwiper3 m_none"
                :navigation="navigation2"
                :modules="modules"
                :grid="{ rows: auto, fill: row }"
                :slidesPerView="6"
                :spaceBetween="20"
                :breakpoints="breakpoints"
              >
                <swiper-slide
                  v-for="(item, index) in USEFUL_PAGE.usefulAudioItems"
                  :key="index"
                >
                  <div>
                    <div class="slider__item filter">
                      <div class="slider__item-image">
                        <v-lazy-image :src="item.image" alt="" />
                        <div class="slider__item-image__overlay">
                          <a
                            class="slider__item-image__overlay-text"
                            @click="
                              (selectedAudio = item), (activeAudio = true)
                            "
                            >{{ $t("play") }}
                          </a>
                        </div>
                      </div>
                      <div class="slider__item-text">
                        <p
                          class="slider__item-text-name"
                          style="margin-bottom: 10px"
                          v-html="item.title"
                        ></p>
                      </div>
                    </div>
                  </div>
                </swiper-slide>
              </swiper>
              <div class="nav1 m_none">
                <div class="swiper-button-prev swiperButtonPrev2">
                  <img src="@/assets/images/mainPage/linia-left.png" alt="" />
                </div>
                <div class="swiper-button-next swiperButtonNext2">
                  <img src="@/assets/images/mainPage/linia-right.png" alt="" />
                </div>
              </div>
              <div class="mobile-wrap d_none">
                <div class="video-mobile d_none">
                  <div  v-for="(item, index) in USEFUL_PAGE?.usefulAudioItems?.slice(0, this.step2)"
                  :key="index" class="slider__item filter">
                      <div class="slider__item-image">
                        <v-lazy-image :src="item.image" alt="" />
                        <div class="slider__item-image__overlay">
                          <a
                          style="cursor: pointer;"
                            v-if="item.type == '1'"
                            class="slider__item-image__overlay-text"
                            @click="
                              (selectedVideo = item),
                                (activeAudio = true)
                            "
                            >{{ $t("play") }}</a
                          >
                          <a
                          style="cursor: pointer;"
                            v-else
                            @click="
                              (selectedVideo = item),
                                (activeAudio = true)
                            "
                            class="slider__item-image__overlay-text"
                            >{{ $t("play") }}</a
                          >
                        </div>
                      </div>
                      <div class="slider__item-text">
                        <p
                          class="slider__item-text-name"
                          style="margin-bottom: 10px"
                          v-html="item.title"
                        ></p>
                      </div>
                  </div>
                </div>
                <div class="video-mobile__btn" v-if="USEFUL_PAGE?.usefulAudioItems?.length > 4 && this.step2 != USEFUL_PAGE?.usefulAudioItems?.length">
                  <button @click="showAll1">{{ $t("more2") }}</button>
                </div>
              </div>
              <div class="audio" v-if="activeAudio">
                <img
                  @click="activeAudio = false"
                  src="../assets/images/close-white.png"
                  alt=""
                />
                <div class="audio-wrapper">
                  <div class="red"></div>
                  <div class="yellow"></div>
                  <audio controls autoplay>
                    <source :src="selectedAudio.audio" />
                  </audio>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- <div class="usefulPodcast__pageBreak">
          <img src="@/assets/images/aboutPage/pageBreak.png" alt="" />
        </div> -->
      </div>

      <div class="usefulPodcasts">
        <div class="usefulPodcast__main" v-if="USEFUL_PAGE.usefulGuide">
          <h1
            class="usefulPodcast__main-title2 b36-700"
            v-html="USEFUL_PAGE.usefulGuide.title"
          ></h1>
          <div class="usefulPodcast__main-info">
            <p
              class="usefulPodcast__main-info-in g18-400"
              v-html="USEFUL_PAGE.usefulGuide.description"
            ></p>
          </div>
        </div>

        <div
          class="usefulPodcast__content-out"
          v-if="USEFUL_PAGE.usefulGuideItems"
        >
          <div class="usefulPodcast__content">
            <div class="slider">
              <swiper
                class="mySwiper4 m_none"
                :navigation="navigation3"
                :modules="modules"
                :grid="{ rows: auto, fill: row }"
                :slidesPerView="6"
                :spaceBetween="20"
                :breakpoints="breakpoints"
              >
                <swiper-slide
                  v-for="(item, index) in USEFUL_PAGE?.usefulGuideItems"
                  :key="index"
                >
                  <div>
                    <div class="slider__item filter">
                      <div class="slider__item-image">
                        <v-lazy-image :src="item.image" alt="" />
                        <div class="slider__item-image__overlay">
                          <a
                            class="slider__item-image__overlay-text"
                            :href="item.guide"
                            target="_blank"
                            style="cursor: pointer;"
                            >{{ $t("play") }}</a
                          >
                        </div>
                      </div>
                      <div class="slider__item-text">
                        <p
                          class="slider__item-text-name"
                          style="margin-bottom: 10px"
                          v-html="item.title"
                        ></p>
                      </div>
                    </div>
                  </div>
                </swiper-slide>
              </swiper>
              <div class="nav2 m_none">
                <div class="swiper-button-prev swiperButtonPrev3">
                  <img src="@/assets/images/mainPage/linia-left.png" alt="" />
                </div>
                <div class="swiper-button-next swiperButtonNext3">
                  <img src="@/assets/images/mainPage/linia-right.png" alt="" />
                </div>
              </div>
              <div class="mobile-wrap d_none">
                <div class="video-mobile d_none">
                  <div class="slider__item filter" v-for="(item, index) in USEFUL_PAGE.usefulGuideItems?.slice(0, this.step3)"
                    :key="index">
                      <div class="slider__item-image">
                        <v-lazy-image :src="item.image" alt="" />
                        <div class="slider__item-image__overlay">
                          <a
                            class="slider__item-image__overlay-text"
                            :href="item.guide"
                            target="_blank"
                            style="cursor: pointer;"
                            >{{ $t("play") }}</a
                          >
                        </div>
                      </div>
                      <div class="slider__item-text">
                        <p
                          class="slider__item-text-name"
                          style="margin-bottom: 10px"
                          v-html="item.title"
                        ></p>
                      </div>
                    </div>
                </div>
                <div class="video-mobile__btn" v-if="USEFUL_PAGE?.usefulGuideItems?.length > 4 && this.step3 != USEFUL_PAGE?.usefulGuideItems?.length">
                  <button @click="showAll">{{ $t("more2") }}</button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- <div class="usefulPodcast__pageBreak">
          <img src="@/assets/images/aboutPage/pageBreak.png" alt="" />
        </div> -->
      </div>
    </div>
  </div>
  <div v-if="activeVideo" @click="activeVideo = false" class="visible"></div>
  <!-- <div v-if="activeAudio" @click="activeAudio = false" class="visible"></div> -->
  <!-- <audioPlayer /> -->
</template>
<script>
import videoModal from "@/components/modals/videoModal.vue";
import VLazyImage from "v-lazy-image";
// import AudioPlayer from "./modals/AudioPlayer.vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css/grid";
import SwiperCore, { Navigation, Grid } from "swiper";
import { mapActions, mapGetters } from "vuex";
SwiperCore.use([Navigation, Grid]);
export default {
  components: {
    Swiper,
    SwiperSlide,
    videoModal,
    VLazyImage
    // AudioPlayer,
  },
  setup() {
    return {};
  },
  data() {
    return {
      step1: 4,
      step2: 4,
      step3: 4,
      activeVideo: false,
      activeAudio: false,
      selectedVideo: [],
      selectedAudio: [],
      isVideo: false,
      breakpoints: {
        1300: {
          slidesPerView: 5,
        },
        990: {
          slidesPerView: 4,
        },
        760: {
          slidesPerView: 3,
        },
        460: {
          slidesPerView: 2,
          sliderPerGroup: 2,
        },
        0: {
          slidesPerView: 2,
          sliderPerGroup: 2,
        },
      },
      navigation1: {
        nextEl: ".swiperButtonNext1",
        prevEl: ".swiperButtonPrev1",
      },
      navigation2: {
        nextEl: ".swiperButtonNext2",
        prevEl: ".swiperButtonPrev2",
      },
      navigation3: {
        nextEl: ".swiperButtonNext3",
        prevEl: ".swiperButtonPrev3",
      },
    };
  },
  created() {
    this.GET_USEFUL_PAGE();
  },
  methods: {
    ...mapActions(["GET_USEFUL_PAGE"]),
    getUrl(value) {
      const regex = /src="([^"]+)"/;
      const match = value.match(regex);

      if (match && match[1]) {
        const srcValue = match[1];
        return srcValue;
      }
    },
    showAll() {
      this.step3 = this.USEFUL_PAGE?.usefulGuideItems?.length
    },
    showAll1() {
      this.step2 = this.USEFUL_PAGE?.usefulAudioItems?.length
    },
    showAll2() {
      this.step1 = this.USEFUL_PAGE?.usefulVideoItems?.length
    }
  },
  computed: {
    ...mapGetters(["USEFUL_PAGE"]),
  },
};
</script>
<style lang="scss" scoped>
.video {
  &-mobile {
    display: grid;
    grid-template-columns: repeat(2, 48%);
    gap: 20px;
    justify-content: space-between;
    &__btn {
      display: flex;
      justify-content: center;
      margin-top: 18px;
      button {
        color: #11415D;
        font-size: 16px;
        font-weight: 700;
        border-radius: 4px;
        border: 1px solid rgba(0, 0, 0, 0.10);
        background: #FFF;
        padding: 9px 0;
        width: 224px;
      }
    }
  }
}
.visible {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 110;
  // opacity: 0.8;
  // background: #8e9396;
}
.container {
  position: relative;
}
.slider {
  &-video {
    display: flex;
    width: 60%;
    position: fixed;
    top: 5%;
    left: 20%;
    z-index: 111;
    iframe {
      width: 100%;
      height: 500px;
      border-radius: 10px;
    }
    img {
      width: 34px;
      margin-left: 20px;
      cursor: pointer;
    }
  }
}
.audio {
  position: fixed;
  top: 45%;
  left: 21%;
  width: 56%;
  z-index: 111;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  img {
    width: 35px;
    margin: 10px 0;
    cursor: pointer;
    background-color: #FD2D2A;
    border-radius: 50%;
    padding: 8px;
    object-fit: contain;
  }
  &-wrapper {
    background-color: #fff;
    width: 100%;
    .red {
      background: #FD2D2A;
      width: 100%;
      height: 8px;
      margin: 8px 0;
    }
    .yellow {
      background: #FFE19C;
      width: 100%;
      height: 8px;
      margin-bottom: 8px;
    }
    audio::-webkit-media-controls-panel {
      background-color: #fff;
    }
    audio::-webkit-media-controls-play-button {
      background-color: #FD2D2A;
      border-radius: 50%;
    }
    audio {
      display: flex;
      justify-content: center;
      margin: 0 auto;
    }
  }
}
.nav, .nav1, .nav2 {
  position: absolute;
  width: 100%;
  top: 48%;
  height: 20px;
  display: flex;
  justify-content: space-between;
}
// .swiper-button-next2, .swiper-button-next3 {
//   position: absolute;
// }
// .swiper-button-prev2, .swiper-button-prev3 {
//   position: absolute;
// }
.swiper-button-next, .swiper-button-next2, .swiper-button-next3 {
  right: 15px;
  &::after {
    opacity: 0;
  }
  img {
    width: 13px;
    height: 22px;
  }
}
.swiper-button-prev, .swiper-button-prev2, .swiper-button-prev3 {
  left: -30px;
  &::after {
    opacity: 0;
  }
  img {
    width: 13px;
    height: 22px;
  }
}
.usefulPodcast {
  position: relative;
  padding-bottom: 160px;
  &__main {
    text-align: center;
    padding: 175px 0px 0px;
    &-info {
      margin-left: auto;
      margin-right: auto;
      width: 55%; //75
    }
  }

  &__content {
    width: 100%;
    height: auto;
    margin-top: 80px;
    position: relative;
  }

  &__pageBreak {
    padding: 80px 0px 80px;
  }

  .slider {
    width: 100%;
    gap: 24px; //   margin-left: 24px;
    &__items {
      display: flex;
      flex-direction: row !important;
      flex-wrap: wrap;
      width: 100%;
    }
    &__item {
      width: 100% !important;
      border: 1px solid rgba(0, 0, 0, 0.1);
      width: 232px !important;
      height: 287px;
      border-radius: 8px;
      background-color: #ffffff;

      &-image {
        background-color: #ffffff;
        display: block;
        width: 229px;
        height: 227px;
        position: relative;
        object-fit: contain;
        padding: 12px;
        border-radius: 12px;
        &__overlay {
          &:hover {
            opacity: 2;
            color: #ffc500;
          }
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          height: 100%;
          width: 100%;
          opacity: 0;
          transition: 0.5s ease;
          // background-color: #11415d;
          backdrop-filter: blur(24px);

          border-radius: 12px;
          &-text {
            font-weight: 700;
            font-size: 18px;
            line-height: 130%;
            text-align: center;
            letter-spacing: 0.02em;
            color: #ffffff;
            position: absolute;
            top: 60%;
            left: 50%;
            transform: translate(-50%, -50%);
            -ms-transform: translate(-50%, -50%);
            width: 95%;
            height: 40%;
          }
        }

        //   &:hover {
        //     background-color: #11415d;
        //   }
        & img {
          max-width: 229px;
          max-height: 227px;
          border-radius: 12px;
          object-fit: cover;
        }
      }
      &-text {
        text-align: center;
        padding: 12px 0px;
        &-name {
          font-weight: 700;
          font-size: 18px;
          line-height: 130%;
          text-align: center;
          letter-spacing: 0.02em;
          color: #000000;
        }
      }
    }
  }
}

@media (min-width: 1024px) and (max-width: 1440px) {
  .nav {
    top: 58%;
  }
  .usefulPodcast {
    .slider {
      &__item {
        width: 200px !important;
        height: 287px;
        &-image {
          width: 198px;
          height: 227px;
          & img {
            max-width: 200px;
            max-height: 227px;
          }
        }
      }
    }
  }
}
@media (min-width: 990px) {
  .slider {
    &__item {
      &-text {
        height: 60px;
        padding: 0 !important;
        display: flex;
        justify-content: center;
        flex-direction: column;
        &-name {
          display: -webkit-box;
          -webkit-box-orient: vertical;  
          overflow: hidden;
          -webkit-line-clamp: 2;
        }
      }
    }
  }
}
@media (max-width: 990px) {
  .slider {
    &-video {
      flex-direction: column-reverse;
      align-items: flex-end;
      width: 100%;
      left: 0;
      iframe {
        height: 370px;
      }
      img {
        background-color: #efefef;
      }
    }
  }
  .audio {
    width: 78%;
    top: 35%;
    left: 10%;
    right: 0;
    audio {
      width: 100%;
    }
    img {
      background-color: #efefef;
    }
    &-wrapper {
      box-shadow: 0px -4px 8px 0px rgba(0, 0, 0, 0.05);
    }
  }
  .usefulPodcast {
    padding: 56px 0 20px 0;
    &__main {
      text-align: left;
      padding-top: 0;
      &-title2 {
        font-weight: 700;
        font-size: 22px;
      }
      &-info {
        width: 100%;
        &-in {
          font-weight: 600;
          font-size: 14px;
          line-height: 130%;
          letter-spacing: 0.02em;
        }
      }
    }

    &__content {
      margin-top: 18px;
    }

    .slider {
      &__item {
        width: 171px !important;
        height: auto;
        &-image {
          width: 168px;
          height: 150px;
          padding: 8px;
        }
        &-text {
          &-name {
            font-weight: 700;
            font-size: 16px;
            line-height: 20px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            overflow: hidden;
            -webkit-line-clamp: 2;
          }
        }
      }
    }

    &__pageBreak {
      display: none;
    }
  }
  .usefulPodcasts {
    margin-bottom: 40px;
  }
}
.swiper-wrapper {
  flex-direction: unset !important;
}
.swiper-slide.result__items-swiper-swipers-slide.swiper-slide-next {
  margin-top: 0 !important;
}

.swiper-slide.result__items-swiper-swipers-slide {
  margin-top: 0 !important;
}
</style>
