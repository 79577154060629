<template>
    <div class="backgroundSign block animated fadeIn">
        <div class="reset">
            <p>{{ this.$t('reset1') }}</p>
            <input type="password" v-model="password" :placeholder="this.$t('newPass')">
            <input type="password" v-model="password_confirmation" :placeholder="this.$t('confirmPass')">
            <button @click="sendReset">{{ this.$t("sendForm") }}</button>
        </div>
    </div>
</template>

<script>
import axios from "axios"
import { createToaster } from "@meforma/vue-toaster";

const toaster = createToaster({ position: "top-right", duration: 2000});

export default {
    data() {
        return {
            password_confirmation: [],
            password: [],
            email: []
        }
    },
    methods: {
        sendReset() {
            this.form = {
                password: this.password,
                password_confirmation: this.password_confirmation,
                email: this.$route.query.email,
                token: this.$route.query.token
            }
            axios.post('/reset-password',this.form, {
                headers: {
                Authorization: this.$route.query.token
                    ? `Bearer ${this.$route.query.token}`
                    : null,
                },
            })
            .then( res => {
                if(res.status === 200) {
                    toaster.success(this.$t('resetSc'));
                    this.$router.push('/login');
                }
            })
        }
    }

}
</script>

<style lang="scss" scoped>
.backgroundSign {
    color: black;
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url(@/assets/images/mainPage/loginSignUp-bg.png);
    background-repeat: no-repeat;
    background-size: cover;
}
.reset {
    width: 394px;
    background: #ffffff;
    display: flex;
    flex-direction: column;
    padding: 18px;
    border-radius: 8px;
    gap: 16px;
    p {
        text-align: center;
        font-size: 24px;
        font-weight: 700;
    }
    input {
        border-radius: 4px;
        background: #F5F7FB;
        box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.05) inset;
        backdrop-filter: blur(2px);
        padding: 10px 0 10px 18px;
        font-size: 18px;
        font-style: italic;
        border: none;
    }
    button {
        border-radius: 4px;
        background: #FD2D2A;
        padding: 13px 0;
        font-size: 18px;
        font-weight: 700;
        border: none;
        color: #ffffff;
    }
}
@media (max-width: 990px) {
    .backgroundSign {
      background-image: url(@/assets/images/loginSignUp.png);
      background-repeat: no-repeat;
      background-size: cover;
      min-height: 100vh;
      height: 100%;
      overflow-y: scroll;
      padding: 70px 0;
    }
    .sign {
      z-index: 111;
      overflow: scroll;
    }
}
</style>