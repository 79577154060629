<template>
    <div
      class="backgroundSign block animated fadeIn"
    >
    <img @click="toMain" class="logo" src="@/assets/logo.svg" alt="">
      <div v-if="!getCode && !forgetPass" class="sign">
        <p class="sign-p1">{{ this.$t('sign') }}</p>
        <span class="sign-bottomLine"></span>
        <div v-if="!sendSms" class="sign-withSms d-flex-center" @click="sendSms = true">
          <img
            class="sign-withSms-img"
            src="@/assets/images/mainPage/registr-sms.svg"
            alt=""
          />
          <p class="sign-withSms-text g18-400">{{ this.$t('signWithSms') }}</p>
        </div>
        <div v-if="sendSms" @click="sendSms = false" class="sign-withSms d-flex-center">
          <img
            class="sign-withSms-img"
            src="@/assets/images/mainPage/mail.png"
            alt=""
          />
          <p class="sign-withSms-text g18-400">{{ this.$t('signWithMail') }}</p>
        </div>
  
        <div class="sign-bottomLine">
          <div class="sign-withSms-line hu18-400">{{ this.$t('or') }}</div>
        </div>
  
        <input
        v-if="!sendSms"
          class="sign-input"
          type="email"
          :placeholder="this.$t('mail')"
          v-model="v$.email.$model"
        />
        <template v-for="(error) of v$.email.$errors" :key="error">
          <p v-if="!sendSms" class="errorText">{{ error.$message }}</p>
        </template>
        <div v-if="!sendSms" class="show-pass">
          <input
            v-if="!showPass"
              class="sign-input"
              type="password"
              :placeholder="this.$t('pass')"
              v-model="v$.password.$model"
            />
            <input
            v-else-if="showPass"
              class="sign-input"
              type="type"
              :placeholder="this.$t('pass')"
              v-model="v$.password.$model"
            />
            <img v-if="showPass" @click="showPass = false" src="@/assets/images/show-pass.png" alt="">
            <img v-else @click="showPass = true" src="@/assets/images/hide-pass.png" alt="">
        </div>
        <template v-for="(error) of v$.password.$errors" :key="error">
          <p v-if="!sendSms" class="errorText">{{ error.$message }}</p>
        </template>
        <input
          v-if="sendSms"
          class="sign-input"
          type="text"
          v-mask="'+7 (###) ### ## ##'"
          v-model="v$.phone.$model"
          :placeholder="this.$t('phone')"
        />
        <template v-for="(error) of v$.phone.$errors" :key="error">
          <p v-if="sendSms" class="errorText">{{ error.$message }}</p>
        </template>
        <div v-if="!sendSms" class="sign__remember d-flex">
          <input id="3" type="checkbox" class="sign__remember-inp" />
          <label for="3" class="sign__remember-text">{{ this.$t('remember') }}</label>
        </div>
        <button v-if="!sendSms" class="sign-button" type="submit" @click="Login">{{ this.$t('signIn') }}</button>
        <button v-if="sendSms" @click="sendSmsPhone" class="sign-button" type="submit">{{ this.$t('sendSms') }}</button>
        <router-link class="sign-registrBtn" to="/registration">{{ this.$t('login') }}</router-link>
        <p v-if="!sendSms" class="forget-pass" @click="forgetPass = true">{{ this.$t('forgetPass') }}</p>
        <span class="sign-bottomLine"></span>
        <p class="sign-agreement">
          {{ this.$t('conf1') }}
          <span class="sign-agreement-docs">
            <a style="color: #FD2D2A;" :href="DOCS?.doc_1">{{ this.$t('conf2') }}</a> {{ this.$t('and') }} <a style="color: #FD2D2A;" :href="DOCS?.doc_2">{{ this.$t('conf3') }}</a>
          </span>
        </p>
      </div>
        <div v-if="getCode" class="sign">
            <div class="sign-top">
                <img @click="getCode = false" src="@/assets/images/left-arrow.png" alt="">
                <p class="sign-p1">{{ this.$t('sign') }}</p>
            </div>
            <span class="sign-bottomLine"></span>
            <input
                class="sign-input sign-top__input"
                type="text"
                v-model="code"
                :placeholder="this.$t('sms')"
            />
            <button class="sign-button sign-top__btn" @click="sendCode">{{ this.$t('confirm') }}</button>
            <button class="sign-registrBtn">{{ this.$t('again') }}</button>
            <span class="sign-bottomLine"></span>
            <p class="sign-top__par">{{ this.$t('correctPhone') }}</p>
          </div>
        <div v-if="forgetPass" class="sign">
            <div class="sign-top">
                <img @click="forgetPass = false" src="@/assets/images/left-arrow.png" alt="">
                <p class="sign-p1">{{ this.$t('sbros') }}</p>
            </div>
            <span class="sign-bottomLine"></span>
            <input
                class="sign-input sign-top__input"
                type="email"
                :placeholder="this.$t('mail')"
                v-model="v$.email.$model"
            />
            <template v-for="(error) of v$.email.$errors" :key="error">
              <p class="errorText">{{ error.$message }}</p>
            </template>
            <button class="sign-button sign-top__btn" @click="passForget">{{ this.$t('withoutPass') }}</button>
            <button class="sign-registrBtn">{{ this.$t('noMail') }}</button>
            <span class="sign-bottomLine"></span>
            <p class="sign-top__par">{{ this.$t('correctMail') }}</p>
          </div>
    </div>
  </template>

  <script>
  import { mask } from "vue-the-mask";
  // import { mapActions } from "vuex";
  import axios from 'axios';
  import { mapActions, mapGetters } from "vuex";
  import { createToaster } from "@meforma/vue-toaster";
  import useVuelidate from "@vuelidate/core";
  import { required, minLength, helpers, email } from "@vuelidate/validators";

  const toaster = createToaster({ position: "top-right", duration: 2000});

  export default {
    directives: { mask },
    data() {
        return {
            v$: useVuelidate(),
            sendSms: false,
            getCode: false,
            forgetPass: false,
            showPass: false,
            email: "",
            password: "",
            phone: "",
            code: ""
        }
    },
    validations() {
      return {
        email: {
            required: helpers.withMessage(this.$t('req'), required),
            email: helpers.withMessage(this.$t('reqMail'), email),
        },
        password: {
            required: helpers.withMessage(this.$t('req'), required),
            minLength: helpers.withMessage(this.$t('minLength'),minLength(6)),
        },
        phone: {
          required: helpers.withMessage('Обязательное поле', required),
          minLength: helpers.withMessage('Обязательное поле',minLength(11)),
        },
      };
    },
    created() {
      this.GET_DOCS();
    },
    methods: {
      ...mapActions(["GET_DOCS"]),
      async Login() {
        this.v$.$validate();
          axios.post("/login", {
            email: this.email,
            password: this.password
          })
          .then(res => {
            if(res.status === 200) {
              localStorage.setItem("access_token", res.data.token)
              toaster.success(this.$t('succesSign'));
              this.$router.push('/platform/cabinet');
            }
          })
          .catch(err => {
            toaster.error(this.$t('err'));
            console.log(err);
          })
      },
      toMain() {
          this.$router.push('/');
      },
      passForget() {
        axios.post('/forget-password',{
          email: this.email,
        }, {
          headers: {
            Authorization: localStorage.getItem("access_token")
              ? `Bearer ${localStorage.getItem("access_token")}`
              : null,
          },
        })
        .then((res) => {
          if(res.status === 200) {
            toaster.success(this.$t('sendMail'));
          }
        })
        .catch((err) => {
          console.log(err);
        })
      },
      async sendSmsPhone() {
        this.v$.$validate();
          axios.post(`/login/send-code`, {
            phone: this.phone,
          })
          .then(res => {
            if(res.status === 200) {
              // localStorage.setItem("access_token", res.data.token)
              toaster.success('Успешно отправлено!');
              this.getCode = true
            }
          })
          .catch(err => {
            toaster.error(this.$t('err'));
            console.log(err);
          })
      },
      async sendCode() {
        axios.post(`/login/auth-code`, {
          phone: this.phone,
          code: this.code,
        })
        .then(res => {
          if(res.status === 200) {
            localStorage.setItem("access_token", res.data.token)
            toaster.success(this.$t('succesSign'));
            this.$router.push('/platform/cabinet');
          }
        })
        .catch(err => {
          toaster.error(this.$t('err'));
          console.log(err);
        })
      }
    },
    computed: {
      ...mapGetters(["DOCS"]),
    }
  };
  </script>
  <style lang="scss" scoped>
  .block {
    display: none; /* блок по умолчанию скрыт */
  }
  .animated {
    animation-duration: 1s; /* длительность анимации */
    animation-fill-mode: both; /* сохраняем состояние после анимации */
  }
  .fadeIn {
    animation-name: fadeIn; /* имя анимации */
  }
  .show-pass {
    position: relative;
    img {
      width: 22px;
      height: 22px;
      cursor: pointer;
      position: absolute;
      right: 20px;
      top: 10px;
    }
  }
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  .logo {
    width: 170px;
    height: 35px;
    position: absolute;
    top: 24px;
    z-index: 11;
    left: 42px;
    cursor: pointer;
  }
  .backgroundSign {
    color: black;
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url(@/assets/images/mainPage/loginSignUp-bg.png);
    background-repeat: no-repeat;
    background-size: cover;
  }
  .forget {
    &-pass {
        color: #FD2D2A;
        font-size: 16px;
        font-weight: 600;
        text-align: center;
        cursor: pointer;
        margin-top: 16px;
    }
  }
  .sign {
    width: 394px;
    background: #ffffff;
    display: flex;
    flex-direction: column;
    // align-items: center;
    padding: 18px 26px;
    border-radius: 8px;
    gap: 16px;
    //   @media (max-width: $tablet) {
    //     width: 95%;
    //     box-sizing: border-box;
    //   }
    &-top {
        display: flex;
        align-items: center;
        img {
            width: 31px;
            height: 31px;
            margin-right: 15%;
            cursor: pointer;
        }
        &__btn {
            margin: 10px 0;
        }
        &__par {
            color: #7C7C7C;
            text-align: center;
            font-size: 16px;
        }
    }
    &-withSms {
      padding: 14px 16px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 8px;
      cursor: pointer;
      &-img {
        width: 20px;
        height: 20px;
      }
      &-text {
        margin-left: 18px;
      }
      &-line {
        position: absolute;
        top: -15px;
        left: 138px;
        padding: 0px 11.5px;
        width: 60px;
        text-align: center;
        background-color: white;
        // transform: translateY(-50%, -50%);
      }
    }
    &-p1 {
      font-size: 24px;
      font-weight: 700;
      letter-spacing: 0.02em;
      text-align: center;
    }
    &-bottomLine {
      border: 1px solid rgba(0, 0, 0, 0.2);
      width: 384px;
      margin: 18px 0px 14px;
      width: 100%;
      position: relative;
    }
    &-input {
      width: 100% !important;
      background: #f5f7fb;
      box-shadow: inset 0px 0px 4px 2px rgba(0, 0, 0, 0.05);
      backdrop-filter: blur(2px);
      border-radius: 4px;
      border: none;
      height: 42px;
      padding: 10px 0px 10px 18px;
      box-sizing: border-box;
      // outline-color: teal;
      font-style: italic;
      font-weight: 400;
      font-size: 18px;
      line-height: 22px;
      color: #8e8e8e;
      width: 340px;
    }
    &__remember {
      width: 340px;
      margin-bottom: 8px;
      &-inp {
        background: #ffffff;
        border: 1px solid rgba(124, 124, 124, 0.24);
        border-radius: 4px;
        width: 20px;
        height: 20px;
      }
      &-text {
        font-weight: 400;
        font-size: 18px;
        line-height: 22px;
        color: #7c7c7c;
        margin-left: 14px;
      }
    }
  
    &-button {
      height: 48px;
      font-weight: 700;
      font-size: 18px;
      line-height: 22px;
      text-align: center;
      color: #ffffff;
      background-color: #fd2d2a;
      border-radius: 4px;
      border: none;
      padding: 13px 0px;
      width: 100%;
    }
    &-registrBtn {
      height: 48px;
      font-weight: 700;
      font-size: 18px;
      line-height: 22px;
      text-align: center;
      color: #fd2d2a;
      background-color: #ffffff;
      border-radius: 4px;
      border: 1px solid #fd2d2a;
      padding: 13px 0px;
      width: 100%;
    }
    &-p2 {
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: #fd2d2a;
      text-align: center;
    }
    &-agreement {
      font-weight: 400;
      font-size: 16px;
      text-align: center;
      letter-spacing: 0.02em;
      color: #7c7c7c;
      &-docs {
        font-weight: 600;
        color: #fd2d2a;
      }
    }
  }
  @media (min-width: 1200px) and (max-width: 1400px) {
    .sign {
      gap: 5px;
    }
  }
  
  @media (min-width: 991px) and (max-width: 1200px) {
    .sign {
      gap: 7px;
    }
  }
  
  @media (max-width: 990px) {
    .backgroundSign {
      background-image: url(@/assets/images/loginSignUp.png);
      background-repeat: no-repeat;
      background-size: cover;
      min-height: 100vh;
      height: 100%;
      overflow-y: scroll;
      padding: 70px 0;
    }
    .sign {
      z-index: 111;
      overflow-y: scroll;
    }
  }
  </style>
  