<template>
  <div class="programPlus" v-if="MAIN_PAGE">
    <div class="container">
      <div class="programPlus__content">
        <div class="programPlus__main" v-if="MAIN_PAGE.homeMap">
          <h3 class="programPlus__main-title r16-600">{{ this.$t("ourStud") }}</h3>
          <h1
            class="programPlus__main-title2 w36-700"
            v-html="MAIN_PAGE.homeMap.title"
          ></h1>
          <p
            class="programPlus__main-text w18-600"
            v-html="MAIN_PAGE.homeMap.description"
          ></p>
        </div>
        <!-- <div class="programPlus__image">
          <img
            class="programPlus__image-img"
            src="@/assets/images/mainPage/world-map.png"
            alt=""
          />
        </div> -->
      </div>
    </div>
    <map-component/>
  </div>
</template>

<script>
import MapComponent from './MapComponent.vue';
export default {
  components: { MapComponent },
  props: ["MAIN_PAGE"],
};
</script>

<style lang="scss" scoped>
.programPlus {
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.351) 0%,
    rgba(0, 0, 0, 0) 0%
  );
  // backdrop-filter: blur(9px);
  &__content {
    padding: 80px 0px 0 0;
  }
  &__main {
    align-items: center;
    margin-bottom: 80px;
    &-title {
      text-align: center;
    }
    &-title2 {
      text-align: center;
      padding: 4px 18px;
    }
    &-text {
      text-align: center;
    }
  }
  &__image {
    // filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.15));
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(60px);
    padding: 12px;
    border-radius: 12px;
    max-width: 1640px;
    max-height: 800px;
    &-img {
      width: 100%;
      height: 100%;
      border-radius: 12px;
      @media(max-width:$mobile){
        height:30vh;
      }
    }
  }
}

@media (max-width: 990px) {
  .programPlus {
    &__content {
      padding-top: 18px;
      padding-bottom:18px;
    }
    &__main {
      margin-bottom: 18px;
      &-title {
        text-align: left;
      }
      &-title2 {
        text-align: left;
        font-size: 22px;
        font-weight: 700;
        padding: 0px;
      }
      &-text {
        text-align: left;
        font-size: 14px;
        font-weight: 600;
      }
    }
  }
}
</style>
