<template>
  <platformModalVue v-if="$store.state.platformModal == true" />
  <div class="program" v-if="PROGRAM_ID">
    <div class="program-wrap">
      <div class="program-top">
        <p class="program-top__left">
          <span @click="goBack" style="display: flex;">
            <img src="@/assets/images/platform/red-arrow1.png" style="cursor: pointer;" alt="Arrow" />
            <p class="m_none">{{ $t("searchProgram") }} /</p>
          </span>
          <span>{{ PROGRAM_ID?.program?.programName }}</span>
        </p>
        <a
          target="_blank"
          :href="PROGRAM_ID?.program?.link"
          class="link fw600 px-16 white"
        >
          <img src="@/assets/images/platform/info-outline.png" alt="" />
          <p class="m_none">{{ this.$t("linkPr") }}</p>
        </a>
      </div>
      <div class="program-mid" v-if="PROGRAM_ID.program">
        <div
          class="program-mid__left"
          :style="{
            'background-image': `url('` + PROGRAM_ID?.program?.image + `')`,
          }"
        >
          <img
            class="program-mid__left-img d_none"
            :src="PROGRAM_ID?.program?.image"
            alt=""
          />
          <div class="program-mid__left-video m_none">
            <a
              target="_blank"
              :href="PROGRAM_ID?.program?.video"
              class="video px-18"
            >
              <img src="@/assets/images/platform/play.png" alt="" />
              {{ this.$t("videoTour") }}
            </a>
            <span class="line"></span>
            <div class="fav px-18" @click="addFavour()">
              <img src="@/assets/images/platform/fav.png" alt="" />
              <p v-if="!isLiked">{{ this.$t("addFavor") }}</p>
              <p v-else>{{ this.$t("deleteFav") }}</p>
            </div>
          </div>
        </div>
        <div class="program-mid__left-video d_none">
          <a
            target="_blank"
            :href="PROGRAM_ID?.program?.video"
            class="video px-18"
          >
            <img src="@/assets/images/platform/play.png" alt="" />
            {{ this.$t("videoTour") }}
          </a>
          <div class="fav px-18" @click="addFavour()">
            <img src="@/assets/images/platform/fav.png" alt="" />
            <p v-if="!isLiked">{{ this.$t("addFavor") }}</p>
            <p v-else>{{ this.$t("deleteFav") }}</p>
          </div>
        </div>
        <div class="program-mid__right">
          <h2 class="px-24 fw700 m_none">
            {{ PROGRAM_ID?.program?.programName
            }}<span class="fw400 gray">{{
              PROGRAM_ID?.program?.programCode
            }}</span>
          </h2>
          <div class="info">
            <p class="gray px-16">{{ this.$t("longEd") }}:</p>
            <p class="px-16 fw600 end">{{ PROGRAM_ID?.program?.duration }}</p>
          </div>
          <div class="info">
            <p class="gray px-16">{{ this.$t("country") }}:</p>
            <p class="px-16 fw600 end">
              {{ PROGRAM_ID?.program?.country
              }}<span v-if="PROGRAM_ID?.program?.city">,</span>
              {{ PROGRAM_ID?.program?.city }}
            </p>
          </div>
          <div class="info">
            <p class="gray px-16">GPA:</p>
            <p class="px-16 fw600 end">{{ PROGRAM_ID?.program?.gpa }}</p>
          </div>
          <div class="info">
            <p class="gray px-16">{{ this.$t("langLevel") }}:</p>
            <p class="px-16 fw600 end">{{ PROGRAM_ID?.program?.language }}</p>
          </div>
          <div class="info">
            <p class="gray px-16">GRE/GMAT:</p>
            <p class="px-16 fw600 end">{{ PROGRAM_ID?.program?.gre }}</p>
          </div>
          <div class="info">
            <p class="gray px-16">{{ this.$t("fallStart") }}:</p>
            <p
              v-if="PROGRAM_ID?.program?.autumn_admission_start_date != null"
              class="px-16 fw600 end"
            >
              с
              {{
                format_date(PROGRAM_ID?.program?.autumn_admission_start_date)
              }}
              - по
              {{ format_date(PROGRAM_ID?.program?.autumn_admission_end_date) }}
            </p>
            <p v-else class="px-16 fw600 end">-</p>
          </div>
          <div class="info">
            <p class="gray px-16">{{ this.$t("fallEnd") }}:</p>
            <p
              v-if="PROGRAM_ID?.program?.autumn_program_start_date != null"
              class="px-16 fw600 end"
            >
              {{ format_date(PROGRAM_ID?.program?.autumn_program_start_date) }}
            </p>
            <p v-else class="px-16 fw600 end">-</p>
          </div>
          <div class="info">
            <p class="gray px-16">{{ this.$t("winterStart") }}:</p>
            <p
              v-if="PROGRAM_ID?.program?.winter_admission_start_date != null"
              class="px-16 fw600 end"
            >
              с
              {{
                format_date(PROGRAM_ID?.program?.winter_admission_start_date)
              }}
              - по
              {{ format_date(PROGRAM_ID?.program?.winter_admission_end_date) }}
            </p>
            <p v-else class="px-16 fw600 end">-</p>
          </div>
          <div class="info">
            <p class="gray px-16">{{ this.$t("winterEnd") }}:</p>
            <p
              v-if="PROGRAM_ID?.program?.winter_program_start_date != null"
              class="px-16 fw600 end"
            >
              {{ format_date(PROGRAM_ID?.program?.winter_program_start_date) }}
            </p>
            <p v-else class="px-16 fw600 end">-</p>
          </div>
          <div class="info">
            <p class="gray px-16">{{ this.$t("priceEd") }}:</p>
            <p class="px-16 fw600 end">{{ PROGRAM_ID?.program?.price }}</p>
          </div>
        </div>
      </div>
      <div class="program-btm">
        <div class="program-btm__top m_none">
          <platform-tab
            :tabItems="tabItems"
            @setTab="selectNewsTab"
            :selected="selectedNewsTab"
            class="program-btm__tab"
          >
          </platform-tab>
          <hr />
        </div>
        <span class="program-btm__mobile d_none">
          <select
            class="SelectMobile"
            v-model="selectedNewsTab"
            @setTab="selectNewsTab"
          >
            <option
              v-for="(item, index) in tabItems"
              :key="index"
              :value="item"
            >
              {{ item }}
            </option>
          </select>
        </span>
        <tab-block :isSelected="selectedNewsTab === this.$t('list')">
          <p class="px-24 fw700 black list" style="margin-bottom: 28px">
            {{ this.$t("list") }}
          </p>
          <p class="px- gray" v-html="PROGRAM_ID?.program?.details"></p>
        </tab-block>
        <tab-block :isSelected="selectedNewsTab === this.$t('letter')">
          <p class="px-24 fw700" style="margin-bottom: 28px">
            {{ this.$t("letter") }}
          </p>
          <p class="px-18 gray" v-html="PROGRAM_ID?.program?.letter"></p>
        </tab-block>
        <tab-block :isSelected="selectedNewsTab === this.$t('exp')">
          <p class="px-24 fw700" style="margin-bottom: 28px">
            {{ this.$t("exp") }}
          </p>
          <p
            class="px-18 gray"
            v-html="PROGRAM_ID?.program?.workExperience"
          ></p>
        </tab-block>
        <tab-block :isSelected="selectedNewsTab === this.$t('other')">
          <p class="px-24 fw700" style="margin-bottom: 28px">
            {{ this.$t("other") }}
          </p>
          <p class="px-18 gray" v-html="PROGRAM_ID?.program?.other"></p>
        </tab-block>
      </div>
    </div>
    <div class="similar" v-if="PROGRAM_ID.similarPrograms">
      <p class="similar-title px-24 fw600 black">{{ this.$t("similar") }}</p>
      <p class="similar-desc px-16 gray">{{ this.$t("similarLike") }}</p>
      <div class="similar-cards">
        <program-card
          v-for="(item, index) in PROGRAM_ID.similarPrograms"
          :item="item"
          :key="index"
        />
      </div>
    </div>
  </div>
</template>

<script>
import platformTab from "@/components/tab/platformTab.vue";
import tabBlock from "@/components/tab/tabBlock.vue";
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import ProgramCard from "@/components/platform/ProgramCard.vue";
import axios from "axios";
import { createToaster } from "@meforma/vue-toaster";
import platformModalVue from "@/components/modals/platformModal.vue";

const toaster = createToaster({ position: "top-right", duration: 2000 });

export default {
  components: { platformTab, tabBlock, ProgramCard, platformModalVue },
  data() {
    return {
      selectedNewsTab: this.$t("list"),
      tabItems: [
        this.$t("list"),
        this.$t("letter"),
        this.$t("exp"),
        this.$t("other"),
      ],
    };
  },
  created() {
    this.GET_PROGRAM_ID(this.$route.params.id);
  },
  methods: {
    ...mapActions(["GET_PROGRAM_ID", "GET_FAVS"]),
    goBack() {
      this.$router.go(-1);
    },
    selectNewsTab(tab) {
      this.selectedNewsTab = tab;
    },
    async addFavour() {
      await axios
        .post(
          "/user-university-program-favorite",
          {
            university_program_id: this.PROGRAM_ID?.program?.id,
          },
          {
            headers: {
              Authorization: localStorage.getItem("access_token")
                ? `Bearer ${localStorage.getItem("access_token")}`
                : null,
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            if (!this.isLiked) {
              toaster.success(this.$t("notAddFav"));
            } else {
              toaster.error(this.$t("notRemFav"));
            }
            this.GET_FAVS();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    format_date(value) {
      moment.locale("ru");
      return moment(String(value)).format("L");
    },
  },
  computed: {
    ...mapGetters(["PROGRAM_ID", "getFavs"]),
    isLiked() {
      return this.getFavs?.some(
        (item) => item.id === this.PROGRAM_ID.program.id
      );
    },
  },
  watch: {
    $route() {
      this.GET_PROGRAM_ID(this.$route.params.id);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/platform/main.scss";
.blur {
  filter: blur(2px);
}
.similar {
  margin: 30px 0;
  padding: 24px 0 24px 28px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 24px 0px 0px 24px;
  &-title,
  &-desc {
    text-align: center;
  }
  &-desc {
    margin-bottom: 20px;
  }
  &-title {
    margin-bottom: 8px;
  }
  &-cards {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    padding-top: 28px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(19%, 1fr));
    // grid-template-columns: repeat(6, 1fr);
    grid-column-gap: 10px;
  }
  &-item {
    width: 220px;
    height: 280px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-right: 1%;
    margin-bottom: 18px;
    transition: all ease 0.4s;
    position: relative;
    cursor: pointer;
    .lock {
      width: 90px;
      height: 90px;
      object-fit: contain;
      position: absolute;
      z-index: 2;
      left: 30%;
      right: 30%;
      top: 28%;
    }
    .image {
      position: absolute;
      width: 220px;
      height: 280px;
      top: 0;
      left: 0;
      border-radius: 8px;
      z-index: 1;
    }
    &__top {
      background: #ffffff;
      border-radius: 4px;
      text-align: center;
      margin: 0 auto;
      padding: 4px 10px;
      z-index: 2;
      position: absolute;
      left: 10px;
      right: 10px;
      top: 10px;
    }
    &__bottom {
      background: #ffffff;
      border-radius: 8px;
      padding: 6px 12px;
      z-index: 2;
      position: absolute;
      bottom: 10px;
      left: 10px;
      right: 10px;
      p {
        text-align: center;
        &:first-child {
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);
          padding-bottom: 6px;
        }
        &:last-child {
          padding-top: 6px;
        }
      }
    }
  }
}
.program {
  padding-right: 0px;
  &-wrap {
    padding: 24px 0 24px 28px;
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 24px 0px 0px 24px;
  }
  &-top {
    display: flex;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding-bottom: 24px;
    &__left {
      font-size: 20px;
      font-weight: 600;
      display: flex;
      img {
        width: 24px;
        height: 24px;
        // transform: rotate(180deg);
        margin-right: 8px;
      }
      a {
        color: #fd2d2a !important;
        display: flex;
        align-items: center;
      }
      span {
        margin-left: 5px;
      }
    }
    .link {
      padding: 6px 10px;
      border-radius: 8px;
      background: #11415d;
      display: flex;
      align-items: center;
      color: #fff;
      margin-left: 24px;
      height: 32px;
      cursor: pointer;
      img {
        width: 20px;
        height: 20px;
        margin-right: 10px;
      }
    }
  }
  &-mid {
    margin: 28px 0;
    display: flex;
    &__left {
      // background-image: url('@/assets/images/platform/slug.png');
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      width: 725px;
      height: 408px;
      border-radius: 18px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      padding: 0 12px;
      position: relative;
      &-video {
        border-radius: 8px 8px 14px 14px;
        background: #fff;
        padding: 10px 0;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        position: absolute;
        bottom: 12px;
        width: 97%;
        .line {
          border-left: 1px solid rgba(0, 0, 0, 0.1);
          height: 24px;
        }
        img {
          width: 22px;
          height: 22px;
          margin-right: 8px;
        }
        .fav {
          color: #ffb100;
        }
        .video,
        .fav {
          cursor: pointer;
          display: flex;
          align-items: center;
        }
        .video {
          color: #000;
        }
      }
    }
    &__right {
      margin-left: 40px;
      h2 {
        margin-bottom: 24px;
      }
      .info {
        display: flex;
        margin-bottom: 16px;
        p {
          &:last-child {
            margin-left: 5px;
          }
        }
      }
    }
  }
  &-btm {
    &__top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 28px;
      hr {
        width: 48%;
        color: rgba(0, 0, 0, 0.1);
      }
    }
    .list {
      li {
        margin-bottom: 10px;
      }
    }
  }
}
@media (min-width: 960px) and (max-width: 1760px) {
  .program {
    &-mid {
      &__left {
        width: 45%;
        background-position: center;
        &-video {
          a,
          .fav {
            font-size: 14px;
          }
        }
      }
      &__right {
        width: 40%;
        margin-left: 25px;
      }
    }
    &-btm {
      width: 90%;
    }
  }
  .similar {
    &-cards {
      grid-template-columns: repeat(auto-fit, minmax(23%, 1fr));
    }
    &-item {
      width: 100%;
      .image {
        width: 100%;
        object-fit: cover;
      }
      .lock {
        width: 60px;
        height: 60px;
        top: 22%;
        left: 35%;
      }
    }
  }
}
@media (min-width: 960px) and (max-width: 1300px) {
  .similar {
    &-cards {
      grid-template-columns: repeat(auto-fit, minmax(32%, 1fr));
    }
  }
}
@media (max-width: 960px) {
  .px-24 {
    font-size: 22px;
  }
  .program {
    padding-right: 0;
    &-wrap {
      border: none;
      padding: 0;
    }
    &-top {
      border-bottom: none;
      padding-bottom: 18px;
      justify-content: space-between;
      .link {
        img {
          margin-right: 0;
        }
      }
      &__left {
        span {
          font-size: 22px;
          font-weight: 700;
        }
      }
    }
    &-mid {
      flex-direction: column;
      margin: 0;
      &__left {
        width: 100%;
        background-position: center;
        height: 232px;
        padding: 8px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        background-image: none !important;
        &-img {
          border-radius: 8px;
          object-fit: cover;
        }
        &-video {
          position: relative;
          bottom: 0;
          flex-direction: column;
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);
          border-radius: 0px;
          padding: 12px 0;
          img {
            margin-right: 10px;
            width: 24px;
            height: 24px;
          }
          a,
          .fav {
            border-radius: 4px;
            border: 1px solid rgba(0, 0, 0, 0.1);
            padding: 9px;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 6px 0;
          }
        }
      }
      &__right {
        margin-left: 0;
        margin-top: 18px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        .info {
          justify-content: space-between;
          p {
            font-weight: 600;
          }
          .end {
            text-align: end;
          }
        }
      }
    }
    &-btm {
      margin: 18px 0;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      &__mobile {
        select {
          border-radius: 4px;
          border: 1px solid rgba(0, 0, 0, 0.1);
          padding: 10px 14px;
          width: 100%;
          appearance: none;
          background: url("@/assets/images/platform/arr-gray.png") 96% / 20px
            no-repeat;
          color: #7c7c7c;
          font-size: 16px;
          font-weight: 700;
          margin-bottom: 18px;
        }
      }
      .list {
        font-size: 22px;
        &-item {
          margin: 18px 0 !important;
          font-weight: 600;
          font-size: 14px;
          letter-spacing: 0.28px;
        }
      }
      .item {
        font-size: 14px;
        font-weight: 600;
        margin: 18px 0 !important;
      }
      ul {
        li {
          font-size: 14px;
          font-weight: 600;
        }
      }
    }
  }
  .similar {
    padding: 0;
    margin: 0;
    border: none;
    &-title {
      margin-bottom: 18px;
      text-align: left;
    }
    &-desc {
      text-align: left;
      margin-bottom: 18px;
    }
    &-cards {
      border-top: none;
      padding-top: 0;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      width: 100%;
      .lock {
        width: 60px;
        height: 60px;
        left: 33%;
        top: 22%;
      }
    }
    &-item {
      width: 100%;
      height: 248px;
      padding: 8px;
      .image {
        width: 100%;
        position: relative;
        height: 150px;
        object-fit: cover;
        object-position: top;
      }
      &__bottom {
        position: relative;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 8px 0;
      }
    }
  }
}
</style>
