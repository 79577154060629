<template>
  <div class="counter" v-if="MAIN_PAGE">
    <div class="container" v-if="MAIN_PAGE?.homeNumbers">
      <div class="counter__content">
        <div class="counter__main">
          <div
            class="counter__item d-flex"
            v-for="(item, index) in MAIN_PAGE.homeNumbers"
            :key="index"
          >
            <div class="counter__item-info">
              <Vue3autocounter
                class="counter__item-num" 
                ref="counter" 
                :startAmount="0" 
                :endAmount="parseInt(item.title)" 
                separator=" " 
                :duration="5" 
                :autoinit="true"/>
              <!-- <h2 class="counter__item-num">{{ item.title }}</h2> -->
              <p class="counter__item-text">{{ item.description }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue3autocounter from "vue3-autocounter";
export default {
  props: ["MAIN_PAGE"],
  components: {Vue3autocounter },
  data() {
    return {
    }
  },
};
</script>

<style lang="scss" scoped>
.counter {
  filter: drop-shadow(4px 12px 8px rgba(0, 0, 0, 0.02));
  z-index: 99;
  position: relative;
  &__content {
    position: relative;
  }
  &__main {
    width: 100%;
    top: -75px;
    background-color: #fff;
    position: absolute;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.4);
    padding: 18px 0;
    border-radius: 10px;
    @media (max-width: $laptop) {
      overflow: auto;
    }
  }
  &__item {
    width: 100%;
    justify-content: center;
    &:not(:last-child) {
      border-right: 2px solid rgba(0, 0, 0, 0.1);
    }
    &-info {
      padding: 0px 10px;
      display: flex;
      flex-direction: column;
    }
    &-num {
      font-weight: 500;
      font-size: 50px;
      text-align: center;
      letter-spacing: 0.02em;
      color: #11415d;
    }
    &-text {
      font-weight: 400;
      font-size: 22px;
      line-height: 130%;
      text-align: center;
      letter-spacing: 0.02em;
      color: #11415d;
    }
    &-line:not(:last-child) {
      border-right: 2px solid rgba(0, 0, 0, 0.1);
    }
  }
}
@media (min-width: 1024px) and (max-width: 1440px) {
  .counter {
    &__main {
      width: 100%;
      top: -75px;
      background-color: #fff;
      position: absolute;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.4);
      padding: 18px 0px;
      border-radius: 10px;
    }
    &__item {
      &:not(:last-child) {
        border-right: 2px solid rgba(0, 0, 0, 0.1);
      }
      &-info {
        padding: 0px 40px;
      }
      &-num {
        font-size: 34px;
      }
      &-text {
        font-size: 16px;
        line-height: 130%;
      }
      // &-line {
      //   border-right: 2px solid rgba(0, 0, 0, 0.1);
      // }
    }
  }
}

@media (max-width: 990px) {
  .counter {
    .container {
      padding: 0px;
    }
    &__main {
      top: 0px;
      padding: 14px 7px 18px 0px !important;
      background-color: transparent !important;
      box-shadow: none;
      padding: 14px 7px 18px 16px;
      border-radius: 0px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      overflow: hidden;
      // overflow-x: scroll;
    }
    &__item {
      &-info {
        padding: 0px 5px;
      }
      &-num {
        font-weight: 700;
        font-size: 20px;
      }
      &-text {
        font-weight: 400;
        font-size: 12px;
      }
    }
  }
}
</style>
