<template>
  <platformModalVue v-if="$store.state.platformModal == true" />
  <div class="plat-wrap">
    <div class="plat-right__items-wrap">
      <div class="head-search d_none">
        <img src="@/assets/images/platform/search-grey.svg" alt="" />
        <input
          :placeholder="this.$t('searchSite') + '...'"
          type="text"
          ref="search"
          v-model="search_program"
          @keyup="searchHandle($event)"
        />
      </div>
      <p class="plat-right__title px-20 fw600 m_none" style="margin-right: 2%">
        {{ $t("searchProgram") }}
      </p>
      <div class="plat-right__title-wrap d_none">
        <p class="plat-right__title px-20 fw600">{{ $t("searchProgram") }}</p>
        <img
          class="d_none"
          @click="isFilterShow"
          src="@/assets/images/platform/logo/filter-outline.png"
          alt=""
        />
        <img
          class="m_none"
          @click="isFilter = true"
          src="@/assets/images/platform/logo/filter-outline.png"
          alt=""
        />
      </div>
      <div class="plat-right__items m_none" v-if="UNI_PROGRAMS">
        <program-card
          v-for="(item, index) in UNI_PROGRAMS"
          :key="index"
          :item="item"
        />
      </div>
      <div class="plat-right__items d_none" v-if="UNI_PROGRAMS">
        <program-card
          v-for="(item, index) in UNI_PROGRAMS?.slice(0, this.step)"
          :key="index"
          :item="item"
        />
      </div>
      <p v-if="UNI_PROGRAMS.length == 0" class="none-program px-16 fw700 black">
        {{ this.$t("noProgram") }}
      </p>
      <div class="plat-right__btn d_none" v-if="!isShow">
        <button
          v-if="UNI_PROGRAMS?.length > 0"
          class="px-16 fw700 blue"
          @click="showMore"
        >
          {{ $t("more2") }}
        </button>
      </div>
      <div class="plat-news d_none">
        <p class="plat-news__title px-22 fw700 black">{{ $t("news") }}</p>
        <div class="plat-news__items">
          <a
            v-for="(item, index) in NEWS"
            :key="index"
            :href="item.link"
            target="_blank"
          >
            <div class="plat-news__items-card">
              <img :src="item.image" alt="" />
              <p class="gray px-16">{{ item.title }}</p>
            </div>
          </a>
        </div>
      </div>
    </div>
    <div class="filter-full" :class="{ activeFilter: isFilter }" v-if="FILTER">
      <div class="filter-full__top">
        <img
          class="m_none"
          @click="isFilter = true"
          v-if="!isFilter"
          src="@/assets/images/platform/filter0.png"
          alt=""
        />
        <img
          class="m_none"
          @click="isFilter = false"
          v-if="isFilter"
          src="@/assets/images/platform/red-arrow.png"
          alt=""
        />
        <p v-if="isFilter" class="px-16 fw600 m_none">
          {{ this.$t("filterSearch") }}
        </p>
        <div v-if="isFilter" class="filter-full__top-mobile d_none">
          <p class="px-16 fw600">{{ this.$t("filterSearch") }}</p>
          <img
            class="d_none"
            @click="isFilterClose"
            src="@/assets/images/platform/logo/filter-outline-red.png"
            alt=""
          />
          <img
            class="m_none"
            @click="isFilter = false"
            src="@/assets/images/platform/logo/filter-outline-red.png"
            alt=""
          />
        </div>
      </div>
      <div v-if="isFilter" class="filter-full__btm">
        <div class="">
          <div class="filter-full__btm-select">
            <div class="custom-select">
              <div class="selected-option px-14" @click="toggleDropdown">
                {{ selectedOption.title }}
                <img
                  :class="{ checkLang: isDropdownOpen }"
                  src="@/assets/images/platform/arrow.png"
                  alt=""
                />
              </div>
              <ul v-if="isDropdownOpen" class="options filter-full__btm-opt">
                <li
                  class="option gray"
                  v-for="option in FILTER.categories"
                  :key="option"
                  @click="selectOption(option)"
                >
                  {{ option.title }}
                </li>
              </ul>
            </div>
          </div>
          <div class="filter-full__btm-select" v-if="showSub">
            <div class="custom-select">
              <div class="selected-option px-14" @click="toggleDropdown2">
                {{ selectedOption2.title }}
                <img
                  :class="{ checkLang: isDropdownOpen2 }"
                  src="@/assets/images/platform/arrow.png"
                  alt=""
                />
              </div>
              <input
                class="search-category"
                v-if="isDropdownOpen2"
                type="text"
                v-model="search_input"
                @input="handleSearch($event)"
                :placeholder="this.$t('searchSub') + '...'"
              />
              <div class="" v-if="isSearch">
                <ul v-if="isDropdownOpen2" class="options filter-full__btm-opt">
                  <li
                    class="option gray"
                    v-for="option in filteredSpecialties"
                    :key="option"
                    @click="selectOption2(option)"
                  >
                    {{ option.title }}
                  </li>
                </ul>
              </div>
              <div class="" v-else>
                <ul v-if="isDropdownOpen2" class="options filter-full__btm-opt">
                  <li
                    class="option gray"
                    v-for="option in selectedOption.subCategories"
                    :key="option"
                    @click="selectOption2(option)"
                  >
                    {{ option.title }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="filter-full__btm-select">
            <div class="custom-select">
              <div class="selected-option px-14" @click="toggleDropdown1">
                {{ selectedOption1.title }}
                <img
                  :class="{ checkLang: isDropdownOpen1 }"
                  src="@/assets/images/platform/arrow.png"
                  alt=""
                />
              </div>
              <ul v-if="isDropdownOpen1" class="options filter-full__btm-opt">
                <li
                  class="option gray"
                  v-for="option in FILTER.countries"
                  :key="option"
                  @click="selectOption1(option)"
                >
                  {{ option.title }}
                </li>
              </ul>
            </div>
          </div>
          <div class="filter-full__btm-select">
            <div class="filter-full__btm-item" @click="clicked = !clicked">
              <p class="px-14">{{ this.$t("reqLang") }}</p>
              <img
                :class="{ checkLang: clicked }"
                src="@/assets/images/platform/arrow.png"
                alt=""
              />
            </div>
            <div class="filter-full__btm-option" v-show="clicked">
              <div class="opt">
                <input
                  v-model="is_ielts"
                  true-value="1"
                  type="checkbox"
                  name=""
                  id="id1"
                />
                <label class="gray px-14" for="id1">IELTS</label>
              </div>
              <div class="opt">
                <input
                  v-model="is_toefl"
                  true-value="1"
                  type="checkbox"
                  name=""
                  id="id2"
                />
                <label class="gray px-14" for="id2">TOEFL</label>
              </div>
            </div>
          </div>
        </div>
        <div class="filter-full__btm-btns">
          <button class="button active-btn" @click="applyFilters">
            {{ this.$t("apply") }}
          </button>
          <button class="button red-btn" @click="resetFilters">
            {{ this.$t("reset") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import platformModalVue from "@/components/modals/platformModal.vue";
import { mapGetters, mapActions } from "vuex";
import ProgramCard from "@/components/platform/ProgramCard.vue";
export default {
  components: { platformModalVue, ProgramCard },
  data() {
    return {
      step: 6,
      isShow: false,
      isDropdownOpen: false,
      isDropdownOpen1: false,
      isDropdownOpen2: false,
      search_input: "",
      search_program: "",
      search: "",
      is_toefl: "",
      is_ielts: "",
      showSub: false,
      selectedOption: {
        title: this.$t("cat1"),
      },
      selectedOption1: {
        title: this.$t("cat2"),
      },
      selectedOption2: {
        title: this.$t("cat3"),
      },
      options: [
        "Специальность 1 (x0252549)",
        "Специальность 1 (x0252549)",
        "Специальность 1 (x0252549)",
      ],
      options1: ["United States of America", "Canada", "Denmark"],
      isFilter: true,
      clicked: false,
      clicked1: false,
      program_id: "",
      country_id: "",
      isSearch: false,
      news: [
        {
          title: this.$t("add120"),
          img: require("@/assets/images/platform/platform-news1.png"),
        },
        {
          title: this.$t("add5"),
          img: require("@/assets/images/platform/platform-news2.png"),
        },
        {
          title: this.$t("addServ"),
          img: require("@/assets/images/platform/platform-news3.png"),
        },
      ],
    };
  },
  created() {
    this.GET_FILTER();
    this.GET_UNI_PROGRAMS(this.$route.query.search);
    this.GET_NEWS();
    if (window.innerWidth <= 960) {
      this.isFilter = false;
    }
  },
  computed: {
    ...mapGetters(["FILTER", "UNI_PROGRAMS", "NEWS"]),
    filteredSpecialties() {
      const lowerSearchInput = this.search_input.toLowerCase();

      const matchingResults = this.selectedOption?.subCategories?.filter(
        (specialty) =>
          specialty.title.toLowerCase().startsWith(lowerSearchInput)
      );

      const remainingResults = this.selectedOption?.subCategories?.filter(
        (specialty) =>
          !specialty.title.toLowerCase().startsWith(lowerSearchInput)
      );

      const sortedResults = matchingResults.concat(remainingResults);

      return sortedResults;
    },
  },
  methods: {
    ...mapActions(["GET_FILTER", "GET_UNI_PROGRAMS", "GET_NEWS"]),
    showMore() {
      this.step = this.UNI_PROGRAMS.length;
      this.isShow = true;
    },
    isFilterShow() {
      this.isFilter = true;
      document.body.classList.add("filter-open");
      document.documentElement.style.overflow = "hidden";
      document.documentElement.style.position = "fixed";
      document.documentElement.style.height = "100%";
      document.documentElement.style.width = "100%";
    },
    isFilterClose() {
      this.isFilter = false;
      document.body.classList.remove("filter-open");
      document.documentElement.style.overflow = "auto";
      document.documentElement.style.position = "relative";
      document.documentElement.style.height = "auto";
    },
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
      this.isDropdownOpen1 = false;
      this.isDropdownOpen2 = false;
    },
    toggleDropdown1() {
      this.isDropdownOpen1 = !this.isDropdownOpen1;
    },
    toggleDropdown2() {
      this.isDropdownOpen2 = !this.isDropdownOpen2;
      this.isDropdownOpen1 = false;
    },
    selectOption(option) {
      this.selectedOption = option;
      this.selectedId = option.id;
      this.isDropdownOpen = false;
      this.showSub = true;
      this.selectedOption2.title = this.$t("cat3");
    },
    selectOption1(option) {
      this.selectedOption1 = option;
      this.selectedId1 = option.id;
      this.isDropdownOpen1 = false;
    },
    selectOption2(option) {
      this.selectedOption2 = option;
      this.selectedId2 = option.id;
      this.isDropdownOpen2 = false;
      this.isDropdownOpen1 = false;
    },
    async resetFilters() {
      (this.sub_category_id = ""),
        (this.category_id = ""),
        (this.country_id = ""),
        (this.is_ielts = "");
      this.is_toefl = "";
      this.selectedOption.title = this.$t("cat1");
      this.selectedOption1.title = this.$t("cat2");
      this.selectedOption2.title = this.$t("cat3");
      this.showSub = false;
      this.isShow = false;
      await this.GET_UNI_PROGRAMS();
    },
    async applyFilters() {
      await this.GET_UNI_PROGRAMS({
        is_toefl: this.is_toefl,
        is_ielts: this.is_ielts,
        category_id: this.selectedId,
        sub_category_id: this.selectedId2,
        country_id: this.selectedId1,
        search: this.$route.query.search,
      });
      this.$router.push({
        path: `/platform/programs?search=${this.$route.query.params}`,
        query: {
          is_toefl: this.is_toefl,
          is_ielts: this.is_ielts,
          category_id: this.selectedId,
          sub_category_id: this.selectedId2,
          country_id: this.selectedId1,
        },
      });
      this.isFilterClose();
    },
    handleSearch(event) {
      let res = event.target.value;
      if (res.length > 0) {
        this.isSearch = true;
      }
    },
    async searchHandle(e) {
      let res = e.target.value;
      if (res.length >= 3) {
        await this.GET_UNI_PROGRAMS({
          search: res,
        }).then(() => {
          // console.log(this.UNI_PROGRAMS);
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/platform/main.scss";
@import "@/assets/styles/platform/platformPage.scss";
.active-btn {
  background: #11415d;
  color: #fff;
}
.plat-wrap {
  padding: 24px 0 24px 28px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 24px 0px 0px 0px;
}
@media (max-width: 960px) {
  .plat-wrap {
    padding: 0;
    border: none;
  }
  .head {
    &-search {
      position: relative;
      margin-bottom: 30px;
      img {
        width: 18px;
        margin-left: 21px;
        position: absolute;
      }
      input {
        padding: 10px 0px 12px 54px;
        background: #ffffff;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 12px;
        font-style: italic;
        width: 100%;
      }
    }
  }
}
</style>
