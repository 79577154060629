<template>
  <a :href="link">
    <div
        :class="['card-component', isTransparent ? 'transparent' : '']"
        :style="style"
        @mouseenter="showOverlay"
        @mouseleave="hideOverlay"
        @click="showOverlay"
    >
  
      <div class="card-component-image">
        <v-lazy-image class="card-component-image-img" v-if="!isTransparent" :src="imgURL" alt="" />
        <v-lazy-image class="card-component-image-img1" v-else :src="imgURL" alt="" />
        <p v-if="!isTransparent" class="card-component-image-text">{{ title }}</p>
      </div>
      <transition name="slide-up">
        <div class="card-component-overlay" >
          <h2 class="card-component-overlay-title">{{ title }}</h2>
          <p :class="{'showmore' : showMore[index]}" class="card-component-overlay-title-info" v-html="desc"></p>
          <p v-show="!showMore[index]" @click="showMore[index] = true" class="d_none" style="font-size: 14px; font-weight: 500; color: #fff; text-align: center; cursor: pointer;" >{{ this.$t("more1") }}</p>
        </div>
      </transition>
    </div>
  </a>
</template>

<script>
import VLazyImage from "v-lazy-image";
export default {
  components: {VLazyImage},
  props: ["title", "imgURL", "desc", "width", "height", "isTransparent", "link"],
  data() {
    return {
      isOverlayVisible: false,
      showMore: [],
    };
  },
  computed: {
    style() {
      return `width: ${this.width}; height: ${this.height}`;
    },
  },
  methods: {
    showOverlay() {
      this.isOverlayVisible = true;
    },
    hideOverlay() {
      this.isOverlayVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.fix-height {
  height: 100% !important;
}
.card-component {
  border-radius: 12px;
  margin-right: 60px;
  margin: 12px 18px;
  position: relative;
  overflow: hidden;
  transition: 0.7s;
  &.transparent {
    transition: 0.7s;
    .card-component-image {
      background-color: rgba(255, 255, 255, 0.1);
      backdrop-filter: blur(60px);
    }
    .card-component-image-text {
      color: #ffffff;
    }
    .card-component-overlay {
      background: #5e809b;
      // backdrop-filter: blur(60px);
      transition: 0.7s;
    }
    .card-component-overlay-title {
      color: #ffffff;
      border-bottom: none;
    }
    .card-component-overlay-title-info {
      color: rgba(255, 255, 255, 0.54);
    }
  }

  &:last-child {
    margin-right: 0px;
  }

  &-image {
    display: block;
    position: relative;
    background-color: #ffffff;
    backdrop-filter: blur(60px);
    border-radius: 12px;
    padding: 12px 9px;
    // transition: 0.3s;
    
    height: 100%;
    width: 100%;

    &-img {
      height: calc(100% - 58px);
      width: 100%;
      border-radius: 12px;
      object-fit: cover;
    }
    &-img1 {
      height: 100%;
      width: 100%;
      border-radius: 12px;
      object-fit: cover;
    }

    &-text {
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
      text-align: center;
      color: #000000;
      padding: 12px 0px;
    }
  }
  &:hover { 
    .card-component-overlay {
      transition: ease-in 0.7s;
      transform: translateY(0);
      // transition-delay: 250ms;
      
      // transition-property: margin-right;
      opacity: 1;
      // transition: 0.4s;
    }
  }

  &-overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    border-radius: 12px;
    padding: 12px 23px 23px;
    display: flex;
    flex-direction: column;
    background: #ffffff;
    transform: translateY(100%);
    opacity: 0;
    transition: transform 0.6s, opacity 0.6s;
    transition: 0.7s;
    &-title {
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
      text-align: center;
      color: #000000;
      padding: 12px 0px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      border-width: 90%;

      &-info {
        font-weight: 500;
        font-size: 16px;
        text-align: center;
        line-height: 28px;
        letter-spacing: 0.02em;
        color: rgba(0, 0, 0, 0.54);
        &-link {
          color: #000000;
          font-weight: 700;
        }
      }
    }
  }
}
@media (min-width: 990px) {
  .card-component {
    &-overlay {
      &-title {
        &-info {
          display: -webkit-box;
          -webkit-line-clamp: 5; // количество строк
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
      }
    }
    &-image {
      &-text {
        height: 60px;
        display: flex;
        justify-content: center;
        flex-direction: column;
      }
    }
  }
}
@media (max-width: 990px) {
  .showmore {
    height: auto !important;
  }
  .card-component {
    margin: 0;
    width: 100% !important;
    height: 283px !important;

    &-overlay {
      z-index: 111;
      padding: 8px;
      &-title {
        &-info {
          font-size: 14px;
          line-height: 17px;
          letter-spacing: 0;
          height: 102px;
          overflow: hidden;
        }
      }
    }
  }
}
.slide-up-enter-active {
  // transition: transform 1s ease-in-out , opacity 2s ease-in-out;
  animation: ease-in .7s;
}

.slide-up-leave-active {
  // transition: transform 2s ease-in-out, opacity 2s ease-in-out;
  animation: ease-in .7s reverse;
}

.slide-up-enter {
  animation: ease-in .7s;

}

.slide-up-leave-to {
  animation: ease-in .7s reverse;
}
</style>
