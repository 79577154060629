<template>
  <div class="platformInfo">
    <HeaderMain />
    <div class="platform" v-if="USEFUL_PAGE">
      <div class="container">
        <div class="platform__main" v-if="USEFUL_PAGE?.usefulPlatform">
          <h3 class="platform__main-title r16-600">{{ this.$t("plat") }}</h3>
          <h1 class="platform__main-title2 b36-700" v-html="USEFUL_PAGE.usefulPlatform.title"></h1>
          <div class="platform__main-info">
            <p class="platform__main-info-in g18-400" v-html="USEFUL_PAGE.usefulPlatform.description"></p>
          </div>

          <button class="platform__main-btn d-flex-center">
            <img
              class="platform__main-btn-img"
              src="@/assets/images/platform/see-video.svg"
              alt=""
            />
            <p class="w24-700">{{ this.$t("watch") }}</p>
          </button>
        </div>

        <div class="platform__content d-flex">
          <div class="platform__content__left">
            <div class="platform__content__left-wrapper">
              <h2 class="platform__content__left-title hu18-400" style="margin-bottom: 16px;">{{ this.$t("main") }}</h2>

              <router-link to="/useful">
                <button
                  class="platform__content__left-item d-flex-center active"
                >
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.5 11H11.71L11.43 10.73C12.4439 9.55402 13.0011 8.0527 13 6.5C13 5.21442 12.6188 3.95772 11.9046 2.8888C11.1903 1.81988 10.1752 0.986756 8.98744 0.494786C7.79973 0.00281635 6.49279 -0.125905 5.23192 0.124899C3.97104 0.375703 2.81285 0.994767 1.90381 1.90381C0.994767 2.81285 0.375703 3.97104 0.124899 5.23192C-0.125905 6.49279 0.00281635 7.79973 0.494786 8.98744C0.986756 10.1752 1.81988 11.1903 2.8888 11.9046C3.95772 12.6188 5.21442 13 6.5 13C8.11 13 9.59 12.41 10.73 11.43L11 11.71V12.5L16 17.49L17.49 16L12.5 11ZM6.5 11C4.01 11 2 8.99 2 6.5C2 4.01 4.01 2 6.5 2C8.99 2 11 4.01 11 6.5C11 8.99 8.99 11 6.5 11Z"
                      fill="red"
                    />
                  </svg>

                  <p class="hu18-400">{{ this.$t("searchProgram") }}</p>
                </button>
              </router-link>
              <router-link to="/registration">
              <button class="platform__content__left-item d-flex-center">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6 22C5.45 22 4.979 21.804 4.587 21.412C4.195 21.02 3.99934 20.5493 4 20V10C4 9.45 4.196 8.979 4.588 8.587C4.98 8.195 5.45067 7.99933 6 8H7V6C7 4.61667 7.48767 3.43733 8.463 2.462C9.43834 1.48667 10.6173 0.999334 12 1C13.3833 1 14.5627 1.48767 15.538 2.463C16.5133 3.43833 17.0007 4.61733 17 6V8H18C18.55 8 19.021 8.196 19.413 8.588C19.805 8.98 20.0007 9.45067 20 10V20C20 20.55 19.804 21.021 19.412 21.413C19.02 21.805 18.5493 22.0007 18 22H6ZM12 17C12.55 17 13.021 16.804 13.413 16.412C13.805 16.02 14.0007 15.5493 14 15C14 14.45 13.804 13.979 13.412 13.587C13.02 13.195 12.5493 12.9993 12 13C11.45 13 10.979 13.196 10.587 13.588C10.195 13.98 9.99934 14.4507 10 15C10 15.55 10.196 16.021 10.588 16.413C10.98 16.805 11.4507 17.0007 12 17ZM9 8H15V6C15 5.16667 14.7083 4.45833 14.125 3.875C13.5417 3.29167 12.8333 3 12 3C11.1667 3 10.4583 3.29167 9.875 3.875C9.29167 4.45833 9 5.16667 9 6V8Z"
                    fill="black"
                    fill-opacity="0.54"
                  />
                </svg>

                <p class="hu18-400">{{ this.$t("fav") }}</p>
              </button>
            </router-link>
            <router-link to="/registration">
              <button class="platform__content__left-item d-flex-center">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6 22C5.45 22 4.979 21.804 4.587 21.412C4.195 21.02 3.99934 20.5493 4 20V10C4 9.45 4.196 8.979 4.588 8.587C4.98 8.195 5.45067 7.99933 6 8H7V6C7 4.61667 7.48767 3.43733 8.463 2.462C9.43834 1.48667 10.6173 0.999334 12 1C13.3833 1 14.5627 1.48767 15.538 2.463C16.5133 3.43833 17.0007 4.61733 17 6V8H18C18.55 8 19.021 8.196 19.413 8.588C19.805 8.98 20.0007 9.45067 20 10V20C20 20.55 19.804 21.021 19.412 21.413C19.02 21.805 18.5493 22.0007 18 22H6ZM12 17C12.55 17 13.021 16.804 13.413 16.412C13.805 16.02 14.0007 15.5493 14 15C14 14.45 13.804 13.979 13.412 13.587C13.02 13.195 12.5493 12.9993 12 13C11.45 13 10.979 13.196 10.587 13.588C10.195 13.98 9.99934 14.4507 10 15C10 15.55 10.196 16.021 10.588 16.413C10.98 16.805 11.4507 17.0007 12 17ZM9 8H15V6C15 5.16667 14.7083 4.45833 14.125 3.875C13.5417 3.29167 12.8333 3 12 3C11.1667 3 10.4583 3.29167 9.875 3.875C9.29167 4.45833 9 5.16667 9 6V8Z"
                    fill="black"
                    fill-opacity="0.54"
                  />
                </svg>

                <p class="hu18-400">{{ this.$t("acc") }}</p>
              </button>
            </router-link>
            <router-link to="/registration">
              <button class="platform__content__left-item d-flex-center">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6 22C5.45 22 4.979 21.804 4.587 21.412C4.195 21.02 3.99934 20.5493 4 20V10C4 9.45 4.196 8.979 4.588 8.587C4.98 8.195 5.45067 7.99933 6 8H7V6C7 4.61667 7.48767 3.43733 8.463 2.462C9.43834 1.48667 10.6173 0.999334 12 1C13.3833 1 14.5627 1.48767 15.538 2.463C16.5133 3.43833 17.0007 4.61733 17 6V8H18C18.55 8 19.021 8.196 19.413 8.588C19.805 8.98 20.0007 9.45067 20 10V20C20 20.55 19.804 21.021 19.412 21.413C19.02 21.805 18.5493 22.0007 18 22H6ZM12 17C12.55 17 13.021 16.804 13.413 16.412C13.805 16.02 14.0007 15.5493 14 15C14 14.45 13.804 13.979 13.412 13.587C13.02 13.195 12.5493 12.9993 12 13C11.45 13 10.979 13.196 10.587 13.588C10.195 13.98 9.99934 14.4507 10 15C10 15.55 10.196 16.021 10.588 16.413C10.98 16.805 11.4507 17.0007 12 17ZM9 8H15V6C15 5.16667 14.7083 4.45833 14.125 3.875C13.5417 3.29167 12.8333 3 12 3C11.1667 3 10.4583 3.29167 9.875 3.875C9.29167 4.45833 9 5.16667 9 6V8Z"
                    fill="black"
                    fill-opacity="0.54"
                  />
                </svg>

                <p class="hu18-400">{{ this.$t("ourProd") }}</p>
              </button>
            </router-link>
            <router-link to="/registration">
              <button class="platform__content__left-item d-flex-center">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6 22C5.45 22 4.979 21.804 4.587 21.412C4.195 21.02 3.99934 20.5493 4 20V10C4 9.45 4.196 8.979 4.588 8.587C4.98 8.195 5.45067 7.99933 6 8H7V6C7 4.61667 7.48767 3.43733 8.463 2.462C9.43834 1.48667 10.6173 0.999334 12 1C13.3833 1 14.5627 1.48767 15.538 2.463C16.5133 3.43833 17.0007 4.61733 17 6V8H18C18.55 8 19.021 8.196 19.413 8.588C19.805 8.98 20.0007 9.45067 20 10V20C20 20.55 19.804 21.021 19.412 21.413C19.02 21.805 18.5493 22.0007 18 22H6ZM12 17C12.55 17 13.021 16.804 13.413 16.412C13.805 16.02 14.0007 15.5493 14 15C14 14.45 13.804 13.979 13.412 13.587C13.02 13.195 12.5493 12.9993 12 13C11.45 13 10.979 13.196 10.587 13.588C10.195 13.98 9.99934 14.4507 10 15C10 15.55 10.196 16.021 10.588 16.413C10.98 16.805 11.4507 17.0007 12 17ZM9 8H15V6C15 5.16667 14.7083 4.45833 14.125 3.875C13.5417 3.29167 12.8333 3 12 3C11.1667 3 10.4583 3.29167 9.875 3.875C9.29167 4.45833 9 5.16667 9 6V8Z"
                    fill="black"
                    fill-opacity="0.54"
                  />
                </svg>

                <p class="hu18-400">{{ this.$t("ourServ") }}</p>
              </button>
            </router-link>
              <button
                class="platform__content__left-item d-flex-center"
                @click="scrollTo('useful')"
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9 15H11V9H9V15ZM10 7C10.2833 7 10.521 6.904 10.713 6.712C10.905 6.52 11.0007 6.28267 11 6C11 5.71667 10.904 5.479 10.712 5.287C10.52 5.095 10.2827 4.99933 10 5C9.71667 5 9.479 5.096 9.287 5.288C9.095 5.48 8.99933 5.71733 9 6C9 6.28333 9.096 6.521 9.288 6.713C9.48 6.905 9.71733 7.00067 10 7ZM10 20C8.61667 20 7.31667 19.7373 6.1 19.212C4.88333 18.6867 3.825 17.9743 2.925 17.075C2.025 16.175 1.31267 15.1167 0.788 13.9C0.263333 12.6833 0.000666667 11.3833 0 10C0 8.61667 0.262667 7.31667 0.788 6.1C1.31333 4.88333 2.02567 3.825 2.925 2.925C3.825 2.025 4.88333 1.31267 6.1 0.788C7.31667 0.263333 8.61667 0.000666667 10 0C11.3833 0 12.6833 0.262667 13.9 0.788C15.1167 1.31333 16.175 2.02567 17.075 2.925C17.975 3.825 18.6877 4.88333 19.213 6.1C19.7383 7.31667 20.0007 8.61667 20 10C20 11.3833 19.7373 12.6833 19.212 13.9C18.6867 15.1167 17.9743 16.175 17.075 17.075C16.175 17.975 15.1167 18.6877 13.9 19.213C12.6833 19.7383 11.3833 20.0007 10 20ZM10 18C12.2333 18 14.125 17.225 15.675 15.675C17.225 14.125 18 12.2333 18 10C18 7.76667 17.225 5.875 15.675 4.325C14.125 2.775 12.2333 2 10 2C7.76667 2 5.875 2.775 4.325 4.325C2.775 5.875 2 7.76667 2 10C2 12.2333 2.775 14.125 4.325 15.675C5.875 17.225 7.76667 18 10 18Z"
                    fill="black"
                    fill-opacity="0.54"
                  />
                </svg>

                <p class="hu18-400">{{ this.$t("use") }}</p>
              </button>
            </div>
            <div class="platform__content__left-line">
              <span></span>
            </div>
            <div class="platform__content__left__news">
              <h2 class="platform__content__left__news-title hu18-400">
                {{ this.$t("news") }}
              </h2>

              <div class="platform__content__left__news-item">
                <img
                  class="platform__content__left__news-item-img"
                  src="@/assets/images/platform/platform-news1.png"
                  alt=""
                />
                <p class="hu18-400">{{ this.$t("add120") }}</p>
              </div>

              <div class="platform__content__left__news-item">
                <img
                  class="platform__content__left__news-item-img"
                  src="@/assets/images/platform/platform-news2.png"
                  alt=""
                />
                <p class="hu18-400">{{ this.$t("add5") }}</p>
              </div>

              <div class="platform__content__left__news-item">
                <img
                  class="platform__content__left__news-item-img"
                  src="@/assets/images/platform/platform-news3.png"
                  alt=""
                />
                <p class="hu18-400">{{ this.$t("addServ") }}</p>
              </div>
            </div>
          </div>

          <div class="platform__content__right" v-if="USEFUL_PAGE.demoPlatform">
            <div class="d-flex-wrap-center title">
              <router-link
                to="/useful"
                class="platform__content__right-title r20-600"
              >
                <svg
                  width="12"
                  height="20"
                  viewBox="0 0 12 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  class="platform__content__right-title-img"
                >
                  <path
                    d="M9.1258 19.0999L0.700796 10.6999C0.600796 10.5999 0.529797 10.4916 0.487797 10.3749C0.445797 10.2582 0.42513 10.1332 0.425797 9.9999C0.425797 9.86657 0.446463 9.74157 0.487797 9.6249C0.52913 9.50824 0.60013 9.3999 0.700796 9.2999L9.1258 0.874902C9.35913 0.641569 9.6508 0.524902 10.0008 0.524902C10.3508 0.524902 10.6508 0.649902 10.9008 0.899902C11.1508 1.1499 11.2758 1.44157 11.2758 1.7749C11.2758 2.10824 11.1508 2.3999 10.9008 2.6499L3.5508 9.9999L10.9008 17.3499C11.1341 17.5832 11.2508 17.8709 11.2508 18.2129C11.2508 18.5549 11.1258 18.8506 10.8758 19.0999C10.6258 19.3499 10.3341 19.4749 10.0008 19.4749C9.66746 19.4749 9.3758 19.3499 9.1258 19.0999Z"
                    fill="#FD2D2A"
                  />
                </svg>

                <span>{{ this.$t("searchProgram") }}&nbsp;/</span>
              </router-link>
              <h2 class="platform__content__right-title2 b20-700">
                &nbsp;{{ USEFUL_PAGE.demoPlatform.programName }}
                <span class="platform__content__right-title2-span h20-400"
                  >{{ USEFUL_PAGE.demoPlatform.programCode }}</span
                >
              </h2>
              <a
                class="platform__content__right-title-linkProgram"
                href="https://tpp.mit.edu/academics/application-process/"
              >
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.16602 13.1665H9.83268V8.1665H8.16602V13.1665ZM8.99935 6.49984C9.23546 6.49984 9.43352 6.41984 9.59352 6.25984C9.75352 6.09984 9.83324 5.90206 9.83268 5.6665C9.83268 5.43039 9.75268 5.23234 9.59268 5.07234C9.43268 4.91234 9.2349 4.83261 8.99935 4.83317C8.76324 4.83317 8.56518 4.91317 8.40518 5.07317C8.24518 5.23317 8.16546 5.43095 8.16602 5.6665C8.16602 5.90262 8.24602 6.10067 8.40602 6.26067C8.56602 6.42067 8.76379 6.50039 8.99935 6.49984ZM8.99935 17.3332C7.84657 17.3332 6.76324 17.1143 5.74935 16.6765C4.73546 16.2387 3.85352 15.6451 3.10352 14.8957C2.35352 14.1457 1.7599 13.2637 1.32268 12.2498C0.88546 11.2359 0.666571 10.1526 0.666016 8.99984C0.666016 7.84706 0.884905 6.76373 1.32268 5.74984C1.76046 4.73595 2.35407 3.854 3.10352 3.104C3.85352 2.354 4.73546 1.76039 5.74935 1.32317C6.76324 0.885948 7.84657 0.667059 8.99935 0.666504C10.1521 0.666504 11.2355 0.885393 12.2493 1.32317C13.2632 1.76095 14.1452 2.35456 14.8952 3.104C15.6452 3.854 16.2391 4.73595 16.6768 5.74984C17.1146 6.76373 17.3332 7.84706 17.3327 8.99984C17.3327 10.1526 17.1138 11.2359 16.676 12.2498C16.2382 13.2637 15.6446 14.1457 14.8952 14.8957C14.1452 15.6457 13.2632 16.2396 12.2493 16.6773C11.2355 17.1151 10.1521 17.3337 8.99935 17.3332ZM8.99935 15.6665C10.8605 15.6665 12.4368 15.0207 13.7285 13.729C15.0202 12.4373 15.666 10.8609 15.666 8.99984C15.666 7.13873 15.0202 5.56234 13.7285 4.27067C12.4368 2.979 10.8605 2.33317 8.99935 2.33317C7.13824 2.33317 5.56185 2.979 4.27018 4.27067C2.97852 5.56234 2.33268 7.13873 2.33268 8.99984C2.33268 10.8609 2.97852 12.4373 4.27018 13.729C5.56185 15.0207 7.13824 15.6665 8.99935 15.6665Z"
                    fill="white"
                  />
                </svg>
                <span style="margin-left: 6px;">{{ this.$t("linkPr") }}</span>
              </a>
            </div>
            <div class="platform__content__right-line">
              <span></span>
            </div>

            <div class="platform__content__right-info d-flex">
              <div class="platform__content__right-info-img">
                <img :src="USEFUL_PAGE.demoPlatform.image" alt="" />
                <div class="platform__content__right-info-btm d-flex">
                  <a
                    class="platform__content__right-info-btm-l d-flex-center"
                    href="https://youtu.be/KIop1hxFliQ"
                  >
                    <svg
                      width="10"
                      height="12"
                      viewBox="0 0 10 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1.7319 11.5229C1.42635 11.7215 1.11712 11.7331 0.804234 11.5577C0.491345 11.3823 0.334595 11.111 0.333984 10.7437V1.25619C0.333984 0.889523 0.490734 0.61819 0.804234 0.44219C1.11773 0.26619 1.42696 0.277801 1.7319 0.477023L9.20273 5.22077C9.47773 5.40411 9.61523 5.66383 9.61523 5.99994C9.61523 6.33605 9.47773 6.59577 9.20273 6.77911L1.7319 11.5229Z"
                        fill="black"
                      />
                    </svg>
                    <p class="b18-400">{{ this.$t("videoTour") }}</p>
                  </a>
                  <a
                    class="platform__content__right-info-btm-r d-flex-center"
                    href="https://youtu.be/KIop1hxFliQ"
                  >
                    <svg
                      width="20"
                      height="18"
                      viewBox="0 0 20 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M19.1673 7.25811C19.259 6.79978 18.8923 6.24978 18.434 6.24978L13.209 5.51645L10.8257 0.749781C10.734 0.566447 10.6423 0.474781 10.459 0.383114C10.0007 0.108114 9.45065 0.291447 9.17565 0.749781L6.88398 5.51645L1.65898 6.24978C1.38398 6.24978 1.20065 6.34145 1.10898 6.52478C0.742318 6.89145 0.742318 7.44145 1.10898 7.80811L4.86732 11.4748L3.95065 16.6998C3.95065 16.8831 3.95065 17.0664 4.04232 17.2498C4.31732 17.7081 4.86732 17.8914 5.32565 17.6164L10.0007 15.1414L14.6757 17.6164C14.7673 17.7081 14.9507 17.7081 15.134 17.7081H15.3173C15.7756 17.6164 16.1423 17.1581 16.0507 16.6081L15.134 11.3831L18.8923 7.71645C19.0757 7.62478 19.1673 7.44145 19.1673 7.25811Z"
                        fill="#FFB100"
                      />
                    </svg>

                    <p class="y18-400">{{ this.$t("addFavor") }}</p>
                  </a>
                </div>
              </div>

              <div class="platform__content__right-info-detail">
                <h1 class="platform__content__right-info-detail-head b24-700">
                  {{ USEFUL_PAGE.demoPlatform.programName }} <span class="h20-400">{{ USEFUL_PAGE.demoPlatform.programCode }}</span>
                </h1>
                <div
                  class="platform__content__right-info-detail-in d-flex-center"
                >
                  <p class="h16-400">
                    {{ this.$t("longEd") }}: <b class="b16-700">{{ USEFUL_PAGE.demoPlatform.duration }}</b>
                  </p>
                </div>

                <div
                  class="platform__content__right-info-detail-in d-flex-center"
                >
                  <p class="h16-400">
                    {{ this.$t("country") }}:
                    <b class="b16-700">{{ USEFUL_PAGE.demoPlatform.city }} - {{ USEFUL_PAGE.demoPlatform.country }}</b>
                  </p>
                </div>

                <div
                  class="platform__content__right-info-detail-in d-flex-center"
                >
                  <p class="h16-400">
                    GPA: <b class="b16-700">{{ USEFUL_PAGE.demoPlatform.gpa }}</b>
                  </p>
                </div>

                <div
                  class="platform__content__right-info-detail-in d-flex-center"
                >
                  <p class="h16-400">
                    {{ this.$t("langLevel") }}:
                    <b class="b16-700">{{ USEFUL_PAGE.demoPlatform.language }}</b>
                  </p>
                </div>

                <div
                  class="platform__content__right-info-detail-in d-flex-center"
                >
                  <p class="h16-400">
                    GRE/GMAT: <b class="b16-700">{{ USEFUL_PAGE.demoPlatform.gre }}</b>
                  </p>
                </div>

                <div
                  class="platform__content__right-info-detail-in d-flex-center"
                >
                  <p class="h16-400">
                    {{ this.$t("fallStart") }}:
                    <b class="b16-700">С {{ format_date(USEFUL_PAGE.demoPlatform.autumn_admission_start_date) }} по {{ format_date(USEFUL_PAGE.demoPlatform.autumn_admission_end_date) }}</b>
                  </p>
                </div>

                <div
                  class="platform__content__right-info-detail-in d-flex-center"
                >
                  <p class="h16-400">
                    {{ this.$t("fallEnd") }}:
                    <b v-if="USEFUL_PAGE.demoPlatform.autumn_program_start_date !== null" class="b16-700">{{ format_date(USEFUL_PAGE.demoPlatform.autumn_program_start_date) }}</b>
                    <b v-else class="b16-700">-</b>
                  </p>
                </div>

                <div
                  class="platform__content__right-info-detail-in d-flex-center"
                >
                  <p class="h16-400" v-if="USEFUL_PAGE.demoPlatform.winter_admission_start_date !== null">
                    {{ this.$t("winterStart") }}: <b class="b16-700">С {{ format_date(USEFUL_PAGE.demoPlatform.winter_admission_start_date) }} по {{ format_date(USEFUL_PAGE.demoPlatform.winter_admission_end_date) }}</b>
                  </p>
                  <p class="h16-400" v-else>
                    {{ this.$t("winterStart") }}: <b class="b16-700">-</b>
                  </p>
                </div>

                <div
                  class="platform__content__right-info-detail-in d-flex-center"
                >
                  <p class="h16-400" v-if="USEFUL_PAGE.demoPlatform.winter_program_start_date !== null">
                    {{ this.$t("winterEnd") }}: <b class="b16-700">{{ format_date(USEFUL_PAGE.demoPlatform.winter_program_start_date) }}</b>
                  </p>
                  <p class="h16-400" v-else>
                    {{ this.$t("winterEnd") }}: <b class="b16-700">-</b>
                  </p>
                </div>

                <div
                  class="platform__content__right-info-detail-in d-flex-center"
                >
                  <p class="h16-400">
                    {{ this.$t("priceEd") }}: <b class="b16-700">{{ USEFUL_PAGE.demoPlatform.price }}</b>
                  </p>
                </div>
              </div>
            </div>

            <div class="platform__content__right-tabs">
              <div class="program-btm">
                <span class="SelectMobile-mobile d_none">
                  <select
                    class="SelectMobile"
                    v-model="selectedNewsTab"
                    @setTab="selectNewsTab"
                  >
                    <option
                      v-for="(item, index) in tabItems"
                      :key="index"
                      :value="item"
                    >
                      {{ item }}
                    </option>
                  </select>
                </span>
                <div class="program-btm__top m_none">
                    <platform-tab
                        :tabItems="tabItems"
                        @setTab="selectNewsTab"
                        :selected="selectedNewsTab"
                        class="program-btm__tab"
                    >
                    </platform-tab>
                    <hr>
                </div>
                <tab-block
                    :isSelected="selectedNewsTab === this.$t('list')"
                >
                    <p class="px-24 fw700 black">{{ this.$t('list') }}</p>
                    <p class="px-18 gray" style="margin: 28px 0;" v-html="USEFUL_PAGE.demoPlatform.details"></p>
                    <!-- <p class="fw700 px-18" style="margin-bottom: 20px;">Письма/ Список документов</p>

                    <ul class="list gray">
                        <li>Résumé/CV</li>
                        <li>Cover Letter (500 words or less)</li>
                        <li>Three Letters of Recommendation</li>
                        <li>Statement of Objectives (500 to 750 words)</li>
                    </ul>
                    <p class="px-18 fw700">Опыт работы</p>
                    <p class="px-18 gray" style="margin: 20px 0;">Не требуется, но желательно для повышения шансов</p>
                    <p class="px-18 fw700">{{ this.$t("other") }}</p>
                    <p class="px-18 gray" style="margin: 20px 0;">По дополнительным вопросам поступления Elena Byrne (ebyrne@mit.edu)</p> -->
                </tab-block>
                <tab-block :isSelected="selectedNewsTab === this.$t('letter')">
                    <p class="px-24 fw700" style="margin-bottom: 28px;">{{ this.$t('letter') }}</p>
                    <p class="px-18 gray" v-html="USEFUL_PAGE.demoPlatform.letter"></p>
                </tab-block>
                <tab-block :isSelected="selectedNewsTab === this.$t('exp')">
                    <p class="px-24 fw700" style="margin-bottom: 28px;">{{ this.$t('exp') }}</p>
                    <p class="px-18 gray" v-html="USEFUL_PAGE.demoPlatform.workExperience"></p>
                </tab-block>
                <tab-block :isSelected="selectedNewsTab === this.$t('other')">
                    <p class="px-24 fw700" style="margin-bottom: 28px;">{{ this.$t("other") }}</p>
                    <p class="px-18 gray" v-html="USEFUL_PAGE.demoPlatform.other"></p>
                </tab-block>
            </div>
            </div>
            <div class="platform__content__right-items">
              <div class="platform__content__right-item locked">
                <img src="@/assets/images/platform/MIT.png" alt="" />
                <h4 class="platform__content__right-item-address h16-400">
                  {{ this.$t("accessClose") }}
                </h4>
                <div class="platform__content__right-item-info">
                  <p
                    class="platform__content__right-item-info-category b14-600"
                  >
                    Technology and Policy
                  </p>
                  <p
                    class="platform__content__right-item-info-university h14-400"
                  >
                    Требуется подписка
                  </p>
                </div>
              </div>

              <div class="platform__content__right-item locked">
                <img src="@/assets/images/platform/MIT.png" alt="" />
                <h4 class="platform__content__right-item-address h16-400">
                  {{ this.$t("accessClose") }}
                </h4>
                <div class="platform__content__right-item-info">
                  <p
                    class="platform__content__right-item-info-category b14-600"
                  >
                    Technology and Policy
                  </p>
                  <p
                    class="platform__content__right-item-info-university h14-400"
                  >
                    Требуется подписка
                  </p>
                </div>
              </div>

              <div class="platform__content__right-item locked">
                <img src="@/assets/images/platform/MIT.png" alt="" />
                <h4 class="platform__content__right-item-address h16-400">
                  {{ this.$t("accessClose") }}
                </h4>
                <div class="platform__content__right-item-info">
                  <p
                    class="platform__content__right-item-info-category b14-600"
                  >
                    Technology and Policy
                  </p>
                  <p
                    class="platform__content__right-item-info-university h14-400"
                  >
                    Требуется подписка
                  </p>
                </div>
              </div>

              <div class="platform__content__right-item locked">
                <img src="@/assets/images/platform/MIT.png" alt="" />
                <h4 class="platform__content__right-item-address h16-400">
                  {{ this.$t("accessClose") }}
                </h4>
                <div class="platform__content__right-item-info">
                  <p
                    class="platform__content__right-item-info-category b14-600"
                  >
                    Technology and Policy
                  </p>
                  <p
                    class="platform__content__right-item-info-university h14-400"
                  >
                    Требуется подписка
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

          <button class="platform__bottom-btn">
            <router-link class="platform__bottom-link" to="/price"><p class="w24-700">{{ this.$t("buy") }}</p></router-link>
          </button>
      </div>
    </div>
    <usefulPodcast />
    <CountactUs />
    <FooterMain />
  </div>
</template>
<script>
import HeaderMain from "./layouts/HeaderMain.vue";
import FooterMain from "./layouts/FooterMain.vue";
import usefulPodcast from "@/components/usefulPodcast.vue";
import CountactUs from "@/components/CountactUs.vue";
import platformTab from '@/components/tab/platformTab.vue';
import tabBlock from "@/components/tab/tabBlock.vue";
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
export default {
  components: {
    HeaderMain,
    usefulPodcast,
    CountactUs,
    FooterMain,
    platformTab, 
    tabBlock
  },
  data() {
    return {
      selectedNewsTab: this.$t('list'),
      tabItems: [this.$t('list'), this.$t('letter'), this.$t('exp'), this.$t('other')],
    }
  },
  created() {
    this.GET_USEFUL_PAGE();
  },
  methods: {
    ...mapActions(["GET_USEFUL_PAGE"]),
    scrollTo(refName) {
      var element = this.$refs[refName];
      window.scrollTo({
        top: 1800,
        behavior: "smooth",
      });
    },
    selectNewsTab(tab) {
      this.selectedNewsTab = tab;
    },
    format_date(value) {
      moment.locale('ru');
      return moment(String(value)).format('LL');
    },
  },
  computed: {
    ...mapGetters(["USEFUL_PAGE"]),
  },
};
</script>
<style lang="scss" scoped>
.platformInfo {
  background: #f5f7fb;
}
.platform {
  padding-top: 190px;
  background: #f5f7fb;

  &__main {
    text-align: center;
    &-title2 {
      width: 60%;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 18px;
    }
    &-info {
      margin-left: auto;
      margin-right: auto;
      width: 100%; //75
    }
    &-btn {
      // width: 360px;
      display: flex;
      justify-content: center;
      text-align: center;
      margin-left: auto;
      margin-right: auto;
      margin: 48px auto 80px;
      padding: 16px;
      background: #fd2d2a;
      border-radius: 8px;
      border: none;
      &-img {
        width: 29px;
        height: 29px;
        margin-right: 10px;
      }
    }
  }

  &__content {
    &__left {
      flex-basis: 352px;
      &-title {
        margin-bottom: 16px;
      }
      &-line {
        border-top: 1px solid rgba(0, 0, 0, 0.1);
        width: 100%;
        margin-top: 16px;
      }
      &-item {
        // box-sizing: border-box;
        background: #ffffff;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        padding: 8px 12px;
        width: 100%;
        margin-bottom: 8px;
        & p {
          margin-left: 15px;
        }
        &.active,
        &:hover {
          background: #fd2d2a;
          & p {
            color: white;
          }
          & path {
            fill: white;
            fill-opacity: 1;
          }
        }
      }
      &__news {
        margin-top: 16px;

        &-item {
          background: #ffffff;
          border: 1px solid rgba(0, 0, 0, 0.1);
          border-radius: 8px;
          width: 100%;
          margin-top: 8px;
          padding: 8px;
          &-img {
            width: 100%;
            height: 137px;
            border: 1px solid rgba(0, 0, 0, 0.1);
            border-radius: 8px;
          }
          & p {
            text-align: center;
            width: 75%;
            margin-left: auto;
            margin-right: auto;
            margin-top: 8px;
          }
        }
      }
    }

    &__right {
      // flex: 1 0 auto;
      margin-left: 36px;
      background: #ffffff;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 24px;
      padding: 24px 24px 28px 32px;
      &-line {
        border: 1px solid rgba(0, 0, 0, 0.1);
        margin: 24px 0px 28px;
      }
      &-title {
        display: inline-flex;
        align-items: center;
        &-img {
          margin-right: 14px;
        }
        &-linkProgram {
          padding: 6px;
          background-color: #11415d;
          color: white;
          font-size: 16px;
          font-weight: 600;
          margin-left: 24px;
          border-radius: 8px;
          gap: 10px;
          border: none;
          // display: inline-flex;
          // align-items: center;
        }
      }
      &-title2 {
        margin-bottom: 0px;
        display: inline;
        &-span {
          display: none;
        }
      }
      &-info {
        margin-top: 28px;
        &-img {
          position: relative;
          margin-right: 28px;
          width: 640px;
          height: 408px;
          & img {
            width: 100%;
            border: 1px solid rgba(0, 0, 0, 0.1);
            border-radius: 18px;
            object-fit: cover;
          }
        }
        &-btm {
          display: flex;
          justify-content: space-between;
          position: absolute;
          bottom: 13px;
          left: 13px;
          background: #ffffff;
          border-radius: 8px 8px 14px 14px;
          // margin: 13px;
          width: calc(100% - 26px);
          & p {
            margin-left: 13px;
          }

          &-l {
            position: relative;
            flex-grow: 1;
            height: 100%;
            padding: 10px;
            display: flex;
            justify-content: center;
            &::after {
              content: "";
              position: absolute;
              width: 1px;
              height: calc(100% * 2 / 3);
              right: 0;
              top: 50%;
              transform: translateY(-50%);
              background-color: rgba(0, 0, 0, 0.1);
            }
          }
          &-r {
            display: flex;
            justify-content: center;
            height: 100%;
            flex-grow: 1;
            padding: 10px;
          }
        }
        &-detail {
          &-head {
            margin-bottom: 24px;
          }
          &-in {
            margin-bottom: 16px;
            column-gap: 5px;
          }
        }
      }
      &-listAll {
        background: #f5f7fb;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 12px;
        padding: 7px;
        width: fit-content;
        &-mobile {
          display: none;
        }
      }
      &-listBtm {
        background: #ffffff !important;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        // padding: 12px 14px;
        & h2 {
          padding: 12px 14px;
          margin-bottom: 0px;
          position: relative;
          &.active {
            background: #11415d;
            border-radius: 7px 0px 0px 7px;
            color: white;
            padding: 12px 14px;
          }
          &::after {
            content: "";
            position: absolute;
            width: 1px;
            height: 66%;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            background-color: rgba(0, 0, 0, 0.1);
          }
        }
        &-line {
          border: 1px solid rgba(0, 0, 0, 0.1);
          margin: 0px 32px 0px 18px;
        }
      }
      &-more {
        & h1 {
          margin: 28px 0px;
        }
      }
      &-items {
        display: none;
        &-linia {
          display: none;
        }
      }
    }
  }

  &__bottom {
    &-btn {
      // width: 360px;
      display: flex;
      justify-content: center;
      text-align: center;
      margin-left: auto;
      margin-right: auto;
      margin: 80px auto 0px;
      padding: 16px;
      background: #fd2d2a;
      border-radius: 8px;
      border: none;
      &-img {
        width: 29px;
        height: 29px;
        margin-right: 10px;
      }
    }
  }
}
@media screen and (max-width: 1640px) {
  .platform {
    &__content {
      &__left {
        flex-shrink: 0;
        flex-basis: 352px;
      }
      &__right {
        flex: 0 1 auto;
      }
    }
  }
  .platform__content__right-info-btm p {
    font-size: 15px;
  }
}

@media screen and (max-width: 1400px) {
  .platform {
    &__content {
      &__left {
        flex-shrink: 0;
        flex-basis: 352px;
      }
      &__right {
        flex: 0 1 auto;
        &-info {
          flex-direction: column;
          &-detail {
            &-head {
              margin-top: 24px;
            }
          }
          &-img {
            width: 100%;
          }
          &-btm {
            & p {
              font-size: 13px;
            }
          }
        }
      }
    }
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .platform {
    &__content {
      &__left {
        flex-basis: 352px;
      }
    }
  }
}
@media (min-width: 991px) {
  .platform {
    &__content {
      &__left {
        &-wrapper {
          width: 350px;
        }
      }
    }
  }
}
@media (max-width: 991px) {
  .platformInfo {
    background-color: #fff;
  }
  .platform {
    background-color: #fff;
    padding-top: 100px;
    &__main {
      display: none;
    }
    &__content {
      // display: inline-block !important;
      flex-direction: column-reverse;
      &__left {
        display: none;
        &-wrapper {
          display: none;
        }
        &-line {
          display: none;
        }
        &__news {
          display: none;
        }
      }
      &__right {
        margin-left: none;
        background: none;
        border-radius: 0px !important;
        border: none !important;
        padding: 0px;
        margin: 0px;
        .title {
          flex-wrap: nowrap !important;
        }
        &-line {
          display: none;
        }
        &-title {
          & span {
            display: none;
          }
          &-linkProgram {
            padding: 6px 10px;
            & svg {
              width: 20px;
              height: 20px;
            }
            & span {
              display: none;
            }
          }
        }
        &-title2 {
          &-span {
            display: block;
          }
        }
        &-listAll {
          display: none;
          &-mobile {
            margin-top: 10px;
            & {
              display: block;
              width: 100% !important;
              margin-left: 0;
              margin-right: 0;
              padding-bottom: 18px;

              &-items {
                width: 100% !important;
                font-style: normal;
                padding: 9px 14px;
                font-size: 16px;
                font-weight: 700;
                color: #7c7c7c;
                border-radius: 4px;
                border: 1px solid rgba(0, 0, 0, 0.1);
                background: #fff;
              }
            }
          }
        }
        &-listBtm {
          flex-direction: column;
        }
        &-info {
          flex-direction: column;
          align-items: center;
          &-btm {
            position: relative;
            flex-direction: column;
            bottom: -6px;
            background: none;
            margin-right: 0;
            left: 0;
            right: 0;
            width: 100%;
            margin-top: 20px;
            &-l {
              border-radius: 4px;
              border: 1px solid rgba(0, 0, 0, 0.1);
              margin-bottom: 10px;
              &::after {
                display: none;
              }
            }
            &-r {
              border-radius: 4px;
              border: 1px solid rgba(0, 0, 0, 0.1);
            }
          }
          &-img {
            width: 100%;
            height: 232px;
            margin-right: 0;
            padding: 8px;
            border-radius: 8px;
            border: 1px solid rgba(0, 0, 0, 0.10);
            img {
              border-radius: 8px;
            }
          }
          &-detail {
            border-top: 1px solid rgba(0, 0, 0, 0.1);
            padding-top: 18px;
            margin-top: 120px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            padding-bottom: 18px;
            &-in {
              & p {
                display: flex;
                justify-content: space-between;
                width: 100%;
                & b {
                  text-align: right;
                }
              }
            }

            &-head {
              display: none;
            }
          }
        }

        &-items {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          row-gap: 24px;
          column-gap: 18px;
          width: 100%;
          margin-top: 18px;
          &-linia {
            display: block;
            border: 1px solid rgba(0, 0, 0, 0.1);
            border-radius: 2px;
            margin: 18px 0px;
          }
        }

        &-item {
          border: 1px solid rgba(0, 0, 0, 0.1);
          border-radius: 8px;
          height: 248px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          position: relative;
          padding: 10px;
          box-sizing: border-box;
          overflow: hidden;

          &.locked {
            & img {
              filter: blur(6px);
            }

            &::after {
              content: "";
              width: 27%;
              position: absolute;
              left: 50%;
              transform: translate(-50%, -50%);
              background-image: url("@/assets/images/platform/locked.png");
              background-repeat: no-repeat;
              top: 28%;
              height: 60px;
              background-size: contain;
            }
          }
          & img {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            width: 90%;
            height: 60%;
            border-radius: 8px;
            border: 1px solid rgba(0, 0, 0, 0.1);
            margin: 8px;
          }
          &-address {
            display: none;
          }

          &-info {
            margin-top: 100%;
            padding: 0px 12px;
            background: none;
            border-radius: 8px;
            z-index: 1;
            position: relative;

            &-category {
              border-bottom: 1px solid rgba(0, 0, 0, 0.1);
              padding-bottom: 6px;
              margin-bottom: 13px;
              text-align: center;
            }
            &-university {
              font-size: 11px;
              text-align: center;
            }
          }
        }
      }
    }
    &__bottom {
      &-btn {
        display: none;
      }
    }
  }
  .program {
    &-btm {
      padding-top: 20px;
    }
  }
  .SelectMobile {
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.10);
    background: #FFF;
    padding: 9px 14px;
    width: 100%;
    color: #7C7C7C;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    margin-bottom: 18px;
  }
}
</style>
