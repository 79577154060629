<template>
  <div class="contactUs">
    <div class="container">
      <div class="contactUs__main d-flex-center">
        <div class="contactUs__main__wrapper">
          <h1 class="contactUs__main-title">
            {{ this.$t('haveQuest1') }}
            <h1 class="contactUs__main-title2">
              {{ this.$t('haveQuest2') }}
              <span style="color: #fd2d2a">MENTOR</span>ME
            </h1>
          </h1>
        </div>
        <!-- <input
          type="text"
          v-model="phone"
          placeholder="+7 (___) - "
          v-mask="'+7 (###) ### ## ##'"
          class="contactUs__main-inp w24-400"
        /> -->
        <a aria-label="Chat on WhatsApp" href="https://api.whatsapp.com/send/?phone=77055881633&text=Здравствуйте, пишу вам чтобы узнать больше о наставничестве MentorMe!&type=phone_number&app_absent=0" target="_blank">
          <button @click="callBack" class="contactUs__main-btn pulse">
            {{ $t("chat") }}
          </button>
        </a>
      </div>
    </div>
    <div class="line">
      <div class="red"></div>
      <div class="yellow"></div>
    </div>
  </div>
</template>
<script>
import { mask } from "vue-the-mask";
import axios from "axios";
import { createToaster } from "@meforma/vue-toaster";

const toaster = createToaster({ position: "top-right", duration: 2000});

export default {
  directives: { mask },
  data() {
    return {
      phone: null,
    };
  },
  methods: {
    callBack() {
      axios
        .post("/applications/create", {
          phone: this.phone,
        })
        .then((res) => {
          if (res.status === 201) {
            toaster.success("Успешно отправлено!");
            this.phone = "";
          }
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.pulse {
  animation: pulse-animation 2s infinite;
}
@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px rgba(250, 255, 252, 0.2);
  }
  100% {
    box-shadow: 0 0 0 20px rgba(250, 255, 252, 0);
  }
}
.contactUs {
  background-image: url(@/assets/images/mainPage/contactUs.png);
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  &__main {
    padding: 80px 0px;
    @media(max-width:$mobile){
      padding-top:10px;
    }
    &-title {
      font-weight: 700;
      font-size: 32px;
      letter-spacing: 0.02em;
      color: #ffe19c;
    }
    &-title2 {
      font-weight: 700;
      font-size: 32px;
      letter-spacing: 0.02em;
      color: #ffffff;
    }
    // &-inp {
    //   padding: 14px;
    //   //   width: 507px;
    //   //   height: 60px;
    //   background: rgba(255, 255, 255, 0.1);
    //   border: 1px solid rgba(255, 255, 255, 0.24);
    //   border-radius: 8px;
    //   margin-left: 80px;
    //   margin-right: 24px;
    //   width: 507px;
    //   &::placeholder {
    //     color: #ffffff;
    //   }
    // }
    &-btn {
      margin-top: 0px;
      font-weight: 700;
      font-size: 24px;
      line-height: 29px;
      text-align: center;
      color: #11415d;
      padding: 16px 47px;
      background-color: #ffffff;
      border-radius: 8px;
      border: none;
      margin-left: 80px;
    }
  }
}
@media (min-width: 1024px) and (max-width: 1440px) {
  .contactUs {
    &__main {
      &-title {
        font-size: 28px;
      }
      &-title2 {
        font-size: 25px;
      }
      // &-inp {
      //   margin-left: 20px;
      //   margin-right: 24px;
      //   width: 307px;
      // }
    }
  }
}

@media (max-width: 990px) {
  .line {
    background-color: #11415d;
    padding-bottom: 8px;
  }
  .red {
    background-color: #FD2D2A;
    height: 8px;
    width: 100%;
    margin-bottom: 8px;
  }
  .yellow {
    background-color: #FFE19C;
    height: 8px;
    width: 100%;
  }
  .contactUs {
    background-image: none;
    background-color: #11415d;
    width: 100%;
    &__main {
      display: block;
      padding: 20px 0;
      &-title {
        font-weight: 700;
        font-size: 16px;
        letter-spacing: 0.02em;
        color: #ffe19c;
      }
      &-title2 {
        font-weight: 700;
        font-size: 16px;
        letter-spacing: 0.02em;
        color: #ffffff;
        margin-bottom: 18px;
      }
      // &-inp {
      //   width: 100%;
      //   padding: 8px;
      //   margin: 0px;
      // }
      &-btn {
        font-weight: 700;
        font-size: 16px;
        padding: 9px 36px;
        margin-left: 0px;
        margin-top: 0;
      }
    }
  }
}
</style>
