<template>
    <div
      class="backgroundSign block animated fadeIn"
    >
        <img @click="toMain" class="logo" src="@/assets/logo.svg" alt="">
        <div v-if="!isRegister" class="sign">
            <p class="sign-p1">{{ this.$t('login') }}</p>
            <span class="sign-bottomLine"></span>
            <input
              class="sign-input"
              type="text"
              :placeholder="this.$t('name')"
              v-model="v$.name.$model"
              @input="validateName"
            />
            <template v-for="(error) of v$.name.$errors" :key="error">
              <p class="errorText">{{ error.$message }}</p>
            </template>
            <input
              class="sign-input"
              type="email"
              :placeholder="this.$t('mail')"
              v-model="v$.email.$model"
            />
            <template v-for="(error) of v$.email.$errors" :key="error">
              <p class="errorText">{{ error.$message }}</p>
            </template>
            <div class="show-pass">
              <input
                  class="sign-input"
                  :type="!showPass ? 'password' : 'text'"
                  :placeholder="this.$t('pass')"
                  v-model="v$.password.$model"
                />
                <img v-if="showPass" @click="showPass = false" src="@/assets/images/show-pass.png" alt="">
                <img v-else @click="showPass = true" src="@/assets/images/hide-pass.png" alt="">
            </div>
            <template v-for="(error) of v$.password.$errors" :key="error">
              <p class="errorText">{{ error.$message }}</p>
            </template>
            <div class="show-pass">
              <input
                  class="sign-input"
                  :type="!showPass1 ? 'password' : 'text'"
                  :placeholder="this.$t('confirmPass')"
                  v-model="v$.confirmPassword.$model"
                />
              <img v-if="showPass1" @click="showPass1 = false" src="@/assets/images/show-pass.png" alt="">
              <img v-else @click="showPass1 = true" src="@/assets/images/hide-pass.png" alt="">
            </div>
            <template v-for="(error) of v$.confirmPassword.$errors" :key="error">
              <p class="errorText">{{ error.$message }}</p>
            </template>
            <input
              class="sign-input"
              type="text"
              v-mask="'+7 (###) ### ## ##'"
              :placeholder="this.$t('phone')"
              v-model="phone"
            />
            <div class="sign__remember d-flex">
              <input type="checkbox" class="sign__remember-inp" id="1"/>
              <label for="1" class="sign__remember-text">{{ this.$t('agree') }} <a :href="DOCS?.doc_1" style="color: #FD2D2A; font-weight: 700">{{ this.$t('conf2') }}</a> {{ this.$t('and') }} <a :href="DOCS?.doc_2" style="color: #FD2D2A; font-weight: 700">{{ this.$t('conf3') }}</a></label>
            </div>
            <div class="sign__remember d-flex">
              <input id="2" type="checkbox" class="sign__remember-inp" />
              <label for="2" class="sign__remember-text">{{ this.$t('rassylka') }}</label>
            </div>
            <button class="sign-button" @click="Register">{{ this.$t('send') }}</button>
            <router-link class="sign-registrBtn" to="/login">{{ this.$t('sign') }}</router-link>
          </div>
    </div>
  </template>

  <script>
  import { mask } from "vue-the-mask";
  import axios from "axios";
  import { mapActions, mapGetters } from "vuex";
  import { createToaster } from "@meforma/vue-toaster";
  import useVuelidate from "@vuelidate/core";
  import { required, minLength, helpers, email, sameAs } from "@vuelidate/validators";

  const toaster = createToaster({ position: "top-right", duration: 2000});

  export default {
    directives: { mask },
    data() {
      return {
        v$: useVuelidate(),
        isRegister: false,
        showPass: false,
        showPass1: false,
        email: "",
        name: "",
        password: "",
        phone: "",
        confirmPassword: "",
      };
    },
    validations() {
      return {
        name: {
          required: helpers.withMessage(this.$t('req'), required),
        },
        email: {
            required: helpers.withMessage(this.$t('req'), required),
            email: helpers.withMessage(this.$t('reqMail'), email),
        },
        password: {
            required: helpers.withMessage(this.$t('req'), required),
            minLength: helpers.withMessage(this.$t('minLength'),minLength(6)),
        },
        confirmPassword: {
          required: helpers.withMessage(this.$t('req'), required),
          sameAsPassword: helpers.withMessage(this.$t('errPass'), (value) => value === this.password),
        },
        phone: {
          required: helpers.withMessage(this.$t('req'), required),
          minLength: helpers.withMessage(this.$t('req'),minLength(11)),
        },
      };
    },
    created() {
      this.GET_DOCS();
    },
    methods: {
      ...mapActions(["GET_DOCS"]),
      async Register() {
        this.v$.$validate()
        if (!this.v$.$invalid) {
          const data = {
            name: this.name,
            email: this.email,
            phone: this.phone,
            password: this.password,
            confirmPassword: this.confirmPassword,
          };
          await axios.post(`/registration`, data)
          .then(res => {
            if(res.status === 200) {
              localStorage.setItem("access_token", res.data.token)
              toaster.success(this.$t('loginSuccess'));
              this.$router.push('/platform/programs');
              this.isRegister = true
            }
          })
          .catch(err => {
            if(err.response.data.errors?.email) {
              toaster.error(err.response.data.errors?.email[0]);
            }
            else if(err.response.data.errors?.phone) {
              toaster.error(err.response.data.errors?.phone[0]);
            }
          })
        } else {
          toaster.error(this.$t('err'))
        }
      },
      toMain() {
        this.$router.push('/')
      },
      validateName() {
        this.name = this.name.replace(/[^a-zA-Zа-яА-ЯәіңғүұқөһӘІҢҒҮҰҚӨҺ ]/g, '');
      },
    },
    computed: {
      ...mapGetters(["DOCS"]),
    }
  };
  </script>
  <style lang="scss" scoped>
  .header {
    display: none;
  }
  .valid {
    color: red;
    text-align: start;
  }
  .show-pass {
    position: relative;
    margin: 0 auto;
    img {
      width: 22px;
      height: 22px;
      cursor: pointer;
      position: absolute;
      right: 20px;
      top: 10px;
    }
  }
  .block {
    display: none; /* блок по умолчанию скрыт */
  }
  .animated {
    animation-duration: 1s; /* длительность анимации */
    animation-fill-mode: both; /* сохраняем состояние после анимации */
  }
  .fadeIn {
    animation-name: fadeIn; /* имя анимации */
  }
  .forget {
    &-pass {
        margin-top: 16px;
        cursor: pointer;
    }
  }
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  .logo {
    width: 170px;
    height: 35px;
    position: absolute;
    top: 24px;
    z-index: 11;
    left: 42px;
    cursor: pointer;
  }
  .backgroundSign {
    color: black;
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url(@/assets/images/mainPage/loginSignUp-bg.png);
    background-repeat: no-repeat;
    background-size: cover;
  }
  .sign {
    width: 394px;
    background: #ffffff;
    display: flex;
    flex-direction: column;
    // align-items: center;
    padding: 18px;
    border-radius: 8px;
    gap: 16px;
    //   @media (max-width: $tablet) {
    //     width: 95%;
    //     box-sizing: border-box;
    //   }
    &-check {
        width: 124px;
        height: 144px;
        margin: 0 auto;
    }
    &-p1 {
      font-size: 24px;
      font-weight: 700;
      letter-spacing: 0.02em;
      text-align: center;
    }
    &-bottomLine {
      border: 1px solid rgba(0, 0, 0, 0.2);
      width: 384px;
      margin: 18px 0px 14px; 
      width: 90%;
    }
    &-input {
      width: 100%;
      background: #f5f7fb;
      box-shadow: inset 0px 0px 4px 2px rgba(0, 0, 0, 0.05);
      backdrop-filter: blur(2px);
      border-radius: 4px;
      border: none;
      height: 42px;
      padding: 10px 0px 10px 18px;
      box-sizing: border-box;
      // outline-color: teal;
      font-style: italic;
      font-weight: 400;
      font-size: 18px;
      line-height: 22px;
      color: #8e8e8e;
      width: 340px;
      margin-left: auto;
      margin-right: auto;
    }
    &__remember {
      width: 340px;
      margin-left: auto;
      margin-right: auto;
      &-inp {
        background: #ffffff;
        border: 1px solid rgba(124, 124, 124, 0.24);
        border-radius: 4px;
        width: 20px;
        height: 20px;
      }
      &-text {
        font-weight: 400;
        font-size: 18px;
        line-height: 22px;
        color: #7c7c7c;
        margin-left: 14px;
      }
    }
  
    &-button {
      height: 48px;
      font-weight: 700;
      font-size: 18px;
      line-height: 22px;
      text-align: center;
      color: #ffffff;
      background-color: #fd2d2a;
      border-radius: 4px;
      border: none;
      padding: 13px 0px;
      width: 340px;
      margin-left: auto;
      margin-right: auto;
    }
    &-registrBtn {
      height: 48px;
      font-weight: 700;
      font-size: 18px;
      line-height: 22px;
      text-align: center;
      color: #fd2d2a;
      background-color: #ffffff;
      border-radius: 4px;
      border: 1px solid #fd2d2a;
      padding: 13px 90px;
      width: 340px;
      margin-left: auto;
      margin-right: auto;
    }
    &-p2 {
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: #fd2d2a;
      text-align: center;
    }
    &-agreement {
      font-weight: 400;
      font-size: 16px;
      text-align: center;
      letter-spacing: 0.02em;
      color: #7c7c7c;
      &-docs {
        font-weight: 600;
        color: #fd2d2a;
      }
    }
  }
  
  @media (max-width: 990px) {
    .backgroundSign {
      background-image: url(@/assets/images/loginSignUp.png);
      background-repeat: no-repeat;
      background-size: cover;
      min-height: 100vh;
      height: 100%;
      overflow-y: scroll;
      padding: 70px 0;
    }
    .sign {
      z-index: 111;
      overflow: scroll;
    }
  }
  </style>
  