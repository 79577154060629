<template>
  <div class="product">
    <div class="product-top">
      <p class="product-top__title px-20 fw600">{{ this.$t("use") }}:</p>
      <ul class="mobile-options m_none">
        <li
          class="px-18 gray"
          v-for="option in options"
          :class="{ active: selectedOption == option }"
          :key="option"
          @click="selectOption(option)"
        >
          <img
            style="width: 22px; height: 22px"
            src="@/assets/images/platform/video-icon.svg"
            alt=""
          />
          {{ option }}
        </li>
      </ul>
      <div class="product-top__select d_none">
        <div
          class="product-top__select-option gray px-18"
          @click="toggleDropdown"
        >
          <div class="wrap">
            <img
              src="@/assets/images/platform/video-icon.png"
              alt=""
              class="m_none"
            />
            {{ selectedOption }}
          </div>
          <img
            :class="{ rotate: isDropdownOpen }"
            src="@/assets/images/platform/arr-gray.png"
            alt=""
          />
        </div>
        <ul v-if="isDropdownOpen" class="options filter-full__btm-opt">
          <li
            class="option px-18 gray"
            v-for="option in options"
            :key="option"
            @click="selectOption(option)"
          >
            {{ option }}
          </li>
        </ul>
      </div>
      <!-- <select class="product-top__options d_none">
                <option selected disabled value="">Выбрать..</option>
                <option value="">Аудио-подкасты</option>
                <option value="">Видео подкасты</option>
            </select> -->
    </div>
    <div class="btm" v-if="this.selectedOption == this.$t('videoPodcast')">
      <p class="d_none px-22 fw700">{{ this.$t("videoPodcast") }}</p>
      <div class="product-btm m_none" v-if="VIDEO_PODCAST">
        <a
          class="product-btm__item"
          v-for="(item, index) in VIDEO_PODCAST"
          :key="index"
          @mouseover="isShow[index] = true"
          @mouseleave="isShow[index] = false"
          :href="item.video"
          target="_blank"
        >
          <p class="blur px-18 fw700 white" v-if="isShow[index]">
            {{ $t("play") }}
          </p>
          <img :src="item.image" alt="" />
          <p class="px-16 black fw700 text" v-html="item.title"></p>
        </a>
      </div>
      <div class="product-btm d_none" v-if="VIDEO_PODCAST">
        <a
          class="product-btm__item"
          v-for="(item, index) in VIDEO_PODCAST?.slice(0, this.step)"
          :key="index"
          @mouseover="isShow[index] = true"
          @mouseleave="isShow[index] = false"
          :href="item.video"
          target="_blank"
        >
          <p class="blur px-18 fw700 white" v-if="isShow[index]">
            {{ $t("play") }}
          </p>
          <img :src="item.image" alt="" />
          <p class="px-16 black fw700 text" v-html="item.title"></p>
        </a>
      </div>
      <div class="product-more__btn d_none" @click="showMore" v-if="!isMore">
        {{ $t("more2") }}
      </div>
    </div>
    <div class="btm" v-else-if="this.selectedOption == this.$t('audioPodcast')">
      <p class="d_none px-22 fw700">{{ this.$t("audioPodcast") }}</p>
      <div class="product-btm m_none" v-if="AUDIO_PODCAST">
        <a
          class="product-btm__item"
          v-for="(item, index) in AUDIO_PODCAST"
          :key="index"
          @mouseover="isShow[index] = true"
          @mouseleave="isShow[index] = false"
          :href="item.audio"
          target="_blank"
        >
          <p class="blur px-18 fw700 white" v-if="isShow[index]">
            {{ $t("play") }}
          </p>
          <img :src="item.image" alt="" />
          <p class="px-16 black fw700 text" v-html="item.title"></p>
        </a>
      </div>
      <div class="product-btm d_none">
        <a
          class="product-btm__item"
          v-for="(item, index) in AUDIO_PODCAST?.slice(0, this.step)"
          :key="index"
          @mouseover="isShow[index] = true"
          @mouseleave="isShow[index] = false"
          :href="item.audio"
          target="_blank"
        >
          <p class="blur px-18 fw700 white" v-if="isShow[index]">
            {{ $t("play") }}
          </p>
          <img :src="item.image" alt="" />
          <p class="px-16 black fw700 text" v-html="item.title"></p>
        </a>
      </div>
      <div class="product-more__btn d_none" @click="showMore" v-if="!isMore">
        {{ $t("more2") }}
      </div>
    </div>
    <div class="btm" v-else>
      <p class="d_none px-22 fw700">{{ this.$t("guide") }}</p>
      <div class="product-btm m_none" v-if="GUIDES">
        <a
          class="product-btm__item"
          v-for="(item, index) in GUIDES"
          :key="index"
          @mouseover="isShow[index] = true"
          @mouseleave="isShow[index] = false"
          :href="item.guide"
          target="_blank"
        >
          <p class="blur px-18 fw700 white" v-if="isShow[index]">
            {{ $t("play") }}
          </p>
          <img :src="item.image" alt="" />
          <p class="px-16 black fw700 text" v-html="item.title"></p>
        </a>
      </div>
      <div class="product-btm d_none">
        <a
          class="product-btm__item"
          v-for="(item, index) in GUIDES?.slice(0, this.step)"
          :key="index"
          @mouseover="isShow[index] = true"
          @mouseleave="isShow[index] = false"
          :href="item.guide"
          target="_blank"
        >
          <p class="blur px-18 fw700 white" v-if="isShow[index]">
            {{ $t("play") }}
          </p>
          <img :src="item.image" alt="" />
          <p class="px-16 black fw700 text" v-html="item.title"></p>
        </a>
      </div>
      <div class="product-more__btn d_none" @click="showMore" v-if="!isMore">
        {{ $t("more2") }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      isMore: false,
      step: 4,
      isDropdownOpen: false,
      selectedOption: this.$t("videoPodcast"),
      options: [
        this.$t("videoPodcast"),
        this.$t("audioPodcast"),
        this.$t("guide"),
      ],
      isShow: [],
    };
  },
  created() {
    this.GET_VIDEO_PODCAST();
    this.GET_AUDIO_PODCAST();
    this.GET_GUIDES();
  },
  methods: {
    ...mapActions(["GET_VIDEO_PODCAST", "GET_AUDIO_PODCAST", "GET_GUIDES"]),
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
    },
    selectOption(option) {
      this.selectedOption = option;
      this.isDropdownOpen = false;
    },
    showMore() {
      this.step = this.items?.length;
      this.isMore = true;
    },
  },
  computed: {
    ...mapGetters(["VIDEO_PODCAST", "AUDIO_PODCAST", "GUIDES"]),
  },
};
</script>

<style lang="scss" scoped>
@import url("@/assets/styles/platform/main.scss");
.rotate {
  transform: rotate(180deg) !important;
  transition: all ease 0.4s;
}
.product {
  padding: 24px 28px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 24px 0px 0px 0px;
  min-height: 100vh;
  &-top {
    display: flex;
    align-items: baseline;
    padding-bottom: 13px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    &__title {
      margin-right: 18px;
    }
    &__select {
      width: 310px;
      position: relative;
      &-option {
        cursor: pointer;
        border-radius: 8px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        background: #fff;
        padding: 12px;
        display: flex;
        justify-content: space-between;
        img {
          width: 20px;
          height: 20px;
          transition: all ease 0.4s;
        }
      }
      .options {
        position: absolute;
        z-index: 111;
        cursor: pointer;
        margin-top: 10px;
        border-radius: 8px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        background: #fff;
        padding: 0 12px;
        width: 310px;
      }
    }
  }
  &-btm {
    margin-top: 28px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
    &__item {
      border-radius: 12px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      background: #fff;
      width: 220px;
      padding: 12px;
      margin-bottom: 24px;
      transition: 0.4s;
      position: relative;
      cursor: pointer;
      .blur {
        position: absolute;
        z-index: 3;
        letter-spacing: 0.36px;
        transition: all 0.4s;
        top: 40%;
        left: 0;
        width: 100%;
        display: flex;
        justify-content: center;
      }
      &:hover {
        transition: 0.4s;
        img {
          filter: blur(3px);
          transition: all 0.4s;
        }
      }
      img {
        width: 100%;
        object-fit: cover;
        height: 208px;
        border-radius: 12px;
        margin-bottom: 12px;
      }
      p {
        text-align: center;
        letter-spacing: 0.32px;
        transition: all 0.4s;
      }
    }
  }
  &-more {
    &__btn {
      color: #11415d;
      text-align: center;
      font-size: 16px;
      font-weight: 700;
      border-radius: 4px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      padding: 9px 0;
      transition: 0.4s;
      &:hover {
        background-color: #11415d;
        color: #fff;
        transition: 0.4s;
      }
    }
  }
}
.option {
  img {
    width: 22px;
    height: 22px;
  }
  padding: 9px 0;
  &:not(:last-child) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
}
ul {
  list-style-type: none;
  padding-left: 0;
}
@media (min-width: 960px) {
  .mobile {
    &-options {
      display: flex;
      li {
        border-radius: 8px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        background: #fff;
        width: 224px;
        text-align: left;
        padding: 10px;
        cursor: pointer;
        margin-right: 14px;
        transition: all ease 0.4s;
        // &:hover {
        //   background-color: #FD2D2A;
        //   color: #FFF;
        //   font-weight: 500;
        // }
      }
    }
  }
  .active {
    background-color: #fd2d2a !important;
    color: #fff;
    font-weight: 500;
    img {
      filter: brightness(0) saturate(100%) invert(100%) sepia(17%)
        saturate(7474%) hue-rotate(167deg) brightness(154%) contrast(107%);
    }
  }
  .product {
    &-btm {
      &__item {
        .text {
          height: 40px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          overflow: hidden;
          -webkit-line-clamp: 2;
        }
      }
    }
  }
}
@media (max-width: 960px) {
  .product {
    padding: 0;
    border: none;
    &-top {
      flex-direction: column;
      border-bottom: 0;
      padding-bottom: 18px;
      &__title {
        margin-bottom: 18px;
        font-size: 22px;
        font-weight: 700;
      }
      &__select {
        width: 100%;
        &-option {
          border-radius: 4px;
          border: 1px solid rgba(0, 0, 0, 0.1);
          padding: 10px 14px;
          width: 100%;
          font-size: 16px;
          color: #000;
        }
        .options {
          width: 100%;
          li {
            font-size: 16px;
            color: #000;
          }
        }
      }
      &__options {
        border-radius: 4px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        padding: 10px 14px;
        width: 100%;
        font-size: 16px;
      }
    }
    &-btm {
      margin-top: 18px;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      &__item {
        width: 48%;
        padding: 8px;
        img {
          width: 100%;
          height: 150px;
        }
        p {
          font-size: 16px;
          font-weight: 700;
          text-align: center;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          overflow: hidden;
          -webkit-line-clamp: 2;
        }
      }
    }
  }
}
</style>
