<template>
  <div class="product">
    <div class="product-top">
      <p class="product-top__title px-20 fw600">{{ $t("ourProd") }}:</p>
      <div class="product-top__select m_none">
        <ul class="mobile-options">
          <li
            class="px-18 gray"
            v-for="option in options"
            :class="{ active: selectedOption == option }"
            :key="option"
            @click="selectOption(option)"
          >
            <img
              style="width: 22px; height: 22px"
              src="@/assets/images/platform/our-product1.svg"
              alt=""
            />
            {{ option }}
          </li>
        </ul>
      </div>
      <div class="product-top__select d_none">
        <div
          class="product-top__select-option gray px-18"
          @click="toggleDropdown"
        >
          <div class="wrap">
            <img
              class="m_none"
              src="@/assets/images/platform/our-product1.png"
              alt=""
            />
            {{ selectedOption }}
          </div>
          <img
            :class="{ rotate: isDropdownOpen }"
            src="@/assets/images/platform/arr-gray.png"
            alt=""
          />
        </div>
        <ul v-if="isDropdownOpen" class="options filter-full__btm-opt">
          <li
            class="option px-18 gray"
            v-for="option in options"
            :key="option"
            @click="selectOption(option)"
          >
            {{ option }}
          </li>
        </ul>
      </div>
    </div>
    <div class="" v-if="selectedOption == this.$t('guide')">
      <p class="d_none px-22 fw700">{{ this.$t("guide") }}</p>
      <div class="product-btm">
        <guide-card
          v-for="(item, index) in PRODUCT_GUIDE"
          :key="index"
          :card="item"
          @select-guide="selectItem(item)"
        />
      </div>
      <!-- {{ selectedItem }} -->
      <!-- <div class="product-more__btn d_none" @click="showMore" v-if="!isMore">
                {{ $t("more2") }}
            </div> -->
      <div class="guide-more">
        <div
          class="guide-more"
          id="more"
          v-if="
            selectedItem &&
            selectedItem.guideImages &&
            selectedItem.guideImages[selectedIndex]
          "
        >
          <div class="guide-more__top">
            <h1
              class="guide-more__top__title px-20 fw700"
              v-html="selectedItem.title"
            ></h1>
          </div>
          <div class="guide-more__content">
            <div class="guide-more__content-left">
              <img
                class="main"
                :src="selectedItem.guideImages[selectedIndex].image"
                alt=""
              />
              <div class="guide-more__content-left-images d-flex">
                <img
                  v-for="(item, index) in selectedItem.guideImages"
                  :key="index"
                  :src="item.image"
                  alt=""
                  @click="selectIndex(index)"
                />
              </div>
            </div>
            <div class="guide-more__content-right">
              <p class="px-20 fw700 black" v-html="selectedItem.title"></p>
              <p class="px-18 fw500 gray" v-html="selectedItem.description"></p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="" v-else>
      <p class="d_none px-22 fw700">{{ this.$t("course") }}</p>
      <div class="product-btm bottom-course">
        <course-card
          v-for="(item, index) in PRODUCT_COURSE"
          :key="index"
          :card="item"
          @select-guide="selectItem(item)"
        />
      </div>
      <!-- <div class="product-more__btn d_none" @click="showMore" v-if="!isMore">
                {{ $t("more2") }}
            </div> -->
      <div class="guide-more">
        <div
          class="guide-more"
          id="more"
          v-if="
            selectedItem &&
            selectedItem.courseImages &&
            selectedItem.courseImages[selectedIndex]
          "
        >
          <div class="guide-more__top">
            <h1
              class="guide-more__top__title px-20 fw700"
              v-html="selectedItem.title"
            ></h1>
          </div>
          <div class="guide-more__content">
            <div class="guide-more__content-left">
              <img
                class="main"
                :src="selectedItem.courseImages[selectedIndex].image"
                alt=""
              />
              <div class="guide-more__content-left-images d-flex">
                <img
                  v-for="(item, index) in selectedItem.courseImages"
                  :key="index"
                  :src="item.image"
                  alt=""
                  @click="selectIndex(index)"
                />
              </div>
            </div>
            <div class="guide-more__content-right">
              <p class="px-20 fw700 black" v-html="selectedItem.title"></p>
              <p class="px-18 fw500 gray" v-html="selectedItem.description"></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GuideCard from "@/components/platform/GuideCard.vue";
import CourseCard from "@/components/platform/CourseCard.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  components: { GuideCard, CourseCard },
  data() {
    return {
      // selectedItem: null,
      isMore: false,
      step: 4,
      isDropdownOpen: false,
      selectedOption: this.$t("guide"),
      options: [this.$t("guide"), this.$t("course")],
      selectedItem: [],
      selectedIndex: 0,
    };
  },
  created() {
    this.GET_PRODUCT_GUIDE();
    this.GET_PRODUCT_COURSE();
  },
  methods: {
    ...mapActions(["GET_PRODUCT_GUIDE", "GET_PRODUCT_COURSE"]),
    selectItem: function (item) {
      this.selectedItem = item;
      this.selectedIndex = 0;
    },
    selectIndex(index) {
      this.selectedIndex = index;
    },
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
    },
    selectOption(option) {
      this.selectedOption = option;
      this.isDropdownOpen = false;
    },
    showMore() {
      this.step = this.items.length;
      this.isMore = true;
    },
  },
  computed: {
    ...mapGetters(["PRODUCT_GUIDE", "PRODUCT_COURSE"]),
  },
};
</script>

<style lang="scss" scoped>
@import url("@/assets/styles/platform/main.scss");
@media (max-width: 960px) {
  .m_none {
    display: none !important;
  }
}
@media (min-width: 960px) {
  .d_none {
    display: none !important;
  }
}
.active {
  background-color: #fd2d2a !important;
  color: #fff;
  font-weight: 500;
  img {
    filter: brightness(0) saturate(100%) invert(100%) sepia(17%) saturate(7474%)
      hue-rotate(167deg) brightness(154%) contrast(107%);
  }
}
.rotate {
  transform: rotate(180deg) !important;
  transition: all ease 0.4s;
}
.bottom-course {
  grid-template-columns: repeat(3, 1fr) !important;
}
.product {
  padding: 24px 28px;
  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 24px 0px 0px 0px;
  min-height: 100vh;
  &-top {
    display: flex;
    align-items: baseline;
    padding-bottom: 13px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    &__title {
      margin-right: 18px;
    }
    &__select {
      // width: 215px;
      position: relative;
      &-option {
        cursor: pointer;
        border-radius: 8px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        background: #fff;
        padding: 12px;
        display: flex;
        justify-content: space-between;
        img {
          width: 20px;
          height: 20px;
          transition: all ease 0.4s;
        }
      }
      .options {
        position: absolute;
        cursor: pointer;
        margin-top: 10px;
        border-radius: 8px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        background: #fff;
        padding: 0 12px;
        // width: 215px;
        z-index: 111;
      }
    }
  }
  &-btm {
    margin-top: 28px;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-column-gap: 10px;
    &__item {
      border-radius: 12px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      background: #fff;
      max-width: 220px;
      width: 100%;
      padding: 7px;
      margin-bottom: 24px;
      transition: 0.4s;
      &:hover {
        transition: 0.4s;
        transform: scale(1.1);
      }
      img {
        width: 100%;
        height: 208px;
        margin: 8px 0;
        object-fit: cover;
        border-radius: 8px;
      }
    }
  }
  &-more {
    &__btn {
      color: #11415d;
      text-align: center;
      font-size: 16px;
      font-weight: 700;
      border-radius: 4px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      padding: 9px 0;
      transition: 0.4s;
      &:hover {
        background-color: #11415d;
        color: #fff;
        transition: 0.4s;
      }
    }
  }
}
.guide {
  &-more {
    margin: 40px 0;
    &__top {
      margin-bottom: 20px;
      h1,
      p {
        text-align: center;
      }
    }
    &__content {
      display: flex;
      justify-content: space-between;
      width: 100%;
      &-left {
        width: 49%;
        .main {
          height: 450px;
          border-radius: 14px;
          border: 1px solid rgba(0, 0, 0, 0.1);
          width: 100%;
          object-fit: cover;
        }
        &-images {
          margin-top: 14px;
          width: 100%;
          img {
            width: 33%;
            height: 100px;
            object-fit: cover;
            border-radius: 14px;
            border: 1px solid rgba(0, 0, 0, 0.1);
            margin-right: 10px;
          }
        }
      }
      &-right {
        width: 49%;
        .black {
          margin-bottom: 40px;
        }
      }
    }
  }
}
.option {
  img {
    width: 22px;
    height: 22px;
  }
  padding: 9px 0;
  &:not(:last-child) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
}
ul {
  list-style-type: none;
  padding-left: 0;
}
@media (min-width: 960px) and (max-width: 1560px) {
  .product {
    &-btm {
      &__item {
        img {
          height: 120px !important;
        }
        .button {
          font-size: 12px;
          padding: 6px 0;
        }
      }
    }
  }
}
@media (min-width: 960px) {
  .mobile {
    &-options {
      display: flex;
      li {
        border-radius: 8px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        background: #fff;
        width: 210px;
        text-align: left;
        padding: 10px;
        cursor: pointer;
        margin-right: 14px;
        transition: all ease 0.4s;
      }
    }
  }
}
@media (max-width: 960px) {
  .guide {
    &-more {
      &__top {
        h1 {
          text-align: left;
        }
      }
      &__content {
        flex-direction: column;
        &-left,
        &-right {
          width: 100%;
        }
        &-left {
          .main {
            height: 250px;
          }
          &-images {
            height: 80px;
          }
        }
        &-right {
          margin-top: 40px;
        }
      }
    }
  }
  .product {
    padding: 0;
    border: 0;
    &-top {
      flex-direction: column;
      border-bottom: 0;
      padding-bottom: 18px;
      &__title {
        margin-bottom: 18px;
        font-size: 22px;
        font-weight: 700;
      }
      &__options {
        border-radius: 4px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        padding: 10px 14px;
        width: 100%;
        font-size: 16px;
      }
      &__select {
        width: 100%;
        &-option {
          border-radius: 4px;
          border: 1px solid rgba(0, 0, 0, 0.1);
          padding: 10px 14px;
          width: 100%;
          font-size: 16px;
          color: #000;
        }
        .options {
          width: 100%;
          li {
            font-size: 16px;
            color: #000;
          }
        }
      }
      &__options {
        border-radius: 4px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        padding: 10px 14px;
        width: 100%;
        font-size: 16px;
      }
    }
    &-btm {
      margin-top: 18px;
      width: 100%;
      grid-template-columns: repeat(2, 1fr);
      &__item {
        width: 100%;
        padding: 8px;
        img {
          width: 100%;
          height: 150px;
        }
        .button {
          font-size: 12px;
          font-weight: 700;
        }
        p {
          font-size: 16px;
          font-weight: 700;
          text-align: center;
          color: #000;
        }
      }
    }
  }
  .bottom-course {
    grid-template-columns: repeat(2, 1fr) !important;
  }
}
</style>
