<template>
  <div class="grantsWays" v-if="USEFUL_PAGE">
    <div class="" v-if="USEFUL_PAGE.howItWorks">
      <div class="container">
        <div class="grantsWays__main">
          <h1
            class="grantsWays__main-title b36-700"
            v-html="USEFUL_PAGE.howItWorks.title"
          ></h1>
          <p
            class="grantsWays__main-text g18-400"
            v-html="USEFUL_PAGE.howItWorks.description"
          ></p>
        </div>
      </div>
      <div class="desctop">
        <!-- :autoplay="{
              delay: 2000,
              pauseOnMouseEnter: true
            }" -->
        <swiper
            :slidesPerView="1.2"
            :spaceBetween="30"
            :modules="modules"
            :navigation="navigation1"
            :loop="true"
            :autoplay="{
              delay: 2000,
              pauseOnMouseEnter: true
            }"
            class="mySwiper1 slider1"
          >
            <swiper-slide v-for="(item, index) in USEFUL_PAGE.howItWorksItems" :key="index">
              <div class="step">
                <img :src="item.image" alt="">
              </div>
            </swiper-slide>
          </swiper>
          <div class="nav">
            <div class="swiper-button-prev swp2">
              <img src="@/assets/images/mainPage/wh-left.png" alt="" />
            </div>
            <div class="swiper-button-next swp1">
              <img src="@/assets/images/mainPage/wh-right.png" alt="" />
            </div>
        </div>
      </div>
      <div class="container">
        <div class="mobile">
          <swiper
            :slidesPerView="1.4"
            :spaceBetween="30"
            :lazy="true"
            :loop="true"
            :breakpoints="{}"
          >
            <swiper-slide
              v-for="(item, index) in USEFUL_PAGE.howItWorksItems"
              :key="index"
              class="GrantsPageMobile"
              v-viewer
            >
              <div class="GrantsPageMobile__card">
                <img :src="item.image_mobile" />
              </div>
            </swiper-slide>
          </swiper>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/pagination';
import { Autoplay, Mousewheel, Pagination } from 'swiper';
export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    return {
      modules: [Mousewheel, Pagination, Autoplay],
    };
  },
  data() {
    return {
      isMore: [],
      navigation1 : {
        nextEl: ".swp1",
        prevEl: ".swp2",
      },
    }
  },
  created(){
    this.GET_USEFUL_PAGE();
  },
  methods: {
    ...mapActions(["GET_USEFUL_PAGE"]),
    toggleMore(index) {
      // Toggle the value of isMore at the given index
      this.isMore[index] = !this.isMore[index];
    },
  },
  computed: {
    ...mapGetters(["USEFUL_PAGE"]),
  },
};
</script>

<style lang="scss" scoped>
.swiper-slide.result__items-swiper-swipers-slide.swiper-slide-next {
    margin-top: 0 !important;
}

.swiper-slide.result__items-swiper-swipers-slide {
    margin-top: 0 !important;
}
.step {
  border-radius: 18px;
  img {
    border-radius: 18px;
    height: 90vh;
    object-fit: cover;
    object-position: top;
  }
}
.mobile {
  width: 100%;
}
.desctop {
  position: relative;
  .swiper {
    height: 90vh;
    &-slide {
      height: 90vh !important;
      &-prev {
        margin-left: 8%;
      }
      &-prev, &-next {
        background: rgba(0, 0, 0, 0.54);
        border-radius: 18px;
        display: inline-block;
        img {
          opacity: 0.4;
        }
      }
    }
    &-button {
      &-prev, &-next {
        &::after {
          display: none;
        }
        img {
          width: 48px;
          height: 48px;
        }
      }
      &-prev {
        left: 45px;
      }
      &-next {
        right: 45px;
      }
    }
  }
  .nav {
    position: absolute;
    top: 50%;
    width: 100%;
  }
} 
.swiper-container {
  height: 400px;
}
.GrantsPageMobile {
  margin-top: 18px;
  height: fit-content;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  display: flex;
  // padding: 0px 8px 18px 8px;
  margin-bottom: 28px;
  img {
    border-radius: 8px;
  }
  &__card {
    display: flex;
    flex-direction: column;
    gap: 18px;
    transition: all 0.4s ease;
    &:hover {
      transition: all 0.4s ease;
      transform: scale(1.1);
    }
    &__top {
      position: relative;
      img {
        width: 100%;
        height: 25vh;
      }
      &__step {
        position: absolute;
        top: 5%;
        left: 1%;
        background: #ffffff;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 6px;
        padding: 8px 8px;
        font-size: 16px;
        font-weight: 700;
      }
    }
    &__bot {
      .more {
        text-align: right;
        padding-top: 20px;
        font-weight: 700;
        cursor: pointer;
      }
      .text {
        height: 148px;
        overflow: hidden;
      }
      .moreText {
        height: auto;
      }
    }
  }
}
.grantsWays {
  padding-top: 50px;
  &__main {
    text-align: center;
    width: 65%;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 80px;
    &-title {
      padding-bottom: 18px;
    }
  }
  &__content {
    padding-top: 80px;
    &__item {
      padding-bottom: 60px;
      &:nth-child(even) {
        flex-direction: row-reverse;
        .grantsWays__content__item-left {
          padding-right: 0;
          padding-left: 60px;
        }
      }
      &-left {
        width: 55%;
        padding-right: 60px;
        & h1 {
          padding: 4px 0px 48px;
        }
      }
      &-right {
        border-radius: 18px;
        width: 808px;
        height: 454px;
        & img {
          border-radius: 18px;
          width: 100%;
          height: 100%;
        }
      }
    }
    &__item2 {
      padding-bottom: 60px;
      flex-direction: row-reverse;
      &-left {
        width: 55%;
        padding-left: 60px;
        & h1 {
          padding: 4px 0px 48px;
        }
      }
      &-right {
        border-radius: 18px;
        max-width: 808px;
        max-height: 454px;
        & img {
          border-radius: 18px;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
@media (max-width: 576px) {
  .swiper-slide {
    // filter: blur(2px);
    &-active {
      filter: unset !important;
    }
  }
}
@media (min-width: 1024px) and (max-width: 1440px) {
  .grantsWays {
    &__content {
      &__item {
        &-left {
          width: 75%;
          & h1 {
            padding-bottom: 8px;
          }
        }
      }
      &__item2 {
        &-left {
          width: 75%;
          & h1 {
            padding-bottom: 8px;
          }
        }
      }
    }
  }
}
@media (max-width: 990px) {
  .grantsWays {
    &__main {
      text-align: left;
      width: 100%;
      margin-bottom: 0;
      padding-bottom: 20px;
      &-title {
        font-weight: 700;
        font-size: 22px;
      }
      &-text {
        font-weight: 600;
        font-size: 14px;
        line-height: 130%;
        letter-spacing: 0.02em;
      }
    }

    &__content {
      padding-top: 18px;
    }
  }
}
</style>
