<template>
  <div class="feedback" v-if="MAIN_PAGE">
    <div class="container">
      <div class="feedback__content">
        <div class="feedback__main" v-if="MAIN_PAGE.homeReview">
          <h3 class="feedback__main-title">{{ this.$t("op") }}</h3>
          <h1
            class="feedback__main-title2"
            v-html="MAIN_PAGE.homeReview.title"
          ></h1>
          <p
            class="feedback__main-text"
            v-html="MAIN_PAGE.homeReview.description"
          ></p>
        </div>
        <div class="feedback__slider" v-if="MAIN_PAGE.reviews">
          <swiper
            :loop="true"
            :navigation="navigation"
            :modules="modules"
            :slides-per-view="3.5"
            :breakpoints="{
              1800: {
                slidesPerView: 3.5,
              },
              1000: {
                slidesPerView: 3,
              },
              768: {
                slidesPerView: 2,
              },
              0: {
                slidesPerView: 1.4,
                slidesPerView: 1.5,
              },
            }"
            class="mySwiper"
          >
            <swiper-slide
              v-for="(item, index) in MAIN_PAGE.reviews"
              :key="index"
            >
              <a :href="item.link" target="_blank">
                <div class="feedback__slider__card">
                  <div class="feedback__slider__card-top d-flex">
                    <img
                      class="feedback__slider__card-image"
                      :src="item.image"
                      alt=""
                    />
                    <div class="feedback__slider__card-info">
                      <h3 class="feedback__slider__card-title">{{ this.$t("stud") }}</h3>
                      <h1
                        class="feedback__slider__card-name"
                        v-html="item.full_name"
                      ></h1>
                      <div class="feedback__slider__card-stars d-flex m_none">
                        <img
                          class="feedback__slider__card-star"
                          src="@/assets/images/mainPage/feedback-star.svg"
                          alt=""
                        />
                        <img
                          class="feedback__slider__card-star"
                          src="@/assets/images/mainPage/feedback-star.svg"
                          alt=""
                        />
                        <img
                          class="feedback__slider__card-star"
                          src="@/assets/images/mainPage/feedback-star.svg"
                          alt=""
                        />
                        <img
                          class="feedback__slider__card-star"
                          src="@/assets/images/mainPage/feedback-star.svg"
                          alt=""
                        />
                        <img
                          class="feedback__slider__card-star"
                          src="@/assets/images/mainPage/feedback-star.svg"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                  <div class="feedback__slider__card-stars d-flex d_none">
                    <img
                      class="feedback__slider__card-star"
                      src="@/assets/images/mainPage/feedback-star.svg"
                      alt=""
                    />
                    <img
                      class="feedback__slider__card-star"
                      src="@/assets/images/mainPage/feedback-star.svg"
                      alt=""
                    />
                    <img
                      class="feedback__slider__card-star"
                      src="@/assets/images/mainPage/feedback-star.svg"
                      alt=""
                    />
                    <img
                      class="feedback__slider__card-star"
                      src="@/assets/images/mainPage/feedback-star.svg"
                      alt=""
                    />
                    <img
                      class="feedback__slider__card-star"
                      src="@/assets/images/mainPage/feedback-star.svg"
                      alt=""
                    />
                  </div>
                  <p
                    class="feedback__slider__card-bottom"
                    v-html="item.comment"
                  ></p>
                  <a :href="item.link" target="_blank" class="feedback__slider__card-more">{{ this.$t("more1") }}</a>
                </div>
              </a>
            </swiper-slide>
          </swiper>
          <div class="nav">
            <div class="swiper-button-prev swiperprev15">
              <img src="@/assets/images/mainPage/linia-left.png" alt="" />
            </div>
            <div class="swiper-button-next swipernext15">
              <img src="@/assets/images/mainPage/linia-right.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import "swiper/css/scrollbar";

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";
// import VLazyImage from "v-lazy-image";
// Import Swiper styles
import "swiper/css";

import "swiper/css/navigation";

// import required modules
import { Navigation } from "swiper";
export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  props: ["MAIN_PAGE"],
  setup() {
    return {
      modules: [Navigation],
    };
  },
  data: () => ({
    breakpoints: {
      1300: {
        slidesPerView: 4,
      },
      990: {
        slidesPerView: 4,
      },
      760: {
        slidesPerView: 2,
      },
      460: {
        slidesPerView: 1.5,
        sliderPerGroup: 1.5,
      },
      0: {
        slidesPerView: 1,
        sliderPerGroup: 1,
      },
    },
    navigation: {
      nextEl: ".swipernext15",
      prevEl: ".swiperprev15",
    },
  }),
};
</script>
<style lang="scss" scoped>
.container {
  position: relative;
}
.nav {
  position: absolute;
  width: 100%;
  top: 58%;
  height: 20px;
  display: flex;
  justify-content: space-between;
}
.swiper-button-next {
  right: -16px;
  &::after {
    opacity: 0;
  }
  img {
    width: 33px;
    height: 21px;
  }
}
.swiper-button-prev {
  left: -12px;
  &::after {
    opacity: 0;
  }
  img {
    width: 33px;
    height: 21px;
  }
}
.feedback {
  background-color: #f5f5f5;
  &__content {
    padding: 80px 0px;
    @media screen and (max-width: $mobile) {
      padding-top: 18px;
    }
  }
  &__main {
    align-items: center;
    &-title {
      font-weight: 600;
      font-size: 16px;
      line-height: 130%;
      text-align: center;
      letter-spacing: 0.02em;
      color: #fd2d2a;
    }
    &-title2 {
      font-weight: 700;
      font-size: 36px;
      line-height: 130%;
      text-align: center;
      letter-spacing: 0.02em;
      color: #000000;
      padding: 4px 18px;
    }
    &-text {
      font-weight: 600;
      font-size: 18px;
      line-height: 28px;
      text-align: center;
      color: #7c7c7c;
      width: 55%;
      margin-left: auto;
      margin-right: auto;
    }
  }
  &__slider {
    padding-top: 80px;
    @media screen and (max-width: $mobile) {
      padding-top: 18px;
    }
    &__card {
      position: relative;
      background: #ffffff;
      border-radius: 18px;
      padding: 14px 16px 10px 14px;
      width: 90%;
      margin: 0 auto;
      height: 300px;
      overflow: hidden;
      @media screen and (max-width: $mobile) {
        width: 94%;
        padding: 0px;
      }
      &-top {
        padding-bottom: 15px;
      }
      &-feedImg {
        width: 388px;
        height: 272px;
        object-fit: contain;
      }
      &-image {
        width: 120px;
        height: 120px;
        object-fit: contain;
        border-radius: 18px;
      }
      &-info {
        padding-left: 18px;
      }
      &-title {
        font-weight: 500;
        font-size: 16px;
        letter-spacing: 0.02em;
        color: #fd2d2a;
      }
      &-name {
        font-weight: 700;
        font-size: 24px;
        line-height: 160%;
        letter-spacing: 0.02em;
        color: #000000;
        padding-top: 2px;
      }
      &-star {
        width: 20px;
        height: 20px;
        margin-right: 18px;
      }
      &-bottom {
        font-weight: 500;
        font-size: 18px;
        letter-spacing: 0.02em;
        color: #7c7c7c;
        padding-top: 20px;
        max-height: 110px;
        overflow: hidden;
        // scrollbar-width: thin;
        // scrollbar-color: rgba(255, 255, 255, 0.5) rgba(0, 0, 0, 0.3);
        // &::-webkit-scrollbar {
        //   width: 6px;
        // }
        // &::-webkit-scrollbar-thumb {
        //   background-color: rgba(255, 255, 255, 0.5);
        //   border-radius: 4px;
        // }

        // &::-webkit-scrollbar-track {
        //   background-color: rgba(255, 255, 255, 0.1);
        //   border-radius: 4px;
        // }
      }
      &-more {
        cursor: pointer;
        text-decoration: underline;
        color: #0070E0;
        font-weight: 600;
        transition: 0.4s;
        position: absolute;
        bottom: 15px;
        left: 14px;
        &:hover {
          color: #0070e0a4;
          transition: 0.4s;
        }
      }
    }
  }
}

@media (min-width: 1024px) and (max-width: 1440px) {
  .feedback {
    &__main {
      &-text {
        width: 75%;
      }
    }
    &__slider {
      &__card {
        width: 95%;
        &-feedImg {
          width: 265px;
        }
        &-name {
          font-size: 18px;
          padding-right: 5px;
        }
        &-star {
          margin-right: 5px;
        }
      }
    }
  }
  .swiper-button-prev {
    left: -20px;
  }
}
@media (min-width: 990px) {
  .swiper-button-next {
    right: -35px;
  }
  .swiper-slide {
    height: 300px;
  }
  .feedback {
    &__slider {
      &__card {
        &-bottom {
          padding-top: 0;
        }
      }
    }
  }
}
@media (max-width: 990px) {
  .nav {
    display: none;
  }
  .feedback {
    background-color: #fff;
    &__content {
      padding: 18px 0;
    }
    &__main {
      &-title {
        text-align: left;
      }
      &-title2 {
        text-align: left;
        padding: 0px;
        font-size: 22px;
        margin-bottom: 18px;
      }
      &-text {
        font-size: 14px;
        line-height: 130%;
        letter-spacing: 0.02em;
        text-align: left;
        padding: 0px;
        margin: 0px;
        width: 100%;
      }
    }
    &__slider {
      &__card {
        padding: 8px;
        border: 1px solid rgba(0, 0, 0, 0.10);
        margin: 0;
        height: 275px;
        &-top {
          padding-bottom: 8px;
        }
        &-info {
          padding-left: 8px;
        }
        &-title {
          font-size: 14px;
          font-weight: 600;
          margin-bottom: 0;
        }
        &-name {
          font-size: 16px;
        }
        &-image {
          width: 64px;
          height: 64px;
          object-fit: contain;
          border-radius: 8px;
        }
        &-stars {
          display: flex;
          justify-content: space-around;
        }
        &-star {
          margin-right: 0;
          width: 24px;
          height: 24px;
        }
        &-bottom {
          padding-right: 0;
          letter-spacing: 0;
          max-height: 124px;
        }
      }
    }
  }
}

@media (max-width: 576px) {
  .swiper-slide {
    // filter: blur(2px);
    &-active {
      filter: unset !important;
    }
  }
}
</style>
